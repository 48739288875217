import React, {useEffect, useState} from 'react';
import {Row} from 'react-bootstrap';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {getWalletActivityDetails} from '../../../../../../api';
import {formatDayTime} from '../../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {CustomContainer, Loading} from '../../../../../common';
import {NewPosHeader} from '../../../../../common/V2';
import DepositTransaction from './DepositTransaction';
import PayoutTransaction from './PayoutTransaction';
import RefundTransaction from './RefundTransaction';

const WalletActivityDetail = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [activityData, setActivityData] = useState({});
    const [loading, setLoading] = useState(true);
    const {business} = useAuthContext();
    let navigate = useNavigate();
    const {state} = useLocation();

    const {id} = useParams();
    useEffect(() => {
        getActivityDetail();
    }, [id]);

    const {created_at, transaction_id, type} = activityData || {};

    async function getActivityDetail() {
        try {
            let params = {
                business_id: business?.id,
                transaction_id: id,
            };
            const response = await getWalletActivityDetails(params);
            if (response.success) {
                setActivityData(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching wallet activity details:', error);
        } finally {
            setLoading(false);
        }
    }
    const handleBack = () => {
        if (state?.screenName === 'balance_tab') {
            navigate('/dashboard', {state: {screenName: 'wallet', tab: 'balance'}});
        } else navigate('/dashboard', {state: {screenName: 'wallet', tab: 'activity'}});
    };

    return (
        <CustomContainer reduceHeight={45} className="addScreenMainBox createCustomerContainer transactionDetail">
            <Row className="justify-content-md-center">
                <NewPosHeader
                    backBtnCta={true}
                    backAction={handleBack}
                    title={{
                        name: `Transaction ID | ${transaction_id}`,
                        decs: `(${formatDayTime(created_at)})`,
                    }}
                />

                {loading ? (
                    <Loading />
                ) : type == 'deposits' ? (
                    <DepositTransaction data={activityData} />
                ) : type == 'payout' ? (
                    <PayoutTransaction data={activityData} />
                ) : type == 'refund' ? (
                    <RefundTransaction data={activityData} />
                ) : null}
            </Row>
        </CustomContainer>
    );
};

export default WalletActivityDetail;
