import {useLanguageContext, useThemeContext} from '../../../../../context';
import TableBody from '../../../../common/TableContainer/TableBody';
import {useState} from 'react';
import {Dropdown, MessageModal} from '../../../../common';
import Icon from '../../../../../assets/icons';

const ListItem = ({item, key, handleArchiveCounter}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);
    const [show, setShow] = useState(false);
    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async () => {
        setArchiveModal(false);
        handleArchiveCounter(item);
    };

    const tableAction = [
        {
            title: I18n[item.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody key={key} className={`threeColumn `} style={{borderColor: theme.inputBorder}}>
                <div className="BoxWidth justifyStart">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {item.name || '--'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="flexRow alignCenter marBot0" style={{height: '20px', color: theme.white}}>
                        <span className="OneLineTruncate marBot0 " style={{width: '50px'}}>
                            {show ? item.secret_key : '****'}
                        </span>
                        <span className="cursorPointer marBot10">
                            <Icon
                                onClick={() => setShow(prevState => !prevState)}
                                name={show ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                                width={18}
                                height={18}
                                fill={theme.barclaysBlue}
                                viewBox="0 0 18 18"
                            />
                        </span>
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <div className="listActionBox">
                        <Dropdown
                            dropDown={{
                                name: 'threeDotIcon',
                                fill: theme.barclaysBlue,
                                width: 18,
                                height: 4,
                                viewBox: '0 0 18 4',
                                option: tableAction,
                            }}
                        />
                    </div>
                </div>
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n[item.archive ? 'do_you_really_want_to_unarchive_this_counter' : 'do_you_really_want_to_archive_this_counter']}
                subDescription={I18n[item.archive ? 'you_can_archive_this_later' : 'you_can_unarchive_this_later']}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n[item.archive ? 'unarchive' : 'archive'],
                    action: () => {
                        handleArchive();
                    },
                }}
            />
        </>
    );
};

export default ListItem;
