import React from 'react';
import {useNavigate} from 'react-router-dom';
import JazzCashLogo from '../../../../../../assets/images/JazzCashLogo.png';
import {firstLetterCaptilize, formatDayTime, formatNum} from '../../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import TableBody from '../../../../../common/TableContainer/TableBody';

const ListItem = ({index, item, isMuiltlocation}) => {
    const {
        payment_method,
        card_number,
        created_at,
        amount,
        type,
        customer_id: customer_phone_number,
        transaction_id,
        card_type_logo,
        business_id,
        location_name,
        customer_uuid,
    } = item || {};

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    let navigate = useNavigate();

    const handlePayment = () => {
        navigate(`/payments-transactions/${transaction_id}`, {state: {businessId: business_id}});
    };

    const navigateCustomerDetails = customer_uuid => {
        if (customer_uuid && customer_phone_number) {
            navigate(`/customers/${customer_uuid}`);
        }
    };

    return (
        <TableBody style={{borderColor: theme.inputBorder}} className={`${isMuiltlocation ? 'sevenColumn' : 'sixColumn'} cursorPointer`} key={index} onClick={handlePayment}>
            <div className="BoxWidth textWithTag justifyStart">
                <p className="fontSize14 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {formatNum(amount)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDayTime(created_at)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {firstLetterCaptilize(payment_method) || '--'}
                </p>
            </div>
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
                className="BoxWidth justifyCenter">
                <p
                    className="fontSize14 OneLineTruncate"
                    onClick={() => navigateCustomerDetails(customer_uuid)}
                    style={{color: customer_phone_number ? theme.barclaysBlue : theme.white}}>
                    {customer_phone_number || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                    {transaction_id || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter transactionIconConatiner">
                {card_number && card_type_logo && <img src={card_type_logo} alt="logo" height={17} width={30} />}
                <p className="fontSize14 OneLineTruncate marRight20" style={{color: theme.white}}>
                    {card_number?.substring(8) || '--'}
                </p>
            </div>
            {isMuiltlocation && (
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {location_name || '--'}
                    </p>
                </div>
            )}
        </TableBody>
    );
};

export default ListItem;
