import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {DateSelector, TextField, Header, Loading} from '../../common';
import {CITY, PROVINCE, validateCreateCustomerForm, toSnakeCase, Country_Name, validateCreatetWaiterForm, validateDraftWaiterForm} from '../../../constants';
import {useNavigate} from 'react-router-dom';
import {getAllCountry, Sync} from '../../../api';
import {fetchOrderByNum, observeGetOrderById} from '../../../pos-core/database/helpers';

const CreateRiderComponent = ({rider}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast} = useAuthContext();

    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    let navigate = useNavigate();

    const [name, setName] = useState(rider?.name || '');
    const [phone, setPhone] = useState(rider?.phone_number || '');
    const [email, setEmail] = useState(rider?.email || '');
    const [dob, setDob] = useState(rider?.dob || '');
    const [province, setProvince] = useState(rider?.province || '');
    const [country, setCountry] = useState(rider?.country || '');
    const [city, setCity] = useState(rider?.city || '');
    const [address, setAddress] = useState(rider?.address || '');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [provincesList, setProvincesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const handleEnterPress = event => {
        if (event.key === 'Enter' && name && phone.length == 11 && !Object.keys(errors).length) {
            handleCreateRider('save');
        }
    };

    const onChange = (label, val) => {
        if (label == I18n.full_name) setName(val);
        else if (label == I18n.phone) setPhone(val);
        else if (label == I18n.email) setEmail(val);
        else if (label == I18n.country) {
            setCountry(val);
            setProvince('');
            setCity('');
            const selectedCountry = data.find(item => item.countryName === val);
            setProvincesList(selectedCountry ? selectedCountry.provinces : []);
        } else if (label == I18n.province) {
            setProvince(val);
            setCity('');
            const selectedProvince = provincesList.find(item => item?.provinceName === val);
            setCitiesList(selectedProvince ? selectedProvince.cities : []);
        } else if (label == I18n.city) setCity(val);
        else if (label == I18n.address) setAddress(val);
        else if (label === I18n.date_of_birth) setDob(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    async function handleCreateRider(val) {
        const {draft: riderDraftStatus, id: riderId} = rider || {};
        const order = orderNumber ? await fetchOrderByNum(orderNumber) : null;

        try {
            const paramsForRider = {
                name,
                phone,
                email,
                dob: new Date(dob).getTime(),
                province,
                city,
                country,
                address,
                draft: val === 'saveDraft',
            };
            let createdRider;

            const formErrors = await (val === 'save' ? validateCreatetWaiterForm(paramsForRider) : validateDraftWaiterForm(paramsForRider));
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            if (rider) {
                createdRider = await rider.updateDetails(paramsForRider);
            } else {
                createdRider = await business.createRider(paramsForRider);
                if (orderNumber && val === 'save') {
                    await order[0]?.updateRider(createdRider);
                }
            }

            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = 'rider_saved_into_draft';
            } else {
                toastMessage = riderDraftStatus ? 'rider_published_successfully' : riderId ? 'rider_updated' : 'rider_created';
            }

            orderNumber ? navigate(`/pos-cockpit`) : navigate('/riders');
            setRenderToast(toastMessage);
            await Sync(user.email);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchCountries = async () => {
        setLoading(true);
        try {
            const res = await getAllCountry();
            setData(res?.data);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    return loading ? (
        <Loading />
    ) : (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                saveCta={{
                    title: I18n.save,
                    action: () => handleCreateRider('save'),
                }}
                primaryCta={{
                    title: I18n.save_draft,
                    action: () => handleCreateRider('saveDraft'),
                }}
            />

            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className="signupTextBox">
                        <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {rider ? I18n.edit_rider : I18n.create_rider}
                        </h3>
                        <h3 className="fontSize20 fontWeight00 marBot10 marTop0 marLeft15" style={{color: theme.text}}>
                            {I18n.rider_details}
                        </h3>
                    </div>

                    <div className="itemFields">
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    onChange={onChange}
                                    error={errors['name']}
                                    label={I18n.full_name}
                                    placeholder={I18n.enter_name}
                                    value={name}
                                    required={true}
                                    autoFocus={true}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    onChange={onChange}
                                    error={errors['phone_number']}
                                    label={I18n.phone}
                                    placeholder={I18n.enter_phone_number}
                                    value={phone}
                                    type={'numeric'}
                                    maxLength={11}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField onChange={onChange} error={errors.email} label={I18n.email} placeholder={I18n.enter_email_address} value={email} />
                            </div>
                            <div className="priceSectionInputRight">
                                <DateSelector label={I18n.date_of_birth} disableFutureDate={true} value={dob} setValue={onChange} />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    label={I18n.country}
                                    placeholder={I18n.country}
                                    data={data?.map(item => item?.countryName)}
                                    value={country}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    label={I18n.province}
                                    placeholder={I18n.province}
                                    data={provincesList?.map(item => item.provinceName)}
                                    value={province}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    label={I18n.city}
                                    placeholder={I18n.select_city}
                                    data={citiesList}
                                    value={city}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField onChange={onChange} errors={errors} label={I18n.address} placeholder={I18n.enter_address} value={address} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CreateRiderComponent;
