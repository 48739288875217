import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {Header} from '../../../../../common';
import TotalTiles from '../../../../../common/TotalTiles';
import FundsJourney from '../../../../../common/FundsJourney';
import RecentDeposits from '../../../../../common/RecentDeposits';
import BeneficiaryModal from '../../../../../common/BeneficiaryModal';
import {getWalletBalance} from '../../../../../../api';
import {formatDate} from '../../../../../../constants';
import {useNavigate} from 'react-router-dom';

const WalletBalance = ({setPrimaryTabValue, userPermission, setVisible}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();
    const [beneficiaryModal, setBeneficiaryModal] = useState(false);
    const [selectedBeneficiaries, setSelectedBeneficiaries] = useState();
    const [wallet, setWallet] = useState();
    let navigate = useNavigate();

    const handleTransfer = () => {
        setBeneficiaryModal(true);
    };

    const OVERVIEW_DATA = [
        {
            iconBGcolor: theme.inputBorder,
            iconName: 'walletBalanceIcon',
            viewBox: '0 0 21 20',
            width: '21',
            height: '20',
            heading: 'wallet_balance',
            amount: wallet?.balance_summary?.wallet_balance || 0,
            rs: true,
            showInfo: true,
            toolTipContent: 'your_available_balance_in_the_wallet',
        },
        {
            iconBGcolor: theme.inputBorder,
            iconName: 'moneyIcon',
            height: '12',
            width: '13',
            viewBox: '0 0 13 12',
            amount: wallet?.balance_summary?.deposit_sum || 0,
            heading: 'deposits',
            rs: true,
            showInfo: true,
            toolTipContent: 'total_amount_deposited_into_your_account',
        },
        {
            iconBGcolor: theme.inputBorder,
            iconName: 'viewBtnIcon',
            viewBox: '0 0 13 13',
            width: '11',
            height: '11',
            amount: wallet?.balance_summary?.payout_sum || 0,
            heading: 'payouts',
            rs: true,
            showInfo: true,
            toolTipContent: 'total_amount_paid_out_from_account',
        },
    ];

    const fundsAvailable = [
        {
            type: 'Balance',
            description: `Opening balance for ${formatDate(wallet?.funds_available?.todays_date)}`,
            amount: wallet?.funds_available?.opening_balance || 0,
            showTooltip: false,
        },
        {
            type: "Today's deposit",
            description: `Funds deposited on ${formatDate(wallet?.funds_available?.todays_date)}`,
            amount: wallet?.funds_available?.todays_deposit || 0,
            showTooltip: false,
        },
        {
            type: "Today's payout",
            description: `Funds withdrawn on ${formatDate(wallet?.funds_available?.todays_date)}`,
            amount: wallet?.funds_available?.todays_payout || 0,
            showTooltip: false,
        },
        {
            type: 'Payout refunds',
            description: `Refunds from failed payouts are added to your wallet.`,
            amount: wallet?.funds_available?.payout_refunds || 0,
            showTooltip: false,
        },
        {
            type: 'Wallet balance',
            description: '',
            amount: wallet?.funds_available?.opening_balance + wallet?.funds_available?.todays_deposit + wallet?.funds_available?.todays_payout || 0,
            showTooltip: false,
        },
    ];

    const fundsOnTheWay = [
        {
            type: "Today's sale",
            description: `Funds will be available on ${formatDate(wallet?.funds_on_way?.todays_sale?.funds_available_on)}`,
            amount: wallet?.funds_on_way?.todays_sale?.amount || 0,
            showTooltip: false,
        },
        {
            type: "Yesterday's sale",
            description: `Funds will be available on ${formatDate(wallet?.funds_on_way?.previous_day_sale?.funds_available_on)}`,
            amount: wallet?.funds_on_way?.previous_day_sale?.amount || 0,
            showTooltip: false,
        },
        {
            type: 'Pending funds',
            description: 'Your pending funds will be available anytime soon',
            amount: wallet?.funds_on_way?.past_pending_funds?.amount || 0,
            showTooltip: true,
            toolTipContent: 'Funds are still being processed and have not yet cleared. We will update you once the funds are successfully transferred.',
        },
        {
            type: 'Total funds',
            description: '',
            amount: wallet?.funds_on_way?.total_pending_funds || 0,
            showTooltip: false,
        },
    ];

    const getWalletDetails = async () => {
        try {
            const response = await getWalletBalance({business_id: business.id});
            if (response.success) {
                setWallet(response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleClick = () => {
        const newUrl = `/dashboard?filter=deposited`;
        navigate(newUrl, {replace: true});
        setPrimaryTabValue(2);
        setVisible({reports: false, location: false, dateSelector: true});
    };

    useEffect(() => {
        getWalletDetails();
        //Handling modal after creating beneficiary
        const showModal = localStorage.getItem('showBeneficiaryModal');
        if (showModal) {
            setBeneficiaryModal(true);
            localStorage.removeItem('showBeneficiaryModal');
        }
    }, []);

    return (
        <>
            <section style={{backgroundColor: theme.topBarBG, margin: '30px 0px', height: '100%'}}>
                <div className="layoutMainInnerBox">
                    <Header
                        title={I18n.balance_summary}
                        type="search"
                        hideSearch={true}
                        rightCta={
                            userPermission?.canCrud
                                ? {
                                      action: handleTransfer,
                                      title: I18n.transfer_money,
                                  }
                                : null
                        }
                    />
                    <div className="topCardsBox walletCardBox">
                        <Container fluid>
                            <Row>
                                {OVERVIEW_DATA.map((item, index) => {
                                    return (
                                        <Col key={index} md={4} xs={12}>
                                            <TotalTiles percent={item.percent} amount={item.amount} item={item} />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Container>
                    </div>
                    <Container fluid>
                        <Row>
                            <Col md={12} xs={12}>
                                <FundsJourney title={I18n.funds_available} list={fundsAvailable} />
                            </Col>

                            <Col md={12} xs={12} className="marTop30">
                                <FundsJourney title={I18n.funds_on_the_way} list={fundsOnTheWay} />
                            </Col>

                            <Col md={12} xs={12} className="marTop30">
                                <RecentDeposits
                                    title={I18n.recent_deposits}
                                    list={wallet?.recent_deposits}
                                    footer={{
                                        title: I18n.see_more,
                                        action: handleClick,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <BeneficiaryModal
                toggle={beneficiaryModal}
                setToggle={setBeneficiaryModal}
                selectedBeneficiaries={selectedBeneficiaries}
                setSelectedBeneficiaries={setSelectedBeneficiaries}
                from="wallet"
            />
        </>
    );
};

export default WalletBalance;
