import {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {Loading} from '../../components/common';
import {useAuthContext, useSessionContext} from '../../context';
const RequireSession = ({children}) => {
    const location = useLocation();
    const {business, user} = useAuthContext();
    const {session, setSession} = useSessionContext();
    const [isLoading, setIsLoading] = useState(session ? false : true);
    const [redirectPath, setRedirectPath] = useState(null);
    useEffect(() => {
        handleSession();
    }, []);
    const handleSession = async () => {
        if (!session) {
            let activeSessions = await business.getActiveSession.fetch();
            if (activeSessions.length) {
                setSession(activeSessions[0]);
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (!isLoading && !session) {
            determineRedirectPath();
        }
    }, [isLoading, session]);
    const determineRedirectPath = async () => {
        const userBusinessDetails = await user?.user_business?.fetch();
        const firstBusinessDetail = userBusinessDetails?.[0];
        const userRole = await firstBusinessDetail?.role?.fetch();
        const targetPath = userRole?.name === 'old-oscar-owner' ? '/dashboard' : '/session';
        setRedirectPath(targetPath);
    };
    if (isLoading) {
        return <Loading />;
    }
    if (!session) {
        return redirectPath ? <Navigate to={redirectPath} state={{from: location}} replace /> : <Loading />;
    }
    return children;
};
export default RequireSession;
