import {Model, Q} from '@nozbe/watermelondb';
import {ORDER_LINE_SCHEMA, ORDER_SCHEMA} from '../schema';
import {field, text, relation, children, writer, json, date, lazy} from '@nozbe/watermelondb/decorators';
import {VOID, sanitizeOrderDiscount, uuid} from '../../constants';

export default class Order extends Model {
    static table = ORDER_SCHEMA;

    static associations = {
        business: {type: 'belongs_to', key: 'business_id'},
        user: {type: 'belongs_to', key: 'user_id'},
        customer: {type: 'belongs_to', key: 'customer_id'},
        waiter: {type: 'belongs_to', key: 'waiter_id'},
        rider: {type: 'belongs_to', key: 'rider_id'},
        discount: {type: 'belongs_to', key: 'discount_id'},
        sales_tax: {type: 'belongs_to', key: 'sales_tax_id'},
        dine_in_table: {type: 'belongs_to', key: 'dine_in_table_id'},
        floor_plan: {type: 'belongs_to', key: 'floor_plan_id'},
        order_line: {type: 'has_many', foreignKey: 'order_id'},
        payment: {type: 'has_many', foreignKey: 'order_id'},
    };

    @text('number') number;
    @text('status') status;
    @text('payment_method') payment_method;
    @field('received_amount') received_amount;
    @field('change') change;
    @field('total') total;
    @field('sub_total') sub_total;
    @field('total_tax') total_tax;
    @field('total_item_level_discount') total_item_level_discount;
    @field('order_level_discount') order_level_discount;
    @field('archive') archive;
    @text('note') note;
    @date('completed_at') completed_at;
    @date('started_at') started_at;
    @text('type') type;
    @text('no_of_guests') no_of_guests;
    @field('void') void;
    @text('void_reason') void_reason;
    @field('redeemed_points') redeemed_points;
    @field('earned_points') earned_points;
    @text('parent_number') parent_number;
    @field('counter') counter;

    @json('order_discount', val => sanitizeOrderDiscount(val)) order_discount;

    @relation('business', 'business_id') business;
    @relation('user', 'user_id') user;
    @relation('customer', 'customer_id') customer;
    @relation('waiter', 'waiter_id') waiter;
    @relation('rider', 'rider_id') rider;
    @relation('discount', 'discount_id') discount;
    @relation('sales_tax', 'sales_tax_id') sales_tax;
    @relation('dine_in_table', 'dine_in_table_id') dine_in_table;
    @relation('floor_plan', 'floor_plan_id') floor_plan;

    @children('order_line') order_line;
    @children('payment') payment;

    @lazy getPendingPayments = () => this.payment.extend(Q.where('status', 'pending'));
    @lazy getCompletedPayments = () => this.payment.extend(Q.where('status', 'complete'));
    @lazy getAllPayments = () => this.payment.extend();

    @writer async createOrderLine(details) {
        return await this.collections.get(ORDER_LINE_SCHEMA).create(line => {
            line.order.set(this);
            details.product && line.product.set(details.product);
            line._raw.id = uuid();
            line.name = details.name;
            line.quantity = details.quantity;
            line.base_price = details?.product?.base_price;
            line.selling_price = details.selling_price;
            line.cost_price = details.cost_price;
            line.discount = details?.product?.product_level_discount && Number(details.product.product_level_discount.discountAmount);
            line.discount_type = details?.product?.product_level_discount && details.product.product_level_discount.discountType;
            line.unit = details.unit;
            line.rate = details.rate;
            // line.tax = details.tax
            line.tax_name = details.tax_name;
            line.tax_rate = details.tax_rate;
            line.is_tax_inclusive = details.is_tax_inclusive;
            line.is_open = details.is_open;
            line.transacted_at = new Date().getTime();
        });
    }

    @writer async updateCustomer(customer) {
        return await this.update(order => {
            order.customer.set(customer);
        });
    }

    @writer async updateWaiter(waiter) {
        return await this.update(order => {
            order.waiter.set(waiter);
        });
    }

    @writer async updateRider(rider) {
        return await this.update(order => {
            order.rider.set(rider);
        });
    }

    @writer async voidOrder(reason) {
        return await this.update(order => {
            order.void = true;
            order.void_reason = reason;
            order.status = VOID;
        });
    }

    @writer async updateFloorAndTable(val) {
        return await this.update(order => {
            val?.floor_plan && order.floor_plan.set(val.floor_plan);
            val?.dine_in_table && order.dine_in_table.set(val.dine_in_table);
            order.started_at = new Date().getTime();
            order.type = val?.type;
            order.no_of_guests = val?.no_of_guests;
        });
    }

    @writer async updateFloor(floor) {
        return await this.update(order => {
            order.floor_plan.set(floor);
        });
    }

    @writer async updateDineInTable(dineInTable) {
        return await this.update(order => {
            order.dine_in_table.set(dineInTable);
        });
    }

    @writer async updateOrderDiscount(data) {
        return await this.update(order => {
            order.order_discount = data;
        });
    }

    @writer async updatePosDiscount(discount) {
        return await this.update(order => {
            order.discount.set(discount);
        });
    }

    @writer async deletePosDiscount() {
        return await this.update(order => {
            order.discount.set(null);
        });
    }

    @writer async updateTax(tax) {
        return await this.update(order => {
            order.sales_tax.set(tax);
        });
    }
    @writer async updateEarnedPoints(points) {
        return await this.update(order => {
            order.earned_points = points;
        });
    }

    @writer async updateOrderCompletion(details) {
        return await this.update(order => {
            order.user.set(details.user);
            order.payment_method = details.payment_method;
            order.total = details.total;

            order.sub_total = details.sub_total;
            order.total_tax = details.total_tax;
            order.total_item_level_discount = details.total_item_level_discount;
            order.order_level_discount = details.order_level_discount;

            order.received_amount = details.amount;
            order.change = details.change;
            order.completed_at = new Date().getTime();

            // order.status = 'complete'
        });
    }

    @writer async updateOrderTotal(total) {
        return await this.update(order => {
            order.total = total;
        });
    }

    @writer async updateRedeemedPoints(points) {
        return await this.update(order => {
            order.redeemed_points = points;
        });
    }

    @writer async updateOrderStatus(status) {
        return await this.update(order => {
            order.status = status;
        });
    }

    @writer async archiveOrder() {
        return await this.update(order => {
            order.archive = true;
            order.status = 'deleted';
        });
    }

    @writer async updateOrderNotes(val) {
        return await this.update(order => {
            order.note = val;
        });
    }

    @writer async setOrderFieldsAndRelation(val) {
        return await this.update(order => {
            val?.floor_plan && order.floor_plan.set(val.floor_plan);
            val?.dine_in_table && order.dine_in_table.set(val.dine_in_table);
            val?.waiter && order.waiter.set(val.waiter);

            order.started_at = val.started_at;
            order.status = val?.status;
            order.note = val?.note;
            order.type = val?.type;
            order.no_of_guests = val?.no_of_guests;
            order.parent_number = val?.parent_number;
            order.total = val?.total;
        });
    }
}
