import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, CustomContainer, MessageModal, Loading} from '..';
import {useState} from 'react';
import {handleCreateCounter} from '../../../api';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

const CreateCounterComponent = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();
    const [name, setName] = useState('');
    const [errors, setErrors] = useState('');
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();
    const onChange = (label, val) => {
        if (label === I18n.counter_name) setName(val);
        setErrors('');
    };

    const handleSave = async () => {
        if (!name) {
            setErrors(I18n.please_enter_counter_name);
            return;
        }
        setLoading(true);
        let paramsForCounter = {
            name,
            business_id: business.id,
        };

        try {
            const response = await handleCreateCounter(paramsForCounter);

            if (response.status) {
                toast.info(I18n.counter_created_successfully);
                navigate('/counters');
            } else {
                setErrors(response.mesg);
            }
        } catch (error) {
            setErrors(error.response.data.mesg || 'Error while Create counter');
        } finally {
            setLoading(false);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') handleSave();
    };

    const handleBack = () => toggleDiscardModal();
    const toggleDiscardModal = () => setDiscardModalToggle(x => !x);
    const handleDiscardAction = () => navigate(-1);

    return loading ? (
        <Loading />
    ) : (
        <div>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleBack}
                saveCta={{
                    title: I18n.save,
                    disabled: loading,
                    action: () => handleSave(),
                }}
            />
            <CustomContainer reduceHeight={110} className="addScreenMainBox createCustomerContainer">
                <Row className="justify-content-md-center" onKeyDown={handleEnterPress}>
                    <Col md={6}>
                        <h3 className="fontSize24 textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {I18n.create_a_counter}
                        </h3>
                        <h3 className="fontSize20 fontWeight400 marBot20 marTop0 marLeft20" style={{color: theme.text}}>
                            {I18n.counter_details}
                        </h3>

                        <div className="itemFields">
                            <TextField
                                onChange={onChange}
                                error={errors}
                                label={I18n.counter_name}
                                placeholder={I18n.counter_name}
                                value={name}
                                autoFocus={true}
                                required={true}
                                maxLength={50}
                            />
                        </div>
                    </Col>
                </Row>
                <MessageModal
                    className="messageModal"
                    setToggle={setDiscardModalToggle}
                    toggle={discardModalToggle}
                    description={I18n.do_you_really_want_to_discard}
                    subDescription={I18n.this_action_is_irreversible_once_you_discard_your_counter_it_its_gone}
                    secondaryCta={{
                        title: I18n.cancel,
                        action: toggleDiscardModal,
                    }}
                    primaryCta={{
                        title: I18n.discard,
                        backgroundColor: theme.white,
                        borderColor: theme.red,
                        txtColor: theme.red,
                        action: handleDiscardAction,
                        isDelete: true,
                    }}
                />
            </CustomContainer>
        </div>
    );
};

export default CreateCounterComponent;
