const {innerHeight} = window;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const HASH_SALT = '$2a$10$sI9oxcJDNyGjoZl0jSqu/e';
export const INITIAL_PAGE = 0;
export const DEVICE_HEIGHT = innerHeight;
export const PHONE_REGEX = /^03[0-9]{9}$/;
export const DECIMAL_PATTERN_REGEX = /^\d*\.?\d*$/;
export const NumberRegex = /^[0-9]+$/;
export const ALPHABET_REGIX = /^[a-zA-Z]+$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9]+$/;
export const NAME_REGEX = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
export const CNIC_REGEX = /^[0-9]{13}$/;
export const PRICE_REGEX = /^\d+(\.\d+)?$/;
export const DATE_REGEX = /^\d{2} [a-zA-Z]{3} \d{4}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const total_list_space = DEVICE_HEIGHT - 290;
export const ITEMS_PER_PAGE = 15;
export const RETAIL = 'Retail';
export const RESTAURANT = 'Restaurant';
export const OSCAR_BENEFICIARY_VERIFICATION = 'oscar_beneficiary_verification';
export const SPLIT_BILL_BY_AMOUNT = 'Split bill by amount';
export const SPLIT_INTO_EQUAL_PAYMENTS = 'Split into equal payments';
export const SPLIT_BILL_BY_DISH = 'Split bill by dish';

export const toSnakeCase = input => input?.replace(/ /g, '_').toLowerCase();
export const COUNTRY = [{name: 'Pakistan'}, {name: 'Saudi Arabia'}, {name: 'India'}, {name: 'Dubai'}, {name: 'UAE'}];
export const TABLE_SHAPES = [
    {
        name: 'Square',
        iconName: 'rectangleIcon',
        height: 20,
        width: 40,
        viewBox: '0 0 40 20',
    },
    {
        name: 'Round',
        iconName: 'roundIcon',
        height: 20,
        width: 40,
        viewBox: '0 0 40 20',
    },
];

export const SALARY_TYPE = [
    {
        name: 'Hourly',
    },
    {
        name: 'Weekly',
    },
    {
        name: 'Monthly',
    },
];

export const DEALS = [
    {
        name: 'Family Pack',
        type: 'Rupees',
        amount: 4000,
        discount_rule: 'Items',
        options: 2,
    },
    {
        name: 'World cup 2024',
        type: 'Rupees',
        amount: 3000,
        discount_rule: 'Items',
        options: 3,
    },
    {
        name: 'FLAT 1000',
        type: 'Rupees',
        amount: 1000,
        discount_rule: 'Items',
        options: 4,
    },
];

export const AMOUNT_TYPES = [
    {
        type: 'Amount (Rs)',
    },
    {
        type: 'Percentage (%)',
    },
];

export const BUSINESS_FLOORS = [
    {
        name: 'Dine In',
        type: 'dinein',
    },
    {
        name: 'Takeaway',
        type: 'takeaway',
    },
    {
        name: 'Delivery',
        type: 'delivery',
    },
];

export const PENDING_FUNDS = ['Payout refunds', 'Pending funds'];

export const ROLE_PERMISSION = [
    {
        title: 'Point of Sale',
        permisionDetails: 'Allow team member to control general POS functions',
        id: 'pos',
        allowedBusinessTypes: [RETAIL],
    },
    {
        title: 'Point of Sale',
        permisionDetails: 'Allow team member to control general POS functions',
        id: 'restaurant-pos',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Dashboard',
        permisionDetails: 'Allow team member to view analytic dashboard',
        id: 'dashboard',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Pos Cockpit',
        permisionDetails: 'Allow team member to control pos cockpit',
        id: 'pos-cockpit',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Purchase Order',
        permisionDetails: 'Allow team member to control purchase orders',
        id: 'purchaseorder',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Company',
        permisionDetails: 'Allow team member to control company',
        id: 'company',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Sales Overview',
        permisionDetails: 'Allow team member to control sales overview',
        id: 'sales-overview',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Wallet Overview',
        permisionDetails: 'Allow team member to control wallet overview',
        id: 'wallet-overview',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Items',
        permisionDetails: 'Allow team member to control items',
        id: 'items',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Categories',
        permisionDetails: 'Allow team member to control categories',
        id: 'category',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Deals',
        permisionDetails: 'Allow team member to control deals',
        id: 'deals',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Modifiers',
        permisionDetails: 'Allow team member to control deals',
        id: 'modifiers',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Order',
        permisionDetails: 'Allow team member to control orders',
        id: 'orders',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Customer',
        permisionDetails: 'Allow team member to control customers',
        id: 'customers',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Waiter',
        permisionDetails: 'Allow team member to control waiters',
        id: 'waiters',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Rider',
        permisionDetails: 'Allow team member to control riders',
        id: 'riders',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Beneficiary',
        permisionDetails: 'Allow team member to control beneficiaries',
        id: 'beneficiary',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Floor Plans',
        permisionDetails: 'Allow team member to control floor plans',
        id: 'floor-plans',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Discounts',
        permisionDetails: 'Allow team member to control discounts',
        id: 'discounts',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Supplier',
        permisionDetails: 'Allow team member to control suppliers',
        id: 'suppliers',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Taxes',
        permisionDetails: 'Allow team member to control sales and purchase tax',
        id: 'tax',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Teams',
        permisionDetails: 'Allow team member to control teams',
        id: 'team',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Location',
        permisionDetails: 'Allow team member to control locations',
        id: 'location',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Settings',
        permisionDetails: 'Allow team member to control settings',
        id: 'setting',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Devices',
        permisionDetails: 'Allow team member to control devices',
        id: 'device',
        allowedBusinessTypes: [RESTAURANT],
    },
    {
        title: 'Counter',
        permisionDetails: 'Allow team member to control counter',
        id: 'counter',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
    {
        title: 'Reports',
        permisionDetails: 'Allow team member to control reports',
        id: 'reports',
        allowedBusinessTypes: [RETAIL, RESTAURANT],
    },
];

export const CATEGORY_COLORS = [
    {
        name: 'gray',
        hexCode: '#898F93',
    },
    {
        name: 'blue',
        hexCode: '#1759B1',
    },
    {
        name: 'sea-green',
        hexCode: '#00B8AD',
    },
    {
        name: 'dark-green',
        hexCode: '#007052',
    },
    {
        name: 'light-green',
        hexCode: '#2BA72B',
    },
    {
        name: 'yellow',
        hexCode: '#FFB200',
    },
    {
        name: 'orange',
        hexCode: '#F75221',
    },
    {
        name: 'red',
        hexCode: '#C00000',
    },
    {
        name: 'brown',
        hexCode: '#574734',
    },

    {
        name: 'purple',
        hexCode: '#7E4CD5',
    },
];

export const ACTIVE = 'active';
export const VOID = 'void';
export const PREPARING = 'preparing';
export const READY = 'ready';
export const SERVE = 'serve';
export const PRINT_BILL = 'print_bill';
export const PAYMENT = 'payment';
export const COMPLETE = 'complete';
export const DISPATCH = 'dispatch';
export const DELIVERY = 'delivery';
export const TAKEAWAY = 'takeaway';
export const DINE_IN = 'dinein';

export const PROVINCE = [{name: 'Sindh'}, {name: 'Punjab'}, {name: 'KPK'}, {name: 'Balochistan'}];

export const LOCATION_TYPE = [
    {
        name: 'Physical location',
    },
    {
        name: 'Mobile location',
    },
];

export const EQUAL_PAYMENTS = [
    {
        value: 2,
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
];

export const WALLET_CARD_DATA = [
    {
        iconName: 'dualArrowIcon',
        iconHeight: 24,
        iconWidth: 24,
        iconViewBox: '0 0 18 18',
        title: 'Instant Balance Overview',
        para: 'Track your available balance and transaction history in real-time.',
    },
    {
        iconName: 'settingIcon',
        iconHeight: 24,
        iconWidth: 24,
        iconViewBox: '0 0 18 18',
        title: 'Easy Beneficiary Management',
        para: 'Add, edit, or delete beneficiaries in just a few clicks.',
    },
    {
        iconName: 'securityIcon',
        iconHeight: 24,
        iconWidth: 24,
        iconViewBox: '0 0 18 18',
        title: 'Fast & Secure Transfers',
        para: 'Send money to any bank or digital wallet securely with our quick transfer process.',
    },
    {
        iconName: 'documentIcon',
        iconHeight: 24,
        iconWidth: 24,
        iconViewBox: '0 0 18 18',
        title: 'Detailed Transaction History',
        para: 'Stay on top of every transaction with a detailed log of all transactions type.',
    },
];

export const PAYMENT_STATUS = {
    PENDING: 'Processing',
};

export const ONBOARDING_KEYS = {
    Address: 'address',
    City: 'city',
    Province: 'province',
    'What is your business type?': 'type',
    'What kind of business are you?': 'kind',
    'Enter your business name': 'name',
    'Location type': 'location_type',
    'Location name': 'location_name',
};

export const Country_Name = [{name: 'Pakistan'}, {name: 'Saudi Arabia'}, {name: 'America'}, {name: 'United Kingdom'}];

export const CITY = [{name: 'Karachi'}, {name: 'Hyderabad'}, {name: 'Lahore'}];

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const SPLIT_BILL = [
    {
        title: 'Split bill by amount',
        subTitle: 'Split the bill between people by setting the amount for each.',
        value: 'split_bill_by_amount',
    },
    {
        title: 'Split into equal payments',
        subTitle: 'Split the total bill equally among everyone.',
        value: 'split_into_equal_payments',
    },
    {
        title: 'Split bill by dish',
        subTitle: 'Split the bill by dish, so everyone pays for what they ordered.',
        value: 'split_bill_by_dish',
    },
];
export const DISCOUNT_TYPES = [
    {
        title: 'Items',
        subTitle: 'Apply to specific items. (e.g. Holiday items)',
        value: 'items',
    },
    {
        title: 'Quantity',
        subTitle: 'Apply when a number of items are purchased. (e.g. Buy one, get one free)',
        value: 'quantity',
    },
];
export const kitchenOrders = [
    {
        notificationText: 'New order received',
        cashier: 'Mubashir',
        number: '01',
        type: 'Delivery',
        punchInTime: 1708326162956,
        punchInDate: '16 May 2023',
        status: 'on-time',
        items: [
            {
                id: 1,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,
                name: 'Chicken Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,
                name: 'Club sandwich',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,
                name: 'Fries',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },

    {
        notificationText: 'New order received',
        cashier: 'Hassan',
        number: '02',
        punchInDate: '16 May 2023',
        type: 'Dine In',
        punchInTime: 1708326162966,
        status: 'delayed',
        items: [
            {
                id: 1,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
    {
        notificationText: 'Order cancelled',
        cashier: 'Farhan',
        number: '03',
        punchInDate: '16 May 2023',
        type: 'Take away',
        punchInTime: 1708326161956,
        status: 'late',
        items: [
            {
                id: 1,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
    {
        cashier: 'Mubashir',
        number: '05',
        punchInDate: '16 May 2023',
        notificationText: 'Order recalled',
        type: 'Delivery',
        punchInTime: 1708327482956,
        status: 'recalled',
        items: [
            {
                id: 1,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
    {
        cashier: 'Moiz',
        number: '04',
        notificationText: 'New order received',
        type: 'Delivery',
        punchInDate: '16 May 2023',
        punchInTime: 1708327162956,

        status: 'cancelled',
        items: [
            {
                id: 1,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
];
export const KDSIntegrations = [
    {
        icon: 'linkIcon',
        headText: 'Share Oscar kitchen display URL with member',
        paraText: 'Share Oscar kitchen display URL with member, then member will use this URL as a kitchen display.',
        linkText: 'https://oscar.pk/kds-login',
        linkRoute: 'https://oscar.pk/kds-login',
        iconHeight: '30',
        iconWidth: '30',
        copyCode: false,
    },
    {
        icon: 'lockIcon',
        headText: 'Sign in',
        paraText: 'Use this device code to sign in to Oscar kitchen display',
        linkText: 'Send device code',
        linkRoute: 'send-device-code',
        iconHeight: '30',
        iconWidth: '30',
        copyCode: true,
    },
    {
        icon: 'groupIcon',
        headText: 'Create pin code (Optional)',
        paraText: 'Use the pin code to add extra layer of security to your KDS device',
        linkText: 'Create pin code',
        linkRoute: 'create-pin',
        iconHeight: '26',
        iconWidth: '26',
        copyCode: false,
    },
    {
        icon: 'odooIcon',
        headText: 'Third party integration',
        paraText: 'Integrate your KDS with the Odoo Oscar POS',
        linkText: 'Connect Odoo',
        linkRoute: 'connect-odoo',
        iconHeight: '13',
        iconWidth: '40',
        copyCode: false,
    },
];

export const teamMembers = [
    {
        id: 123,
        fullName: 'Muhammad Moiz',
        username: 'Moiz',
        cnic: '4200027217632',
        password: 'pass123',
        phone: '03443457239',
        last_visited: 'Today',
        role: 'manager',
        created: '1 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 234,
        fullName: 'Uzair Hassan',
        username: 'Uzair',
        cnic: '4200027217732',
        password: 'pass123',
        phone: '03443457238',
        last_visited: 'Yesterday',
        role: 'admin',
        created: '2 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 345,
        fullName: 'Sherry Rahman',
        username: 'Sherry',
        cnic: '4200027217612',
        password: 'pass123',
        phone: '03443457229',
        last_visited: 'Last Month',
        role: 'admin',
        created: '3 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 456,
        fullName: 'Ghulam Mustafa',
        username: 'Ghulam',
        cnic: '4200027217633',
        password: 'pass123',
        phone: '03443457249',
        last_visited: 'Today',
        role: 'manager',
        created: '4 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 567,
        fullName: 'Asim Khan',
        username: 'Asim',
        cnic: '4200027217612',
        password: 'pass123',
        phone: '03443457230',
        last_visited: 'Yesterday',
        role: 'admin',
        created: '5 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 678,
        fullName: 'Obaid Ansari',
        username: 'Obaid',
        cnic: '4200027217632',
        password: 'pass123',
        phone: '03443457231',
        last_visited: 'Last month',
        role: 'head-cashier',
        created: '1 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 789,
        fullName: 'Waleed Khan',
        username: 'Waleed',
        cnic: '4200027217612',
        password: 'pass123',
        phone: '03443457249',
        last_visited: 'Today',
        role: 'admin',
        created: '6 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
];

export const dininTables = [
    {
        id: 1,
        name: 'Table 01',
        height: 100,
        width: 300,
        seats: 0,
        shape: 'square',
        xAxis: 100,
        yAxis: 300,
    },

    {
        id: 2,
        name: 'Table 02',
        height: 100,
        width: 300,
        seats: 0,
        shape: 'square',
        xAxis: 500,
        yAxis: 200,
    },
];

export const cockpitOrders = [
    {
        number: '0001',
        time: '04:14 PM/ 0mins',
        type: 'Dine in',
        table: 'Table 01',
        customer: 'Zubair',
        waiter: 'Karim',
        rider: 'Tanveer',
        status: 'Preparing',
        amount: '740',
    },
    {
        number: '0002',
        time: '02:14 PM/ 0mins',
        type: 'Delivery',
        table: 'Table 02',
        customer: 'Farhan',
        waiter: 'Ghulam',
        rider: 'Moiz',
        status: 'Preparing',
        amount: '840',
    },
    {
        number: '0003',
        time: '01:14 PM/ 0mins',
        type: 'Takeaway',
        table: 'Table 03',
        customer: 'Zubair',
        waiter: 'Karim',
        rider: 'Tanveer',
        status: 'Preparing',
        amount: '710',
    },
    {
        number: '0004',
        time: '09:14 PM/ 0mins',
        type: 'Dine in',
        table: 'Table 04',
        customer: 'Zubair',
        waiter: 'Obaid',
        rider: 'Farhan',
        status: 'Preparing',
        amount: '740',
    },
];

export const HEADER_VALUES = {
    pos: {
        name: 'POS',
        iconName: 'posNewIcon',
        viewBox: '0 0 24 24',
    },
    session: {
        name: 'POS',
        iconName: 'posNewIcon',
        viewBox: '0 0 24 24',
    },
    onboarding: {
        name: 'Setup POS',
        iconName: 'posNewIcon',
        viewBox: '0 0 24 24',
    },
    items: {
        name: 'Items',
        iconName: 'itemsIcon',
        viewBox: '0 0 18 18',
    },
    category: {
        name: 'Item Library',
        iconName: 'categoryIcon',
        viewBox: '0 0 18 18',
    },
    dashboard: {
        name: 'Dashboard',
        iconName: 'dashboardIcon',
        viewBox: '0 0 18 18',
    },
    suppliers: {
        name: 'Supplier',
        iconName: 'suppliesIcon',
        viewBox: '0 0 18 18',
    },
    company: {
        name: 'Suppliers',
        iconName: 'suppliesIcon',
        viewBox: '0 0 18 18',
    },
    vendor: {
        name: 'Suppliers',
        iconName: 'suppliesIcon',
        viewBox: '0 0 18 18',
    },
    orders: {
        name: 'Order history',
        iconName: 'ordersHistoryIcon',
        viewBox: '0 0 18 18',
    },
    orderdetail: {
        name: 'Order Detail',
        iconName: 'ordersHistoryIcon',
        viewBox: '0 0 18 18',
    },
    customers: {
        name: 'Customers',
        iconName: 'customersIcon',
        viewBox: '0 0 18 18',
    },
    discounts: {
        name: 'Discounts',
        iconName: 'discountIcon',
        viewBox: '0 0 18 18',
    },
    payment_method_tax: {
        name: 'Taxes',
        iconName: 'taxesIcon',
        viewBox: '0 0 18 18',
    },
    tax: {
        name: 'Taxes',
        iconName: 'taxesIcon',
        viewBox: '0 0 18 18',
    },
    invoice: {
        name: 'Invoice',
        iconName: 'invoiceIcon',
        viewBox: '0 0 18 18',
    },
    purchaseorder: {
        name: 'Purchase order',
        iconName: 'purchaseOrderNewIon',
        viewBox: '0 0 18 18',
    },
    report: {
        name: 'Reports',
        iconName: 'reportsIcon',
        viewBox: '0 0 18 18',
    },
    team: {
        name: 'Team',
        iconName: 'teamIcon',
        viewBox: '0 0 18 18',
    },
    setting: {
        name: 'Settings',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    counters: {
        name: 'Counters',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    device: {
        name: 'Devices',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    kitchendisplay: {
        name: 'Kitchen Display',
        iconName: 'posIcon',
        viewBox: '0 0 18 18',
    },
    displaysetting: {
        name: 'Display Setting',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    timersetting: {
        name: 'Timer & Alerts',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
};
export const UNITS = [
    {name: 'Box'},
    {name: 'Bags'},
    {name: 'Bottles'},
    {name: 'Cans'},
    {name: 'Pieces'},
    {name: 'Pounds (lbs)'},
    {name: 'Ounces (oz)'},
    {name: 'Kilograms (kg)'},
    {name: 'Gallons (gal)'},
    {name: 'Liters (L)'},
    {name: 'Packs'},
    {name: 'Cartons'},
    {name: 'Slices'},
    {name: 'grams (g)'},
];

export const CSV_HEADER_VALUES = {
    customer: {
        create_heading: ['Name', 'Phone Number', 'Email', 'Date of brith', 'Country', 'Province', 'City', 'Address'],
        display_heading: ['Name', 'Phone #', 'Last visited', 'Visits', 'Created', 'Average spend', 'Credit amount', 'Total spent'],
    },
    discount: {
        create_heading: ['Discount Name', 'Select discount type (%/Rs)', 'Discount rate', 'Max discount cap'],
        display_heading: ['Name', 'Location', 'Rate', 'Type', 'Given'],
    },
    tax: {
        create_heading: ['Tax Name', 'Tax rate %', 'Tax Type (Sales / Purchase)', 'Tax behavior', 'Tax Calculation', 'Tax application'],
        display_heading: ['Name', 'Type', 'Rate', 'Tax application', 'Applied on # of items'],
    },
    item: {
        create_heading: ['Name', 'Category', 'Description (is Optional)', 'Price', 'Cost per item', 'Add Qty', 'Low stock alert (is Optional)', 'Barcode (is Optional)', 'Unit'],
        display_heading: ['Items', 'Category', 'Stock available', 'Low stock', 'Price', 'Image'],
    },
};

export const ORDER_HISTORY = [
    {
        number: '01',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '02',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Farhan',
        type: 'Dine In',
        status: 'On-time',
        timeTaken: '00:15:54',
    },
    {
        number: '03',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Bashir',
        type: 'Take away',
        status: 'Cancelled',
        timeTaken: '00:15:54',
    },
    {
        number: '04',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '05',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Hassan',
        type: 'Dine In',
        status: 'On-Time',
        timeTaken: '00:15:54',
    },
    {
        number: '06',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Take away',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '07',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Tanveer',
        type: 'Delivery',
        status: 'Cancelled',
        timeTaken: '00:15:54',
    },
    {
        number: '08',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Moiz',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '09',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'On-Time',
        timeTaken: '00:15:54',
    },
    {
        number: '10',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
];
export const CSV_TEMPLATE_DUMMY_DATA = {
    customer: [
        {
            Name: 'Ali',
            'Phone Number': 3212356569,
            Email: 'Ali@gmail.com',
            'Date of brith': '18 jan 1999',
            Country: 'pakistan',
            Province: 'Sindh',
            City: 'Karachi',
            Address: 'F- 43, Block 8, Near Moti Masjid, Block 8 Clifton, Karachi, Karachi City, Sindh 75600',
        },
        {
            Name: 'Zain',
            'Phone Number': 3217646569,
            Email: 'Zain@gmail.com',
            'Date of brith': '17 jun 1999',
            Country: 'pakistan',
            Province: 'Sindh',
            City: 'Karachi',
            Address: 'Abyssinia Lines Abysenia Lines, Karachi, Karachi City, Sindh',
        },
    ],
    discount: [
        {
            'Discount Name': 'HBL Discount 10%',
            'Select discount type (%/Rs)': 'Percentage %',
            'Discount rate': 10,
            'Max discount cap': 2000,
        },
        {
            'Discount Name': 'UBL Discount 500',
            'Select discount type (%/Rs)': 'Amount Rs.',
            'Discount rate': 500,
            'Max discount cap': 3000,
        },
    ],
    tax: [
        {
            'Tax Name': 'GSt10',
            'Tax rate %': 10,
            'Tax Type (Sales / Purchase)': 'sales',
            'Tax behavior': 'exclusive',
            'Tax Calculation': 'Calculate tax based on the pre discount price',
            'Tax application': 'On all items',
        },
        {
            'Tax Name': 'GSt30',
            'Tax rate %': 30,
            'Tax Type (Sales / Purchase)': 'sales',
            'Tax behavior': 'inclusive ',
            'Tax Calculation': 'Calculate tax based on the post discount price',
            'Tax application': 'Select items',
        },
        {
            'Tax Name': 'purchase30',
            'Tax rate %': 30,
            'Tax Type (Sales / Purchase)': 'purchase',
            'Tax behavior': '-',
            'Tax Calculation': '-',
            'Tax application': '-',
        },
    ],
    item: [
        {
            Name: 'Apple',
            Category: 'fruit',
            'Description (is Optional)': 'golden Apple',
            Price: 500,
            'Cost per item': 200,
            'Add Qty': 20,
            'Low stock alert (is Optional)': 5,
            'Barcode (is Optional)': 20039994,
            Unit: 'Box',
        },
        {
            Name: 'orange',
            Category: 'fruit',
            'Description (is Optional)': 'fresh orange',
            Price: 400,
            'Cost per item': 250,
            'Add Qty': 30,
            'Low stock alert (is Optional)': 10,
            'Barcode (is Optional)': 20039995,
            Unit: 'Cartons',
        },
    ],
};
export const salesDropDown = ['Overview', 'Transactions', 'Online', 'Reports'];
export const PaymentDropDown = ['Overview', 'Balance', 'Activity'];
export const sales_dashboard_metrics = ['sales_by_channel', 'sales_by_payment_method', 'sales_by_day', 'sales_by_hour'];
export const payment_dashboard_metrics = ['average_transaction_value', 'successful_payments', 'payouts'];

export const payment_key_metrics = {
    gross_payment_section: {
        gross_payment: 650,
        available_balance: 200,
        failed_payment: 10,
    },
    net_payment_section: {
        net_payment: 200,
        total_payouts: 15,
        pending: 5,
    },
    no_of_transaction_section: {
        no_of_transaction: 20,
        successful_payments: 18,
    },
    average_transaction: {
        Sunday: 20,
        Monday: 10,
        Tuesday: 5,
        Wednesday: 8,
        Thursday: 9,
        Friday: 25,
        Saturday: 5,
    },
    successful_payments: {
        Sunday: 5,
        Monday: 20,
        Tuesday: 10,
        Wednesday: 18,
        Thursday: 6,
        Friday: 10,
        Saturday: 3,
    },
    payouts: {
        Sunday: 4,
        Monday: 6,
        Tuesday: 5,
        Wednesday: 9,
        Thursday: 12,
        Friday: 15,
        Saturday: 10,
    },
    pending_payment: [
        {
            amount: 5200,
            date: 'mar 20, 2024 8:53 PM',
            status: 'pending',
        },
        {
            amount: 5200,
            date: 'mar 20, 2024 8:53 PM',
            status: 'pending',
        },
        {
            amount: 5560,
            date: 'mar 20, 2024 8:53 PM',
            status: 'pending',
        },
        {
            amount: 8900,
            date: 'mar 20, 2024 8:53 PM',
            status: 'pending',
        },
        {
            amount: 1030,
            date: 'mar 20, 2024 8:53 PM',
            status: 'pending',
        },
    ],
};

export const salesReportDropDown = [
    {name: 'Sales Summary', value: 'sale_summary', placeholder: 'reports', inputType: 'select'},
    {name: 'Payment Methods', value: 'payment_summary', placeholder: 'reports', inputType: 'select'},
    {name: 'Item Sales', value: 'item_sales', placeholder: 'reports', inputType: 'select'},
    {name: 'Category Sales', value: 'category_summary', placeholder: 'reports', inputType: 'select'},
    {name: 'Discount', value: 'discount_summary', placeholder: 'reports', inputType: 'select'},
    {name: 'Sales Tax', value: 'tax_summary', placeholder: 'reports', inputType: 'select'},
];

export const mentorReportTypeOption = [
    {name: 'Master Ledger', value: 'master_ledger', placeholder: 'reports', inputType: 'select'},
    {name: 'Location Level Ledger', value: 'location_level_ledger', placeholder: 'reports', inputType: 'select'},
    {name: 'Oscar Ledger', value: 'oscar_ledger', placeholder: 'reports', inputType: 'select'},
    {name: 'Cube and Dots Ledger', value: 'cube_and_dots_ledger', placeholder: 'reports', inputType: 'select'},
    {name: 'Affiliate Partner Ledger', value: 'affiliate_partner_ledger', placeholder: 'reports', inputType: 'select'},
];

export const CardData = [
    {
        title: 'taxable_item_sales',
        value: '9,258.74',
    },
    {
        title: 'non_taxable_item_sales',
        value: '712.50',
    },
];

export const TopSell = [
    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
    {
        itemname: 'Card tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },

    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
    {
        itemname: 'Sales tax',
        qty: '18',
        unitCost: '6',
        ucPercent: '7,914.87',
        salePrice: '1,187.23',
    },
];

export const DateDropDownList = [
    {name: 'today', option: 'today', placeholder: 'Date', inputType: 'select'},
    {name: 'yesterday', option: 'yesterday', placeholder: 'Date', inputType: 'select'},
    {name: 'This week', option: 'this_week', placeholder: 'Date', inputType: 'select'},
    {name: 'Last week', option: 'last_week', placeholder: 'Date', inputType: 'select'},
    {name: 'This month', option: 'this_month', placeholder: 'Date', inputType: 'select'},
    {name: 'Last month', option: 'last_month', placeholder: 'Date', inputType: 'select'},
    {name: 'This quarter', option: 'this_quarter', placeholder: 'Date', inputType: 'select'},
    {name: 'Last quarter', option: 'last_quarter', placeholder: 'Date', inputType: 'select'},
    {name: 'This year', option: 'this_year', placeholder: 'Date', inputType: 'select'},
    {name: 'Last year', option: 'last_year', placeholder: 'Date', inputType: 'select'},
];

export const dateFilterMapping = {
    Today: 'today',
    Yesterday: 'yesterday',
    'This week': 'this_week',
    'Last week': 'last_week',
    'This month': 'this_month',
    'Last month': 'last_month',
    'This quater': 'this_quater',
    'Last quater': 'last_quater',
    'This year': 'this_year',
    'Last year': 'last_year',
    Custom: 'custom',
};

export const DateFilterOption = [
    {name: 'Today'},
    {name: 'Yesterday'},
    {name: 'This week'},
    {name: 'Last week'},
    {name: 'This month'},
    {name: 'Last month'},
    {name: 'This year'},
    {name: 'Last year'},
];

export const channelPaymentData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    values: [5000, 8300, 7500, 9870, 7900, 8100, 7000],
    additionalText: ['Text 1', 'Text 2', 'Text 3', 'Text 4'],
    stylesData: {
        barColor: '#A5B0EE',
        barBgColor: '#313547',
        textColor: 'white',
        secondaryTextColor: '#CECECE',
        radius: 14,
    },
};

export const PandingPaymentData = [
    {
        amount: 5000,
        date: 'mar 20, 2024 8:53 PM',
        status: 'pending',
    },
    {
        amount: 5560,
        date: 'mar 20, 2024 8:53 PM',
        status: 'pending',
    },
    {
        amount: 8900,
        date: 'mar 20, 2024 8:53 PM',
        status: 'pending',
    },
    {
        amount: 1030,
        date: 'mar 20, 2024 8:53 PM',
        status: 'pending',
    },
];
export const paymentMockdata = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    values: [5000, 8300, 7500, 9870, 7900, 8100, 7000],
    additionalText: ['Text 1', 'Text 2', 'Text 3', 'Text 4'],
    stylesData: {
        barColor: '#A5B0EE',
        barBgColor: '#313547',
        textColor: 'white',
        secondaryTextColor: '#CECECE',
        radius: 14,
    },
};
