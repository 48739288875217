export default {
    languageType: 'en',
    lang: 'en',
    welcome: 'WELCOME',
    select_your_language: 'SELECT YOUR LANGUAGE',
    select_theme: 'SELECT YOUR THEME',
    phone_number_placeholder: 'Mobile Number',
    whats_your_phone_number: "What's your mobile number?",
    lets_create_your_account: 'Let’s create your account.',
    enter_mobile_number: 'Enter Mobile Number',
    enter_your_phone: 'Enter your phone',
    enter_password: 'Enter your password',
    enter_email: 'Enter your email address',
    login: 'Login',
    forgot_password: 'Forgot password?',
    lets_create_account: 'Let’s create your account',
    signing_oscar_is_fast: 'Signing up for Oscar is fast and free. No commitments or long-term contracts',
    first_name: 'First name',
    last_name: 'Last name',
    enter_your_phone_number: 'Enter your phone number',
    create_password: 'Create a password',
    confirm_password: 'Confirm new password',
    continue: 'Continue',
    account_created: 'Congratulations, your account is ready to roll! 🎉',
    few_more_questions: "Let's put the finishing touches together to ensure you have all the right tools to start earning.",
    tell_us_about_business: 'Tell us a little about your business',
    help_you_get_start: 'We’ll help you get started based on your responses',
    thank_you: 'Thank you',
    starting_pos: 'We are loading your journey to success',
    start_selling: 'Add your precious items and start winning',
    items: 'Items',
    settings: 'Settings',
    pos: 'Pos',
    session: 'POS',
    language: 'Language',
    profile: 'Profile',
    backup_now: 'Back up now',
    logout: 'Log out',
    add_customer: 'Add customer',
    no_customer_found: 'No customer found',
    create_new_customer_and_add_to_sale: 'Create new customer and add to sale',
    create_new_waiter_and_add_to_sale: 'Create new waiter and add to sale',
    before_you_open: 'Before you open your store, first you need some products',
    import_customers: 'Import customers',
    create_customer: 'Create customer',
    set_up_team: 'Setup team',
    second_let_create_your_team: "Second, let's create your team",
    set_up_your_team_members: 'Setup your team members and configure permissions',
    create_member: 'Create member',
    next: 'Next',
    pos_is_loading: 'Your POS is loading',
    stay_few_seconds: 'Stay with us for few seconds',
    create_an_item: 'Create an item',
    item_details: 'Item details',
    pricing: 'Pricing',
    inventory: 'Inventory',
    name: 'Name',
    category: 'Category',
    description: 'Description',
    locations: 'Locations',
    all_location: 'All Locations',
    price: 'Price',
    drag_image_here: 'Drag image here,',
    upload: 'Upload',
    browse_image_library: 'browse image library.',
    compare: 'Compare at price',
    charge_tax: 'Charge tax on this product',
    cost_per_item: 'Cost per item',
    tax_value: 'Tax',
    profit: 'Profit',
    margin: 'Margin',
    track_quantity: 'Track quantity',
    add_qty: 'Add Qty',
    stock_alert: 'Low stock alert',
    sku: 'SKU',
    barcode: 'Barcode',
    variants: 'Variants',
    add_variant_options: 'Add options like size or color',
    set_up_inventory: 'Setup Inventory',
    import_items: 'Import items',
    create_item: 'Create item',
    error: 'Error',
    setup_guide: 'Setup guide',
    nav_pos: 'POS',
    suppliers: 'Suppliers',
    orders_history: 'Orders history',
    customers: 'Customers',
    discounts: 'Discounts',
    taxes: 'Taxes',
    invoices: 'Invoices',
    purchase_order: 'Purchase order',
    reports: 'Reports',
    teams: 'Teams',
    team_members: 'Team members',
    all_members: 'All members',
    nav_logout: 'Logout',
    we_will_send_you_verification_code: 'We will send you a verification code',
    oscar_is_fast_and_free_forever: 'Oscar is fast and free, forever.',
    we_have_sent_a_verification_code_to: 'We have sent a verification code to',
    no_internet_please_login_again: 'No internet, please login again',
    timeout_message: "There's a problem in restoring your data, please log in again",
    enter_your_verification_code: 'Enter Your Verification Code',
    verification_code: 'VERIFICATION CODE',
    view_report: 'View report',
    business_updated_success: 'Business Updated successfully',
    coming_soon: 'Coming Soon',
    search_or_scan_items: 'Search or scan items (Shift+S)',
    first_up_what_are_you_selling: 'First up: what are you selling?',
    before_you_open_your_store: 'Before you open your store, first you need some products.',
    dont_have_an_account: "Don't have an account?",
    create_an_account: 'Create new account',
    back: 'Back',
    skip: 'Skip',
    phone_number_format: 'Phone number should be in format e.g. 03xxxxxxxxx',
    password_does_not_match: 'Password does not match',
    enter_username: 'Enter your username',
    what_kind_of_business_are_you: 'What kind of business are you?',
    what_is_your_business_type: 'What is your business type?',
    province: 'Province',
    city: 'City',
    what_is_your_business_name: 'Enter your business name',
    address: 'Address',
    username_already_exists: 'Username already exists',
    phone_already_exists: 'Phone number already exists',
    api_is_down: 'Api is down',
    use_this_personalized_guide_to_get_your_store: 'Use this personalized guide to get your store up and running.',
    owner: 'Owner',
    set_up_printer: 'Setup printer receipt',
    last_lets_set_up_printer: "Last, let's setup your printer",
    configure_your_printer_and_receipts: 'Configure your printer & receipts',
    save: 'Save',
    add_photo: 'Add Photo',
    customer_details: 'Customer details',
    selling_price_is_required: 'Selling price is required',
    cost_price_is_required: 'Cost price is required',
    quantity_is_required: 'Quantity is required',
    enter_name: 'Enter name',
    phone_number: 'Phone number',
    enter_phone_number: 'Enter phone number',
    email_address_optional: 'Enter email address (optional)',
    enter_email_address: 'Enter email address',
    select_dat_birth: 'Select date of birth',
    province_optional: 'Province (optional)',
    select_province: 'Select province',
    city_optional: 'City (optional)',
    select_city: 'Select city',
    address_optional: 'Address (optional)',
    enter_address: 'Enter address',
    create_team_member: 'Create a team member',
    edit_team_member: 'Edit team member',
    team_member_details: 'Team member details',
    hold: 'Hold',
    new: 'New',
    delete: 'Delete',
    create_an_option: 'Create an option',
    create_variants: 'Create variants',
    create_variant_first_create_option: 'To create a variant, first create an option set.',
    see_video: 'See video',
    option_set: 'Option set',
    size_color_etc: 'Size, color, etc',
    option: 'Option',
    options: 'Options',
    option_set_type: 'Option set type',
    small_medium_large: 'small, medium, large, etc',
    option_type: 'Option type',
    values: 'Values',
    text: 'Text',
    text_and_color: 'Text & color',
    value_already_selected: 'Value already selected',
    please_enter_a_value: 'Please enter a value',
    do_you_want_to_create_variants: 'Do you want to create variants',
    yes: 'Yes',
    or: 'or',
    no: 'No',
    add_option_set: 'Add option set',
    add_option_like_size_color: 'Add options like size or color',
    create_option: 'Create option',
    select_option: 'Select option',
    variations_below_will_be_created: 'The variations below will be created from your options.',
    all_variations: 'All variations',
    variation_list: 'Variation list',
    discard: 'Discard',
    close: 'Close',
    variations: 'Variations',
    quantity: 'Quantity',
    cost_price: 'Cost price',
    sell_price: 'Sell price',
    unit: 'Unit',
    secondary_unit: 'Secondary unit',
    conversion_rate: 'Conversion rate',
    low_stock: 'Low stock',
    enter_barcode: 'Enter barcode',
    select_tax: 'Select Tax',
    order: 'Order',
    order_placed_successfully: 'Order placed successfully',
    order_updated_successfully: 'Order updated successfully',
    order_completed_successfully: 'Order completed successfully',
    item_image: 'Item Image',
    edit: 'Edit',
    upload_image: 'Upload image',
    set_your_opening_balance: 'Set your opening balance',
    date: 'Date',
    opening_balance: 'Opening balance',
    submit: 'Submit',
    create_pin_code: 'Create 4 digit pin code',
    pin_code: 'Pin code',
    enter_pin_code: 'Enter pin code',
    ok: 'Ok',
    cancel: 'Cancel',
    search_item_by_name_and_barcode: 'Search items by name and barcode (Shift + S)',
    all_inventory: 'All inventory',
    actions: 'Actions',
    import_library: 'Import library',
    export_library: 'Export Library',
    search_item_by_name: 'Search item by name, barcode or SKU',
    low_stock_alert: 'Low stock alert',
    sold_out: 'Sold out',
    stock_available: 'Stock available',
    duplicate: 'Duplicate',
    create_quick_item: 'Create quick item',
    categories: 'Categories',
    open_item: 'Open item (Shift + O)',
    open_item_name: 'Open item',
    add_new_item: 'Add new item (Shift + N)',
    payment_shift: 'Payment (Shift + Enter)',
    item_list: 'Item List',
    clear_all_items: 'Clear all items',
    sub_total: 'Sub total',
    total_tax: 'Total tax',
    discount: 'Discount',
    bill_amount: 'Bill amount',
    invalid_pin: 'Invalid pin',
    home: 'Home',
    all_customers: 'All Customers',
    create_quick_customer: 'Create quick customer',
    phone_num: 'Phone #',
    last_visited: 'Last visited',
    visits: 'Visits',
    created: 'Created',
    average_spend: 'Average spend',
    credit_amount: 'Credit amount',
    total_spent: 'Total spent',
    search_customer_by_name: 'Search customers by name or number',
    date_of_birth_optional: 'Date of birth (optional)',
    enter_cash_amount: 'Enter cash amount',
    change: 'Change',
    payments: 'Payments',
    redeem_points: 'Redeem points',
    order_discount: 'Order discount',
    cash: 'Cash',
    card: 'Card',
    wallet: 'Wallet',
    credit: 'Credit',
    customer_information: 'Customer Information',
    order_id: 'Order ID',
    amount: 'Amount',
    record_payment: 'Record Payment (Enter)',
    swipe_your_card: 'Swipe your card on machine',
    amount_paid_via: 'Amount paid via',
    charged: 'Charged',
    transaction_successful: 'Transaction successful',
    print_receipt: 'Print receipt',
    create_new_discount: 'Create new discount',
    create_discount: 'Create discount',
    discount_details: 'Discount details',
    create: 'Create',
    discount_name: 'Discount Name',
    enter_discount_name: 'Enter discount name',
    tax_behaviour: 'Tax behavior',
    select_taxation_behaviour: 'Select taxation behaviour',
    discount_amount_limit: 'Discount amount limit',
    enter_discount_amount_limit: 'Enter discount amount limit',
    select_discount_type: 'Select discount type',
    max_discount_cap: 'Max discount cap',
    enter_max_discount_cap: 'Enter max discount cap',
    location: 'Location',
    create_location: 'Create location',
    edit_location: 'Edit location',
    select_location: 'Select location',
    location_created_successfully: 'Location created successfully',
    location_updated_successfully: 'Location updated successfully',
    search_discounts_by_name: 'Search discounts by name or type',
    discount_amount: 'Discount amount',
    discount_type: 'Discount type',
    discount_given: 'Discount given',
    tax_exclusive: 'Tax exclusive',
    tax_inclusive: 'Tax inclusive ',
    type_percentage: 'Percentage (%)',
    type_amount: 'Amount (Rs.)',
    discount_credit_cards_entire_orders: 'Discounts for credit cards & entire orders',
    example_hbl_discount_student_discount: '(e.g. HBL discount, 20% student discount)',
    pos_discounts: 'POS discounts',
    pos_discount: 'POS discount',
    new_order: 'New order (Shift + N)',
    for_receipt: 'For receipt : enter phone',
    send: 'Send',
    search_sales_tax_by_name: 'Search sales tax by name',
    create_new_sales_tax: 'Create new sales tax',
    create_sales_tax: 'Create sales tax',
    sales_tax_details: 'Sales tax details',
    tax_name: 'Tax name',
    tax_rate: 'Tax rate %',
    tax_application: 'Tax application',
    tax_calculation: 'Tax Calculation',
    applied_on: 'Applied on # of items',
    enter_sales_tax_name: 'Enter sales tax name',
    enter_tax_rate: 'Enter tax rate in %',
    select_tax_calculation: 'Select tax calculation',
    select_tax_application: 'Select tax application',
    calculate_tax_on_pre_discount: 'Calculate tax based on the pre-discount price',
    calculate_tax_on_post_discount: 'Calculate tax based on the post-discount price',
    on_all_items: 'On all items',
    select_items: 'Select items',
    order_level_discount: 'Order level discount',
    done: 'Done',
    select_order_level_discount: 'Select order level discount type',
    rs: 'Rs.',
    percent: '%',
    final_bill_amount: 'Final bill amount',
    cash_amount: 'Cash amount',
    email: 'Email',
    date_of_birth: 'Date of birth',
    sales_tax_created_in_oscar_dashboard: 'Sales tax created in oscar dashboard will sync to all devices signed in to this account.',
    the_tax_amount_will_be_automatically: 'The tax amount will be automatically calculated at purchase.',
    create_purchase_tax: 'Create purchase tax',
    purchase_tax_details: 'Purchase tax details',
    all_taxes: 'All taxes',
    sales_tax: 'Sales tax',
    purchase_tax: 'Purchase tax',
    create_tax: 'Create tax',
    selected_items: 'Selected items',
    multiple_items: 'Multiple items',
    create_category: 'Create category',
    category_details: 'Category details',
    category_name: 'Category name',
    enter_category_name: 'Enter category name',
    total_inclusive_tax: 'Total inclusive tax',
    total_exclusive_tax: 'Total exclusive tax',
    search_order_by_id: 'Search order by ID',
    all_orders: 'All orders',
    confirmed_orders: 'Confirmed orders',
    cancel_orders: 'Cancel orders',
    details: 'Details',
    delete_order: 'Delete order',
    order_number: 'Order no',
    cashier: 'Cashier',
    customer: 'Customer',
    payment_status: 'Payment status',
    total: 'Total',
    order_history: 'Order history',
    you_havent_placed_any_order: "you haven't placed any order yet, Start selling",
    payment_method: 'Payment method',
    visits_lower: 'visits',
    visit: 'visit',
    held_orders: 'Held orders',
    search_order_by_name_number: 'Search order by name or number',
    filter: 'Filter',
    all: 'All',
    most_recent: 'Most recent',
    tax_type: 'Tax type',
    hold_order: 'Hold order',
    are_you_sure_you_want_to_hold_order: 'Are you sure you want to hold this order',
    import_purchaseorder: 'Import purchase order',
    export_purchaseorder: 'Export purchase order',
    auto: 'Auto',
    draft: 'Draft',
    partially_received: 'Partially received',
    pending: 'Pending',
    received: 'Received',
    create_po: 'Create PO',
    import_po: 'Import PO',
    sales: 'Sales',
    purchase: 'Purchase',
    search_vendor_order_no: 'Search Vendor or Order #',
    edit_customer: 'Edit customer',
    rate: 'Rate',
    type: 'Type',
    given: 'Given',
    edit_sales_tax: 'Edit sales tax',
    edit_purchase_tax: 'Edit purchase tax',
    search_tax_by_name: 'Search tax by name',
    edit_product: 'Edit product',
    discount_rate: 'Discount rate',
    enter_discount_rate: 'Enter discount rate',
    edit_discount: 'Edit discount',
    print: 'Print',
    loyalty_point_available: 'Loyalty point available',
    payment_received_by_cash_of: 'Payment received by cash of',
    payment_received_by_card_of: 'Payment received by card of',
    refund: 'Refund',
    add_a_note_to_the_order: 'Add a note to the order',
    history: 'History',
    refund_updated_send_sms_to_customer: 'Refund updated, send SMS to customer',
    resend_sms: 'Resend SMS',
    archived: 'Archived',
    archive: 'Archive',
    unarchive: 'Unarchive',
    are_you_sure_you_want_to_delete_order: 'Are you sure you want to delete this order',
    archive_product: 'Archive product',
    are_you_sure_you_want_to_archive: 'Are you sure you want to archive ',
    are_you_sure_you_want_to_unarchive: 'Are you sure you want to unarchive ',
    archive_discount: 'Archive discount',
    all_discounts: 'All discounts',
    archive_tax: 'Archive tax',
    set_your_closing_balance: 'Set your closing balance',
    closing_balance: 'Closing balance',
    are_you_sure_you_want_to_logout: 'Are you sure you want to logout?',
    add_additional_option_set: 'Add additional option set',
    archive_customer: 'Archive customer',
    create_purchase_order: 'Create purchase order',
    lets_create_your_account_po: 'Let’s create your account PO',
    enter_order_information: 'Enter order information',
    save_draft: 'Save draft',
    denerate_purcahse_order: 'Genterate purchase order.',
    team_member_permission: 'Team member permission',
    team_member_name: 'Team member name',
    cnic: 'CNIC no',
    upload_cnic_front: 'Upload front',
    upload_cnic_back: 'Upload back',
    cnic_details: 'CNIC details',
    phone: 'Phone',
    password: 'Password',
    required_password: 'Password is required',
    pos_pin: 'POS pin',
    point_of_Sale: 'Point of Sale',
    allow_team_member_to_control_general_POS_functions: 'Allow team member to control general POS functions.',
    head_cashier: 'Head Cashier',
    manager: 'Manager',
    waiter: 'Waiters',
    create_waiter: 'Create waiter',
    edit_waiter: 'Edit waiter',
    waiter_details: 'Waiter details',
    create_and_manage_your_team: 'Create and manage your team',
    allow_team_member_to_control_inventory_management: 'Allow team member to control inventory management.',
    purchasing: 'Purchasing',
    allow_team_member_to_control_purchasing_system: 'Allow team member to control purchasing system.',
    sales_invoicing: 'Sales Invoicing',
    allow_team_member_to_manage_sales_invoicing: 'Allow team member to manage sales invoicing.',
    products: 'Products',
    allow_team_member_to_create_and_manage_products: 'Allow team member to create and manage products.',
    manufacturing: 'Manufacturing',
    allow_team_member_to_manufacture_products: 'Allow team member to manufacture products.',
    requester: 'Requester',
    approver: 'Approver',
    role: 'Role',
    select_role: 'Select role',
    role_details: 'Role Details',
    create_role: 'Create Role',
    all_role: 'All role',
    role_name: 'Role Name',
    search_team_member_by_phone: 'Search team member by phone',
    transfer_receiver: 'Transfer receiver',
    po_Receiver_Distribution_operator: 'PO / Receiver / Distribution operator',
    auditor: 'Auditor',
    user: 'User',
    products_manager: 'Products manager',
    chef: 'Chef',
    allowed_pos: 'Allowed POS',
    bag_a_Bagel_POS_Admin: 'Bag a Bagel - POS (Admin)',
    generate_purcahse_order: 'Generate purchase order.',
    company: 'Company',
    ship_to: 'Ship to',
    purchase_order_date: 'Purchase order date',
    expected_on: 'Expected on',
    notes: 'Notes',
    reference: 'Reference',
    shipping_method: 'Shipping method',
    add_item_information: 'Add item information',
    type_item_name_SKU_or_Barcode: 'Type item name, SKU or Barcode',
    notes_Memo: 'Notes / Memo',
    qty: 'Qty',
    unit_cost: 'Unit cost',
    subtotal: 'Subtotal',
    add_global_discount_amount: 'Add global discount amount',
    global_discount_added: 'Global discount added',
    total_amount: 'Total amount',
    cash_on_delivery: 'Cash on delivery',
    via_Bank_on_delivery: 'Via Bank on delivery',
    create_company: 'Create company',
    company_details: 'Company details',
    enter_company_name: 'Enter company name',
    email_address: 'Email address',
    country_region: 'Country/Region',
    state_province: 'State/Province',
    zip_postal: 'Zip/Postal code',
    enter_zip_postal: 'Enter Zip/Postal code',
    send_purchase_order: 'Send purchase order',
    would_you_like_to_email_or_Save_a_copy_of_your_order: 'Would you like to email or Save a copy of your order?',
    to: 'To',
    pdf: 'PDF',
    csv: 'CSV',
    save_your_purchase_order_as: 'Save your purchase order as',
    send_me_a_copy: 'Send me a copy',
    create_supplier: 'Create supplier',
    import_suppliers: 'Import suppliers',
    cnic_front_uploaded: 'CNIC front uploaded',
    cnic_back_uploaded: 'CNIC back uploaded',
    unarchived: ' Unarchived',
    are_you_sure_you_want_to_archive_the_selected_customer: 'Are you sure you want to archive the selected customer?',
    are_you_sure_you_want_to_unarchive_the_selected_customer: 'Are you sure you want to unarchive the selected customer?',
    unarchive_customer: 'Unarchive customer',
    are_you_sure_you_want_to_archive_the_taxes_Customer: 'Are you sure you want to archive the selected taxes?',
    are_you_sure_you_want_to_unarchive_the_taxes_Customer: 'Are you sure you want to unarchive the selected taxes?',
    unarchive_tax: 'Unarchive tax',
    are_you_sure_you_want_to_archive_the_selected_discount: 'Are you sure you want to archive the selected discount?',
    are_you_sure_you_want_to_unarchive_the_selected_discount: 'Are you sure you want to unarchive the selected discount?',
    unarchive_discount: 'Unarchive discount',
    are_you_sure_you_want_to_archive_the_selected_product: 'Are you sure you want to archive the selected product?',
    are_you_sure_you_want_to_unarchive_the_selected_product: 'Are you sure you want to unarchive the selected product?',
    unarchive_product: 'Do you want to unarchive selected product?',
    loyalty_points_earned: 'Loyalty points earned',
    loyalty: 'loyalty',
    buyer_Summary: 'Buyer Summary',
    first_visit: 'First visit',
    last_visit: 'Last visit',
    frequency: 'Frequency',
    order_details: 'Order details',
    upload_and_create_your_inventory: 'Upload and create your inventory',
    upload_CSV_file: 'Upload CSV file',
    select_a_file_or_drag_and_drop_here: 'Select a file or drag and drop here',
    or_select_files_from_device: 'or select files from device',
    max_50MB: 'Max. 50MB',
    select_file: 'Select file',
    download_CSV_template: 'Download CSV template',
    po_item_error: 'Quantity, unit and cost are required fields',
    po_num: 'PO #',
    status: 'Status',
    purchase_date: 'Purchase date',
    vendor: 'Vendor',
    approve_po: 'Approve',
    reject_po: 'Reject',
    mark_as_delivered: 'Mark as delivered',
    mark_as_complete: 'Mark as complete',

    delivered: 'Delivered',
    rejected: 'Rejected',
    username: 'Username',
    amount_outstanding: 'Amount Outstanding',
    add_invoices: 'Add Invoices',
    add_report: 'Add report',
    create_invoices: 'Create Invoices',
    import_invoices: 'Import Invoices',
    create_report: 'Create report',
    import_report: 'Import report',
    add_supplier: 'Add Supplier',
    create_new_supplier_and_add_to_sale: 'Create new supplier and add to sale',
    before_you_open_your_store_first_you_need_some_supplier: 'Before you open your store, first you need some supplier',
    create_new_invoice_and_add_to_sale: 'Create new invoice and add to sale',
    before_you_open_your_store_first_you_need_some_invoice: 'Before you open your store, first you need some invoice',
    create_new_purchase_order_and_add_to_item: 'Create new purchase order and add to item',
    before_you_open_your_store_first_you_need_some_purchase_order: 'Before you open your store, first you need some purchase order',
    create_report_and_add_to_sale: 'Create new report and add to sale',
    before_you_open_your_store_first_you_need_some_report: 'Before you open your store, first you need some report',
    please_adjust_the_settings_according_to_your_preferences: 'Please adjust the settings according to your preferences',
    before_opening_your_store_you_need_to_adjust_the_settings_first: 'Before opening your store, you need to adjust the settings first',
    new_order2: 'New order',
    check_out: 'Checkout',
    add_customer_to_sale: 'Add customer to sale',
    create_new_customer: 'Create new customer',
    search_customer_name_phone_or_email: 'Search customer name, phone or email',
    select_payment_method: 'Select payment method',
    udhaar: 'udhaar',
    enter_amount: 'Enter amount',
    item_level_discount: 'Item level discount',
    rupees: 'Rupees',
    percentage: 'Percentage',
    item_level_discount_added: 'Item level discount added',
    update_Customer: 'Update Customer',
    new_order_toolpit_desc: 'Before starting new order do you want to save this order to saved orders list?',
    order_saved: 'Order saved',
    order_saved_successfully: 'Order saved successfully',
    order_cleared: 'Order clear successfully',
    saved_order: 'Saved orders',
    resume_order: 'Resume order',
    clear_all_item: 'Clear all items?',
    do_you_want_to_clear_all_the_items_in_the_list: 'Do you want to clear all the items in the list?',
    order_resumed_successfully: 'Order resumed successfully',
    remove_Customer: 'Customer removed',
    search_discounts_by_name: 'Search discounts by name...',
    new_to_oscar: 'New to Oscar?',
    get_started: 'Get started',
    already_have_an_account: 'Already have an account?',
    pos_cockpit: 'POS Cockpit',
    dine_in: 'Dine In',
    take_away: 'Take away',
    delivery: 'Delivery',
    order_time: 'Order time',
    order_typee: 'Order type',
    time: 'Time',
    table: 'Table',
    rider: 'Riders',
    nothing_found_create_order: 'Nothing found, please create order',
    table_details: 'Table Details',
    appearance: 'Appearance',
    table_name: 'Table name',
    no_of_seats: 'No of seats',
    table_shape: 'Table shape',
    table_width: 'Table width (px)',
    table_height: 'Table height (px)',
    dashboard: 'Dashboard',
    orders_overview: 'Orders overview',
    total_sales: 'Total sales',
    total_orders: 'Total orders',
    hold_orders: 'Hold orders',
    cancelled_orders: 'Cancelled orders',
    payment_types: 'Payment types ',
    gross_sale: 'Gross Sales',
    transactions: 'Transactions',
    avg_net_sale: 'Average net sale',
    returns: 'Returns',
    net_sales: 'Net sales',
    total_customers: 'Total customers',
    new_customers: 'New customers',
    returning_customers: 'Returning customers',
    avg_spent_per_customer: 'Avg. spent per customer',
    conversion_rate: 'Conversion rate',
    top_10_items: 'Top 10 items',
    top_10_categories: 'Top 10 categories',
    devices: 'Devices',
    search_kds_by_name: 'Search KDS by name',
    device_name: 'Device Name',
    new_kitchen_display: 'New Kitchen Display',
    kitchen_display: 'Kitchen Display',
    sign_in_with_device_code: 'Sign in with device code',
    add_kds_device: 'Add a KDS device',
    generating_device_code: 'Generating device code',
    stay_with_us: 'Stay with us for few seconds',
    kitchen_display_devices: 'Kitchen Display Devices',
    send_device_code: 'Send Device Code',
    send_code_to_email: 'Send this device code to an email to sign in Oscar kitchen display',
    create_four_digit_pin: 'Create a 4 digit pin code',
    confirm_pin_code: 'Confirm pin code',
    third_party_integration: 'Third party integration',
    integrate_kds_with_odoo: 'Integrate you KDS with Odoo Oscar POS',
    connect_odoo: 'Connect Odoo',
    connected: 'Connected',
    paired: 'Paired',
    unpaired: 'Unpaired',
    copy: 'Copy',
    device_code_are_created_for_each_device: 'Device codes are created in dashboard for each new device, allowing you to sign in without ever sharing your email and password.',
    hold_tight: 'Hold tight',
    all_incoming_orders_will_display_here: 'All incoming orders will display here',
    mark_all_as_ready: 'Mark all as ready',
    mark_as_complete: 'Mark as complete',
    completed_orders_will_show_up_here: 'The completed orders will show up here',
    order_completed: 'Order Completed',
    order_status: 'Order Status',
    order_type: 'Order Type',
    time_taken: 'Time Taken',
    recall_order: 'Recall order',
    notifications: 'Notifications',
    new_notifications_will_show_up_here: 'New notifications will show up here',
    order_items: 'Order items',
    device_created: 'Device created',
    connect_your_kds_device_with_code: 'Connect your KDS device with a code.',
    url: 'Url',
    dine_in: 'Dine In',
    delivery: 'Delivery',
    take_away: 'Takeaway',
    green_timer: 'Green timer',
    yellow_timer: 'Yellow timer',
    red_timer: 'Red timer',
    play_sound_when_new_order_arrives: 'Play sound when new order arrives',
    play_sound_when_item_or_order_cancelled: 'Play sound when item or order cancelled',
    play_sound: 'Play sound',
    activate_ticket_timer_after: 'Activate ticket timer after',
    configuration: 'Configuration',
    display_information: 'Display information',
    display_name: 'Display name',
    sign_in_date: 'Sign in date',
    point_of_sale_system: 'Point of Sale System',
    confirm_pin: 'Confirm PIN',
    confirm: 'Confirm',
    karachi: 'karachi',
    lahore: 'Lahore',
    hyderabad: 'Hyderabad',
    search_by_name_or_order_number: 'Search by name or order number',
    paid: 'Paid',
    create_floor_plan: 'Create floor plan',
    edit_floor_plan: 'Edit floor plan',
    floor_plan_created_successfully: 'Floor Plan created successfully',
    floor_plan_updated_successfully: 'Floor Plan updated successfully',
    floor_plan_archived_successfully: 'Floor Plan archived successfully',
    floor_plan_unarchived_successfully: 'Floor Plan unarchived successfully',
    floor_plan_details: 'Floor plan details',
    floor_plan_name: 'Floor plan name',
    is_it_dinein_floor_or_delivery_floor: 'Is it dine in floor or delivery floor?',
    add_tables: 'Add tables',
    all_modifiers: 'All modifiers',
    create_modifier: 'Create modifier',
    edit_modifier: 'Edit modifier',
    create_modifiers: 'Create modifiers',
    setup_modifiers: 'Setup modifiers',
    setup_modifiers_and_customize: 'Setup modifiers and customize your items',
    archive_modifier: 'Archive modifier',
    unarchive_modifier: 'Unarchive modifier',
    no_archive_modifier_found: 'No archive modifiers found',
    skip_this_step: 'Skip this step',
    modifier_name: 'Modifier name',
    modifier_created_successfully: 'Modifier created successfully',
    modifier_updated_successfully: 'Modifier updated successfully',
    modifier_archived_successfully: 'Modifier archived successfully',
    modifier_unarchived_successfully: 'Modifier unarchived successfully',
    are_you_sure_you_want_to_archive_the_selected_modifier: 'Are you sure you want to archive the selected modifier?',
    are_you_sure_you_want_to_unarchive_the_selected_modifier: 'Are you sure you want to unarchive the selected modifier?',
    print_in_receipt: 'Print in receipt',
    apply_modifier_on_these_items: 'Apply modifier on these items',
    add_items_to_modifier: 'Add items to modifier',
    add_items: 'Add items',
    modifier: 'Modifier',
    modifiers: 'Modifiers',
    price: 'Price',
    stock_status: 'Stock status',
    rs_zero: 'Rs. 0',
    saved_orders: 'Saved orders',
    when_a_new_order_is_created_the_ongoing_order_will_be_saved_here: 'When a new order is created, the ongoing order will be saved here.',
    easypaisa: 'Easypaisa',
    jazzcash: 'Jazzcash',
    enter_Jazzcash_account_number: 'Enter Jazzcash account number',
    enter_easypaisa_account_number: 'Enter Easypaisa account number',
    enter_number: 'Enter number',
    verifying_account: 'Verifying account',
    account_fetched_successfully: 'Account fetched successfully',
    account_title: 'Account title',
    account_number: 'Account number',
    sorry_account_not_found_Try_again: 'Sorry account not found. Try again',
    bank: 'Bank',
    verified_account_by: 'Verified account by',
    oTP_code_has_been_sent_to: 'OTP code has been sent to',
    sms_code_ka_wait_time: 'SMS code ka wait time',
    code_nahi_mila: 'Code nahi mila? ',
    sms: 'SMS',
    call: 'Call',
    customer_se_kahein_woh_apni_Jazzcash_app_se_MPIN_verify_kare: 'Customer se kahein woh apni Jazzcash app se MPIN verify kare',
    waiting_for_MPIN_confirmation: 'Waiting for MPIN confirmation',
    request_MPIN: 'Request MPIN',
    customer_added: 'Customer added',
    create_customer_and_add_them_into_sales: 'Create customer and add them into sales',
    full_name: 'Full name',
    phone: 'Phone',
    country: 'Country',
    select_country: 'Select Country',
    drafts_saved: 'Drafts saved',
    customer_created_successfully: 'Customer created successfully',
    search_by_name: 'Search by name',
    order_no_without_dot: 'Order no',
    points_earned: 'Points earned',
    payment: 'Payment',
    summary_and_details: 'Summary & details',
    loyalty_point_available: 'Loyalty point available',
    payment_received_by_cash_of: 'Payment received by cash of',
    paid_by_customer: 'Paid by customer',
    order_history_not_found_start_selling: 'order history not found, start selling',
    start_selling: 'Start selling',
    never: 'Never',
    do_you_really_want_to_archive_this_customer: 'Do you really want to archive this customer?',
    do_you_really_want_to_unarchive_this_customer: 'Do you really want to unarchive this customer?',
    customer_archive_successfully: 'Customer archive successfully',
    customer_unarchive_successfully: 'Customer unarchive successfully',
    no_archive_customers_found: 'No archive customers found',
    no_draft_customers_found: 'No customer saved into draft',
    archive_customers: 'Archive customers',
    no_customers_found: 'No customers found',
    change_file: 'Change file',
    library_imported_successfully: 'Library imported successfully',
    place_order: 'Place order',
    update_order: 'Update order',
    item: 'Item',
    draft_customer: 'Draft Customer',
    total_visits: 'Total visits',
    pay_credits: 'Pay credits',
    credit_history: 'Credit history',
    search_by_amount: 'Search by amount',
    pay_date: 'Pay date',
    credit_history_not_found: 'Credit history not found',
    floor_plan: 'Floor plan',
    guests: 'Guests',
    dob: 'DOB',
    customer_updated_successfully: 'Customer updated successfully',
    csv_uploaded: 'CSV uploaded',
    action: 'Action',
    remove_waiter: 'Waiter removed successfully',
    add_waiter: 'Waiter added successfully',
    update_waiter: 'Waiter updated successfully',
    write_note: 'Write note...',
    note_placeholder: 'E.g Cut burger in half',
    clear_note: 'Clear note',
    add_note: 'Add note',
    void_item: 'Void item',
    add_void_reason: 'Add void reason',
    write_reason: 'Write reason',
    clear_reason: 'Clear reason',
    void_notes: 'Void notes',
    void_placeholder: 'E.g. Customer changed mind',
    how_many_loyalty_points_would_you_like_to_redeem: 'How many loyalty points would you like to redeem?',
    date_and_time: 'Date & time',
    customer_id: 'Customer ID',
    transaction_id: 'Transaction ID',
    succeeded: 'Succeeded',
    master: 'Master',
    visa: 'Visa',
    search_by_customer_name_phone_and_transactin_id: 'Customer name, phone, transaction ID',
    search_by_name_and_transactin_id: 'Search by name and transaction ID',
    export_pdf: 'Export PDF',
    item_library: 'Item library',
    money_in: 'Money in',
    money_out: 'Money out',
    method: 'Method',
    wallet_summary: 'Wallet summary',
    wallet_balance: 'Wallet balance',
    payout: 'Payout',
    refunded: 'Refunded',
    titles: 'Titles',
    split_bill: 'Split bill',
    choose_how_to_split_the_bill: 'Choose how to split the bill',
    all_discounts_and_customer_must_be_reapplied: 'All discounts and customer details must be reapplied after splitting the bill by dish.',
    split_bill_by_amount: 'Split bill by amount',
    total_bill_amount: 'Total Bill amount',
    dividing_the_cost: 'Dividing the total cost of a bill or expense among multiple people based on specific amounts each person owes.',
    customer_published_successfully: 'Customer published successfully',
    customer_saved_into_draft: 'Customer saved into draft',
    all_types: 'All types',
    void_orders: 'Void orders',
    paid_orders: 'Paid orders',
    cash_payment: 'Cash payment',
    card_payment: 'Card payment',
    wallet_payment: 'Wallet payment',
    credit_payment: 'Credit payment',
    complete_order: 'Complete order',
    order_no: 'Order no.',
    search_by_order_number: 'Search by order number',
    maximum_discount_cap: 'Maximum discount cap',
    discounts_name: 'Discount name',
    amount_type: 'Amount type',
    discount_created_successfully: 'Discount created successfully',
    discount_updated_successfully: 'Discount updated successfully',
    discount_published_successfully: 'Discount published successfully',
    discount_saved_into_draft: 'Discount saved into draft',
    please_select_discount_first: 'Please select discount first',
    draft_discount: 'Draft discount',
    no_archive_discounts_found: 'no archive discounts found',
    no_draft_discounts_found: 'no draft discounts found',
    tax_is_to_be_added_on_top_of_price: 'Tax is to be added on top of price',
    tax_is_already_included_in_Price: 'Tax is already included in Price',
    tax_behavior: 'Tax behavior',
    no_archive_tax_found: 'No archive tax found',
    no_purchase_found: 'No purchase tax found',
    no_sales_tax_found: 'No sales tax found',
    purchase_tax_created_successfully: 'Purchase tax created successfully',
    purchase_tax_updated_successfully: 'Purchase tax updated successfully',
    sale_tax_created_successfully: 'Sales tax created successfully',
    sale_tax_updated_successfully: 'Sales tax updated successfully',
    create_items_and_start_selling: 'Create items and start selling',
    edit_item: 'Edit item',
    item_created_successfully: 'Item created successfully',
    item_updated_successfully: 'Item updated successfully',
    member_credential_details: 'Member credential details',
    create_and_manage_your_team: 'Create and manage your team',
    team_member: 'Team member',
    create_purchase_order_and_manage_inventory: 'Create purchase order and manage inventory',
    purchase_order_details: 'Purchase order details',
    item_information: 'Item information',
    ingredient: 'Ingredient',
    u_cost: 'U. Cost (Rs)',
    amount_with_rs: 'Amount (Rs)',
    global_discount: 'Global discount',
    team_member_created_successfully: 'Team member created successfully',
    team_member_updated_successfully: 'Team member updated successfully',
    po_created_successfully: 'Po created successfully',
    po_saved_into_draft: 'Po saved into draft',
    po_rejected: 'Purchase order rejected',
    po_pending: 'Purchase order pending',
    po_approved: 'Purchase order approved',
    order_note: 'Order note',
    note_added: 'Note added',
    enabled: 'Enabled',
    disabled: 'Disabled',
    app_settings: 'App settings',
    import_discount: 'Import discount',
    set_up_items: 'Setup items',
    go_to_pos: 'Go to POS',
    before_you_open_your_business_first_you_need_some_items: 'Before you open your business, first you need some items.',
    create_categories_and_add_them_into_items: 'Create categories and add them into items',
    create_catergoreis_and_start_selling: 'Create catergoreis and start selling',
    create_deals_and_start_selling: 'Create deals and start selling',
    edit_tile_color: 'Edit tile color',
    variation_details: 'Variation details',
    variation_display_name: 'Variation display name',
    create_variation: 'Create variation',
    search_category_by_name: 'Search category by name...',
    create_categories: 'Create categories',
    item_name: 'Item name',
    selling_price: 'Selling price',
    add_quantity: 'Add quantity',
    primary_unit: 'Primary unit',
    add_variations: 'Add variations',
    drag_image: 'Drag profile image',
    upload_file_or_drag_here: 'Upload file or drag here',
    upload_only_jpeg_jpg_or_png: 'Upload only jpeg, jpg or png',
    profit_will_be_automatically_calculated_and_displayed_when_adding_selling_price_and_item_cost:
        'Profit will be automatically calculated and displayed when adding selling price and item cost.',
    margin_will_be_automatically_calculated_and_displayed_when_adding_selling_price_and_item_cost:
        'Margin will be automatically calculated and displayed when adding selling price and item cost.',
    item_published_successfully: 'Item published successfully',
    item_saved_into_draft: 'Item saved into draft',
    do_you_really_want_to_discard_this_item: 'Do you really want to discard this item?',
    this_action_is_irreversible_once_you_discard_it_its_gone: "This action is irreversible, once you discard your item, it's gone.",
    stock: 'Stock',
    loading: 'loading',
    max_5MB: 'Max: 5mb',
    no_archive_items_found: 'No archive items found',
    no_draft_items_found: 'No draft items found',
    no_low_stock_items_found: 'No low stock items found',
    no_sold_out_items_found: 'No sold out items found',
    all_items: 'All items',
    please_enter_all_required_fileds: 'Please enter all required fileds',
    item_is_on_low_stock_please_add_more_items: 'Item is on low stock, please add more items.',
    item_sold_out_please_add_more_items: 'Item sold out, please add more items.',
    we_couldnt_find_any_results_for_your_search: "We couldn't find any results for your search.",
    please_go_to_the_POS_and_add_items_to_the_list: 'Please go to the POS and add items to the list.',
    search: 'Search...',
    all_categories: 'All categories',
    category_created_successfully: 'Category created successfully',
    category_updated_successfully: 'Category updated successfully',
    edit_category: 'Edit category',
    create_category: 'Create category',
    do_you_really_want_to_discard: 'Do you really want to discard?',
    this_action_is_irreversible_once_you_discard_it_its_gone: "This action is irreversible, once you discard it, it's gone.",
    do_you_really_want_to_archive_this_category: 'Do you really want to archive this category?',
    do_you_really_want_to_unarchive_this_category: 'Do you really want to unarchive this category?',
    category_archive_successfully: 'Category archive successfully',
    category_unarchive_successfully: 'Category unarchive successfully',
    company_name: 'Company name',
    zip_code: 'Zip code',
    all_company: 'All Company',
    create_new_company_and_add_to_sale: 'Create new company and add to sale',
    no_draft_companies_found: 'No draft companies found',
    draft_company: 'Draft company',
    company_created_successfully: 'Company created successfully',
    company_updated_successfully: 'Company updated successfully',
    company_published_successfully: 'Company published successfully',
    company_saved_into_draft: 'Company saved into draft',
    loading_with_dot: 'loading...',
    location_name: 'Location name',
    location_details: 'Location details',
    business_address: 'Business address',
    location_type: 'Location type',
    salary_type: 'Salary type',
    salary_amount: 'Salary amount',
    do_you_want_to_proceed_this_order: 'Do you want to proceed refund for this order?',
    select_items_for_refund: 'Select items for refund',
    primary_and_Secondary_Units: 'Primary and secondary units',
    approved: 'approved',
    archive_po: 'archive po',
    draft_po: 'draft po',
    no_archive_po_found: 'no archive po found',
    no_draft_po_found: 'no draft po found',
    no_complete_order_found: 'no complete order found',
    no_saved_order_found: 'no saved order found',
    reset_your_password: 'Reset your password',
    enter_the_email_address_you_used_to_register_with: 'Enter the email address you used to register with.',
    verify_your_email: 'Verify your email',
    verification_code_has_been_sent_to: 'Verification code has been sent to',
    resend_code: 'Resend code',
    verify: 'Verify',
    create_new_password: 'Create_new_password',
    finish: 'Finish',
    custom_tax: 'Custom tax',
    Default: 'Default',
    Payment_method_tax: 'Payment method tax',
    apply_custom_tax: 'Apply custom tax',
    please_enter_a_valid_balance: 'Please enter a valid balance',
    there_are_no_items_in_the_list_to_be_cleared: 'There are no items in the list to be cleared.',
    no_items_in_the_list_to_clear: 'No items in the list to clear',
    payment_method_tax: 'Payment method tax',
    points_redeemed_successfully: 'Points redeemed successfully',
    more_filter_by_amount: 'More filters by amount',
    filter_by_status: 'Filter by status',
    filter_by_method: 'Filter by Method',
    create_item_new: 'Create item',
    please_select_variation: 'Please select variation',
    order_clear_successfully: 'Order clear successfully',
    search_tax_by_name: 'Search tax by name',
    you_are_already_logged_in: 'You are already logged in on another device',
    logging_into_this_device: 'Logging into this device will log you out of the other device.',
    login_this_device: 'Log in this device',
    you_have_been_logged_out: 'You have been logged out of this device.',
    effortless_payments_solutions: 'Effortless Payments Solutions',
    effortless_payments_descp:
        'Accept payments effortlessly with our low-rate solutions. Manage transactions seamlessly with automated reconciliation and a user-friendly dashboard.',
    accept_payments_via_card_machines: 'Accept payments via card machines, EasyPaisa, and JazzCash.',
    lowest_rates_in_pakistan: 'Lowest rates in Pakistan starting from 1%.',
    Automated_reconciliation: 'Automated reconciliation.',
    user_friendly_payments_dashboard: 'User-friendly payments dashboard.',
    explore_payment_plans: 'Explore payment plans',
    sales_by_payment_method: 'Sales by payment method',
    top_stores_by_sales: 'Top 10 stores by sales',
    sales_by_category: 'Sales by category',
    sales_by_day: 'Sales by day',
    sales_by_hour: 'Sales by hour',
    top_selling_items: 'Top selling items',
    low_selling_items: 'Low selling items',
    site_wise_sales: 'Site wise sales',
    location_wise_low_stock_items: 'Location wise low stock items',
    expand_your_online_presence_with_oscar: 'Expand Your Online Presence with Oscar',
    location_wise_out_of_stock_items: 'Location wise out of stock items',
    sale_price: 'Sale price',
    contribution: 'Contribution',
    low_stock_items: 'Low stock items',
    integrate_your_existing_online: 'Integrate Your Existing Online Store for Free',
    integrate_your_existing_online_descp:
        "Sell effortlessly online with Oscar! Whether you're starting fresh or have an existing online store, integrate seamlessly with Oscar's platform at no cost.",
    integrate_your_store: 'Integrate your store',
    out_of_stock_items: 'Out of stock items',
    key_metrics: 'Key metrics',
    avg_net_sale: 'Avg net sale',
    items_sold: 'Items sold',
    avg_basket_size: 'Avg basket size',
    tax_Collected: 'Tax Collected',
    upgrade_your_oscar_plan: 'Upgrade your Oscar plan',
    upgrade_your_oscar_heading: 'You are now on the Oscar for Retail standard plan',
    upgrade_your_oscar_descp: 'Continue using Oscar for Retail without interruption and upgrade at any time to',
    upgrade_your_oscar_desc: 'access advanced features.',
    everything_from_standard: 'Everything from standard',
    crm: 'CRM',
    loyalty_program: 'Loyalty program',
    customer_feedback: 'Customer feedback',
    sms_marketing: 'SMS marketing',
    multi_location: 'Multi location',
    franchise_management: 'Franchise management',
    warehouse_operations: 'Warehouse operations',
    automated_reporting: 'Automated reporting',
    unified_reporting_for_hq: 'Unified reporting for HQ',
    upgrade_to_enterprise: 'Upgrade to Enterprise',
    sales_by_channel: 'Sales by channel',
    achieve_unmatched_sales_growth_online: 'Achieve Unmatched Sales Growth Online',
    achieve_unmatched_sales_growth_descp:
        'Elevate your brand and drive sales with our cutting-edge online store solutions. Unlock your potential and achieve unprecedented growth today!',
    preview_site: 'Preview site',
    average_sale: 'Average Net Sales',
    average_order_item_size: 'Average Basket Size',
    gross_sales: 'Gross Sales',
    net_sale: 'Net Sales',
    return: 'Returns',
    tax_collected: 'Tax Collected',
    total_discount: 'Discounts',
    total_items_sold: 'Items sold',
    view: 'View',
    effortless_payments_solutions_para:
        'Accept payments effortlessly with our low-rate solutions. Manage transactions seamlessly with automated reconciliation and a user-friendly dashboard.',
    achieve_unmatched_para: 'Elevate your brand and drive sales with our cutting-edge online store solutions. Unlock your potential and achieve unprecedented growth today!',
    start_onboarding: 'Start onboarding',
    location: 'Location',
    payment_summary: 'Payment summary',
    transaction_status_breakdown: 'Transaction status breakdown',
    dispute_count: 'Dispute count',
    average_transaction_value: 'Average transaction value',
    successful_payments: 'Successful payments',
    failed_payments: 'Failed payments',
    payouts_over_time: 'Payouts over time',
    top_payment_methods: 'Top payment methods',
    export: 'Export',
    all: 'All',
    taxable_item_sales: 'Taxable item sales',
    taxable_sales: 'Taxable item sales',
    non_taxable_sales: 'Non-Taxable item sales',
    tax_rate_one: 'Tax rate',
    applied_on_of_items: 'Applied on # of items',
    no_activity_zero_transactions_recorded: 'No activity zero transactions recorded',
    average_net_sales: 'Average net sales',
    items_solds: 'Items solds',
    transections: 'Transections',
    average_bucket_size: 'Average bucket size',
    all_waiters: 'All waiters',
    archive_waiter: 'Archive waiter',
    draft_waiter: 'Draft waiter',
    waiter_created: 'Waiter created successfully',
    waiter_updated: 'Waiter updated successfully',
    waiter_published_successfully: 'Waiter published successfully',
    waiter_saved_into_draft: 'Waiter saved into draft',
    no_archive_waiters_found: 'No archive waiters found',
    no_draft_waiters_found: 'No draft waiters found',
    no_waiter_found: 'No waiter found',
    do_you_really_want_to_unarchive_this_waiter: 'Do you really want to unarchive this waiter',
    do_you_really_want_to_archive_this_waiter: 'Do you really want to archive this waiter',
    seats: 'Seats',
    shape: 'Shape',
    save_and_new: 'Save and new',
    save_and_close: 'Save and close',
    add_table: 'Add table',
    height: 'Height',
    width: 'Width',
    which_type_of_floor: 'Which type of floor plan do you want to create? Is it for dine in, take away, or delivery?',
    no_draft_tax_found: 'No draft tax found',
    draft_tax: 'Draft Tax',
    tax_published_successfully: 'Tax published successfully',
    tax_saved_into_draft: 'Tax saved into draft',
    floor_plans: 'Floor plans',
    all_floors: 'All floors',
    no_active_floor_plans: 'No active floor plans found, please create one',
    discount_schedule: 'Discount schedule',
    set_date_range: 'Set date range',
    set_the_dates: 'Set the dates this discount is available. (Example: Seasonal Sale)',
    start_date: 'Start date',
    end_date: 'End date',
    create_rider: 'Create rider',
    edit_rider: 'Edit rider',
    rider_details: 'Rider details',
    all_riders: 'All riders',
    archive_rider: 'Archive rider',
    no_archive_riders_found: 'No archive riders found',
    draft_rider: 'Draft rider',
    no_draft_riders_found: 'No draft riders found',
    no_rider_found: 'No rider found',
    rider_created: 'Rider created successfully',
    rider_updated: 'Rider updated successfully',
    rider_published_successfully: 'Rider published successfully',
    rider_saved_into_draft: 'Rider saved into draft',
    rider_archived_successfully: 'Rider archived successfully',
    rider_unarchived_successfully: 'Rider unarchived successfully',
    do_you_really_want_to_unarchive_this_rider: 'Do you really want to unarchive this rider',
    do_you_really_want_to_archive_this_rider: 'Do you really want to archive this rider',
    remove_rider: 'Rider removed successfully',
    add_rider: 'Rider added successfully',
    update_rider: 'Rider updated successfully',
    search_rider_by_name: 'Search rider by name',
    create_new_rider_and_add_to_sale: 'Create new rider and add to sale',
    split_into_equal_payments: 'Split into equal payments',
    dividing_the_cost_or_bill_evenly: 'Dividing a total cost or bill evenly among multiple individuals, ensuring each pays an equal share of the expense.',
    number_of_split: 'Number of splits',
    discount_rule: 'Discount rule',
    deals: 'Deals',
    deal_saved_into_draft: 'Deal saved into draft',
    create_deal: 'Create deal',
    edit_deal: 'Edit deal',
    create_deal_and_start_selling: 'Create deals and start selling',
    all_deals: 'All deals',
    deal_details: 'Deal details',
    deal_name: 'Deal name',
    amount_type: 'Amount type',
    deal_discount: 'Deal discounts',
    discount_rules: 'Discount rules',
    apply_this_discount_automatically: 'Apply this discount automatically to specific times and quantities.',
    apply_to_items: 'Apply to items',
    select_purchase_rule: 'Select purchase rule',
    apply_to_specific_items: 'Apply to specific items. (e.g Holiday items)',
    apply_when_a_number_of_items_are_purchased: 'Apply when a number of items are purchased. (e.g. Buy one, get one free)',
    do_you_really_want_to_unarchive_this_deal: 'Do you really want to unarchive this deal?',
    do_you_really_want_to_archive_this_deal: 'Do you really want to archive this deal?',
    are_you_sure_you_want_to_archive_the_selected_deal: 'Are you sure you want to archive the selected deal(s)?',
    are_you_sure_you_want_to_unarchive_the_selected_deal: 'Are you sure you want to unarchive the selected deal(s)',
    please_select_deal_first: 'Please select deal first',
    deal_created_successfully: 'Deal created successfully',
    deal_updated_successfully: 'Deal updated successfully',
    deal_archived_successfully: 'Deal archived successfully',
    deal_unarchived_successfully: 'Deal unarchived successfully',
    total_cost: 'Total cost',
    deal_amount_is_greater: 'Your deal amount is greater then the item total please update the deal amount or item quantity.',
    duplicating_item: 'Duplicating item',
    page_not_found: 'Page not found',
    this_page_doesnot_exist: "This page doesn't exist, please try different URL or go back to homepage",
    go_back_to_home_page: 'Go back to homepage',
    upload_margin_breakdown_file: 'Upload  margin breakdown file',
    you_can_share_only_one_file_every_hours_so_please_ensure_you_submit_the_correct_file:
        'You can share only one file every 24 hours, so please ensure you submit the correct file.',
    you_can_download_reports_based_on_the_last_file_shared: 'You can download reports based on the last file shared.',
    download_reports: 'Download reports',
    file_uploaded: 'File uploaded.',
    current_stock: 'Current Stock',
    low_stock_level: 'Low stock level',
    stock_needed: 'Stock Needed',
    location_wise_sales: 'Location wise sales',
    orders: 'Orders',
    unit_cost_percentage: 'Unit cost %',
    export_csv: 'Export CSV',
    sales_summary: 'Sales summary',
    gross_sales_summary: 'Gross Sales (Total before Deductions)',
    taxes_summary: 'Taxes (Sales Tax Applied)',
    discounts_summary: 'Discounts (Promotions)',
    net_sales_summary: 'Net Sales (After Deductions)',
    payment_methods: 'Payment methods',
    top_payment_methods_by_net_total: 'Top payment methods by net total',
    top_payment_methods_by_net_total: 'Top payment methods by net total',
    top_to_low: 'Top to low',
    low_to_top: 'Low to top',
    total_units: 'Total units',
    Units_sold: 'Units sold',
    category_sales: 'Category sales',
    discount_rs_percentage: 'Discount Rs./percentage',
    discount_applied: 'Discount applied',
    oscar_can_provide_insights_to_help_grow_their_business: 'Oscar can provide insights to help grow their business',
    oscar_nonideal_title: 'Oscar POS offers valuable insights that can help businesses grow by tracking sales trends, managing inventory, and understanding customer preferences.',
    youve_not_done_any_transactions_yet: 'You’ve not done any transactions yet',
    total_qty: 'Total Qty',
    item_sales: 'Item Sales',
    single_location: 'Single Location',
    multi_location: 'Multi Location',
    successful_payments: 'Successful payments',
    payouts: 'Payouts',
    pending_payments: 'Pending payments',
    no_discount_found: 'No discount found',
    discount_for_credit_cards_and_orders: 'Discount for credit cards & entire orders',
    create_role_title: 'Create role',
    search_customer_name_or_phone: 'Search customer name or phone',
    please_select_customer_first: 'Please select customer first',
    no_tax_found: 'No tax found',
    please_select_tax_first: 'Please select tax first',
    tax_archive_successfully: 'tax archive successfully',
    tax_unarchive_successfully: 'tax unarchive successfully',
    item_archive_successfully: 'item archive successfully',
    item_unarchive_successfully: 'item unarchive successfully',
    explore_payment_plans: 'Explore payment plans',
    gross_payment: 'Gross payment',
    available_balance: 'Available balance',
    failed_payment: 'Failed payments',
    net_payment: 'Net payment',
    total_payouts: 'Total payouts',
    no_of_transaction: 'No. of transactions',
    search_by_transaction_id: 'Search by transaction ID',
    wallet_balance: 'Wallet balance',
    deposits: 'Deposits',
    payouts: 'Payouts',
    funds_available: 'Funds available',
    funds_on_the_way: 'Funds on the way',
    total_funds: 'Total funds',
    recent_deposits: 'Recent Deposits',
    your_available_balance_in_the_wallet: 'Your available balance in the wallet, ready for transactions or transfers.',
    total_amount_deposited_into_your_account: 'Total amount deposited into your account.',
    total_amount_paid_out_from_account: 'Total amount paid out from your account.',
    payment_type: 'Payment type',
    payment_details: 'Payment details',
    gross_amount: ' Gross amount',
    net_amount: 'Net amount',
    card_holder_name: 'Card holder name',
    bank_name: 'Bank name',
    card_number: 'Card number',
    expires: 'Expires',
    payment_deposits: 'Payment deposits',
    payment_on_the_way: 'Payment on the way',
    payment_started: 'Payment started',
    timeline: 'Timeline',
    title: 'Title',
    payment_breakdown: 'Payment breakdown',
    total_transactions: 'Total transactions',
    fees: 'Fees',
    view_transactions_breakdown_detailed_report: 'View transactions breakdown detailed report',
    payment_succeeded: 'Payment succeeded',
    payment_processing: 'Payment processing',
    payment_created: 'Payment created',
    payment_sent_to: 'Payment sent to',
    payment_sent_from: 'Payment sent from',
    bank_details: 'Bank details',
    account_hash: 'Account #',
    payment_refunded: 'Payment refunded',
    beneficiaries_details: 'Beneficiaries details',
    total_of_net_amount: 'Total of net amount',
    you_dont_have_any_activities_to_show_yet_please_make_some_transactions: 'You don’t have any activities to show yet. Please make some transactions.',
    activity: 'Activity',
    no_Payments_found: 'No Payments found',
    there_arent_any_results_for_that_query_Try_using_different_filters: "There aren't any results for that query. Try using different filters.",
    payment_settled: 'Payment settled',
    payout_succeeded: 'Payout succeeded',
    payout_in_processing: 'Payout in processing',
    see_more: 'See more',
    beneficiaries: 'Beneficiaries',
    create_beneficiary: 'Create beneficiary',
    add_beneficiary_details: 'Add beneficiary details',
    select_bank: 'Select bank',
    enter_account_number: 'Enter account number',
    verify_otp: 'Verify OTP',
    nothing_found_create_new_beneficiary: 'Nothing found, create new beneficiary',
    create_new_beneficiary_and_make_payouts: 'Create new beneficiary and make payouts',
    nick_name: 'Nick name',
    do_you_really_want_to_delete_this_beneficiary: 'Do you really want to delete this beneficiary?',
    search_beneficiary: 'Search beneficiaries...',
    confirm_beneficiary_details: 'Confirm beneficiary details',
    beneficiary_account_details: 'Beneficiary account details',
    account_title: 'Account title',
    account_number: 'Account number',
    balance_summary: 'Balance summary',
    transfer_money: 'Transfer money',
    add_more_beneficiaries: 'Add more beneficiaries',
    beneficiary_created_successfully: 'Beneficiary created successfully',
    beneficiary_deleted_successfully: 'Beneficiary deleted successfully',
    transfer: 'Transfer',
    review_payments: 'Review payments',
    confirm_payment_details: 'Confirm payment details',
    processing_payments: 'Processing payments',
    transferred_amount: 'Transferred Amount',
    go_to_activity: 'Go to activity',
    transfer_amount: 'Transfer amount',
    select_beneficiaries_for_transfer: 'Select beneficiaries for transfer',
    business_name: 'Business name',
    business_type: 'Business type',
    oscar_wallet: 'Oscar Wallet',
    experience_seamless_money_transfer: 'Experience seamless money transfers and balance tracking with a secure, user-friendly interface designed for your needs',
    ready_to_activate_your_business_wallet: 'Ready to Activate Your Business Wallet?',
    start_your_journey_to_fast_secure_transactions: 'Start your journey to fast, secure transactions by connecting with us today!',
    request_a_call_back: 'Request a Call Back',
    refund_in_processing: 'Refund in processing',
    refund_succeeded: 'Refund succeeded',
    initial_failed_transaction: 'Initial failed transaction',
    payout_failed: 'Payout failed',
    refund_transaction: 'Refund transaction',
    search_waiter_by_name: 'Search waiter by name',
    order_discounts: 'Order discounts',
    add_order_notes: 'Add order notes',
    archive_deal: 'Archive deal',
    no_archive_deal_found: 'No archive deal found',
    draft_deal: 'Draft deal',
    no_draft_deal_found: 'No Draft deal found',
    no_deal_found: 'No deal found',
    create_categories: 'Create categories',
    create_items: 'Create items',
    create_deals: 'Create deals',
    create_items_and_add_to_modifiers: 'Create items and add to modifiers',
    split_bill_by_dish: 'Split bill by dish',
    counter: 'Counter',
    add_a_counter: 'Add a counter',
    add_multiple_counters_and_link_your_payment_devices: 'Add multiple counters and link your payment devices',
    counter_details: 'Counter details',
    counter_name: 'Counter name',
    new_counter_details: 'New counter details',
    counter_created: 'Counter created',
    connect_your_payment_device_with_a_code: 'Connect your payment device with a code.',
    counter_code: 'Counter code',
    use_this_code_to_connect_your_payment_device_to_a_counter: 'Use this code to connect your payment device to a counter.',
    point_of_sale_POS: 'Point of sale (POS)',
    edit_counter: 'Edit Counter',
    counter_created_successfully: 'Counter created successfully',
    counter_updated_successfully: 'Counter updated successfully',
    counter_management: 'Counter management',
    create_new_counter: 'Create new counter',
    assign_a_unique_PIN_to_each_counter_to_link_your_payment_devices_decs:
        'Assign a unique PIN to each counter to link your payment devices for accurate transaction tracking and reporting.',
    create_a_counter: 'Create a counter',
    counter_code: 'Counter code',
    search_counter_by_name: 'Search counter by name',
    do_you_really_want_to_archive_this_counter: 'Do you really want to archive this counter?',
    you_can_unarchive_this_later: 'You can unarchive this later',
    counter_archived_successfully: 'Counter archived successfully',
    all_counters: 'All counters',
    do_you_really_want_to_unarchive_this_counter: 'Do you really want to unarchive this counter?',
    counter_unarchived_successfully: 'Counter unarchived successfully',
    you_can_archive_this_later: 'You can archive this later',
    archive_counter: 'Archive counter',
    no_archive_counter_found: 'No archive counter found',
    no_counter_found: 'No counter found',
    please_enter_counter_name: 'Please enter counter name',
    continue_to_payment: 'Continue to payment',
    continue_to_next_bill: 'Continue to next bill',
    order_hash: 'Order #',
    child_order_id: 'Child Order ID',
    parent_order_id: 'Parent Order ID',
    low_stock_thresholds_are_not_set_for_your_items_yet: 'Low stock thresholds are not set for your items yet.',
    no_items_are_currently_out_of_stock: 'No items are currently out of stock.',
    password_created_successfully: 'Password created successfully',
    do_you_really_want_to_logout: 'Do you really want to log out?',
    your_session_is_active: 'Your session is active. If you log out, it will be paused and you can resume it anytime.',
    are_you_sure_you_want_to_archive_the_selected_modifier: 'Are you sure you want to archive the selected modifier?',
    are_you_sure_you_want_to_unarchive_the_selected_modifier: 'Are you sure you want to unarchive the selected modifier?',
    counters: 'counters',
    this_action_is_irreversible_once_you_discard_your_counter_it_its_gone: "This action is irreversible, once you discard your counter, it's gone.",
};
