import React, {useEffect, useState} from 'react';
import {useAuthContext} from '../../context';

const usePermission = module => {
    const [userPermission, setUserPermission] = useState(null);
    const {userRole} = useAuthContext();

    const fetchPermission = async moduleName => {
        const permission = userRole?.find(mod => mod.name === (moduleName || module));
        if (permission?.read) {
            setUserPermission({canView: permission.read, canCrud: permission.create});
        } else {
            setUserPermission(null);
        }
    };

    useEffect(() => {
        fetchPermission();
    }, [module]);

    return userPermission;
};

export default usePermission;
