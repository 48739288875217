import moment from 'moment';
import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';

export const days = ['SUN', 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT'];
export const VOID = 'void';
export const BASE_64 = 'y/SFcWCzS5EZPcuMMs9vN4a8vVPCZ5i4b2paudQabI3UKkO5GUuEqvDjNJp3GhNry9eHPItm+GHyep7Cgd1v0A==';

export const REST_BASE_URL = 'http://192.168.10.40:8000';

export const PLATFORM = 'web';

export const patt = /03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/; // VALIDATION FOR PAKISTANI MOBILE NUMBER
export const dates = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days'];
export const escapeRegExp = text => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
export const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const getRoleModules = roleName => {
    switch (roleName) {
        case 'owner':
            return [
                {name: 'pos', create: true, read: true, update: true, delete: true},
                {name: 'dashboard', create: true, read: true, update: true, delete: true},
                {name: 'purchaseorder', create: true, read: true, update: true, delete: true},
                {name: 'sales-overview', create: true, read: true, update: true, delete: true},
                {name: 'wallet-overview', create: true, read: true, update: true, delete: true},
                {name: 'items', create: true, read: true, update: true, delete: true},
                {name: 'orders', create: true, read: true, update: true, delete: true},
                {name: 'customers', create: true, read: true, update: true, delete: true},
                {name: 'discounts', create: true, read: true, update: true, delete: true},
                {name: 'suppliers', create: true, read: true, update: true, delete: true},
                {name: 'tax', create: true, read: true, update: true, delete: true},
                {name: 'payment-method-tax', create: true, read: true, update: true, delete: true},
                {name: 'sales-tax', create: true, read: true, update: true, delete: true},
                {name: 'purchase-tax', create: true, read: true, update: true, delete: true},
                {name: 'team', create: true, read: true, update: true, delete: true},
                {name: 'setting', create: true, read: true, update: true, delete: true},
                {name: 'location', create: true, read: true, update: true, delete: true},
                {name: 'category', create: true, read: true, update: true, delete: true},
                {name: 'company', create: true, read: true, update: true, delete: true},
                {name: 'vendor', create: true, read: true, update: true, delete: true},
                {name: 'restaurant-pos', create: true, read: true, update: true, delete: true},
                {name: 'pos-cockpit', create: true, read: true, update: true, delete: true},
                {name: 'riders', create: true, read: true, update: true, delete: true},
                {name: 'waiters', create: true, read: true, update: true, delete: true},
                {name: 'floor-plans', create: true, read: true, update: true, delete: true},
                {name: 'modifiers', create: true, read: true, update: true, delete: true},
                {name: 'deals', create: true, read: true, update: true, delete: true},
                {name: 'device', create: true, read: true, update: true, delete: true},
                {name: 'beneficiary', create: true, read: true, update: true, delete: true},
                {name: 'counter', create: false, read: false, update: false, delete: false},
            ];

        case 'admin':
            return [
                {name: 'pos', create: true, read: true, update: true, delete: true},
                {name: 'dashboard', create: true, read: true, update: true, delete: true},
                {name: 'purchaseorder', create: true, read: true, update: true, delete: true},
                {name: 'sales-overview', create: true, read: true, update: true, delete: true},
                {name: 'items', create: true, read: true, update: true, delete: true},
                {name: 'orders', create: false, read: true, update: false, delete: false},
                {name: 'customers', create: true, read: true, update: true, delete: true},
                {name: 'discounts', create: false, read: true, update: false, delete: false},
                {name: 'suppliers', create: true, read: true, update: true, delete: true},
                {name: 'tax', create: false, read: true, update: false, delete: false},
                {name: 'payment-method-tax', create: true, read: true, update: true, delete: true},
                {name: 'sales-tax', create: false, read: true, update: false, delete: false},
                {name: 'purchase-tax', create: false, read: true, update: false, delete: false},
                {name: 'team', create: true, read: true, update: true, delete: true},
                {name: 'setting', create: false, read: true, update: true, delete: false},
                {name: 'location', create: true, read: true, update: true, delete: true},
                {name: 'category', create: true, read: true, update: true, delete: true},
                {name: 'company', create: true, read: true, update: true, delete: true},
                {name: 'vendor', create: true, read: true, update: true, delete: true},
                {name: 'restaurant-pos', create: true, read: true, update: true, delete: true},
                {name: 'pos-cockpit', create: true, read: true, update: true, delete: true},
                {name: 'riders', create: true, read: true, update: true, delete: true},
                {name: 'waiters', create: true, read: true, update: true, delete: true},
                {name: 'floor-plans', create: true, read: true, update: true, delete: true},
                {name: 'modifiers', create: true, read: true, update: true, delete: true},
                {name: 'deals', create: true, read: true, update: true, delete: true},
                {name: 'device', create: true, read: true, update: true, delete: true},
                {name: 'counter', create: false, read: false, update: false, delete: false},
            ];

        case 'manager':
            return [
                {name: 'pos', create: true, read: true, update: true, delete: true},
                {name: 'purchaseorder', create: true, read: true, update: true, delete: true},
                {name: 'items', create: false, read: true, update: false, delete: false},
                {name: 'orders', create: true, read: true, update: true, delete: true},
                {name: 'customers', create: true, read: true, update: true, delete: true},
                {name: 'discounts', create: false, read: true, update: false, delete: false},
                {name: 'suppliers', create: false, read: true, update: false, delete: false},
                {name: 'tax', create: false, read: true, update: false, delete: false},
                {name: 'sales-tax', create: false, read: true, update: false, delete: false},
                {name: 'purchase-tax', create: false, read: true, update: false, delete: false},
                {name: 'team', create: true, read: true, update: true, delete: true},
                {name: 'setting', create: false, read: true, update: false, delete: false},
                {name: 'location', create: false, read: true, update: false, delete: false},
                {name: 'category', create: false, read: true, update: false, delete: false},
                {name: 'company', create: false, read: true, update: false, delete: false},
                {name: 'vendor', create: false, read: true, update: false, delete: false},
                {name: 'payment-method-tax', create: true, read: true, update: true, delete: true},
                {name: 'restaurant-pos', create: true, read: true, update: true, delete: true},
                {name: 'pos-cockpit', create: true, read: true, update: true, delete: true},
                {name: 'riders', create: false, read: true, update: false, delete: false},
                {name: 'waiters', create: false, read: true, update: false, delete: false},
                {name: 'floor-plans', create: false, read: true, update: false, delete: false},
                {name: 'modifiers', create: false, read: true, update: false, delete: false},
                {name: 'deals', create: false, read: true, update: false, delete: false},
                {name: 'device', create: false, read: true, update: false, delete: false},
            ];

        case 'cashier':
            return [
                {name: 'pos', create: true, read: true, update: true, delete: true},
                {name: 'items', create: false, read: true, update: false, delete: false},
                {name: 'orders', create: false, read: true, update: false, delete: false},
                {name: 'customers', create: true, read: true, update: true, delete: true},
                {name: 'discounts', create: false, read: true, update: false, delete: false},
                {name: 'category', create: false, read: true, update: false, delete: false},
                {name: 'payment-method-tax', create: true, read: true, update: true, delete: true},
                {name: 'restaurant-pos', create: true, read: true, update: true, delete: true},
                {name: 'pos-cockpit', create: true, read: true, update: true, delete: true},
                {name: 'riders', create: false, read: true, update: false, delete: false},
                {name: 'waiters', create: false, read: true, update: false, delete: false},
                {name: 'floor-plans', create: false, read: true, update: false, delete: false},
                {name: 'modifiers', create: false, read: true, update: false, delete: false},
                {name: 'deals', create: false, read: true, update: false, delete: false},
                {name: 'device', create: false, read: false, update: false, delete: false},
            ];

        default:
            return [];
    }
};

export const formatted_date = current_datetime => {
    current_datetime = new Date(current_datetime);
    current_datetime = current_datetime.getDate() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getFullYear();
    return current_datetime.toString();
};

export const getDateForAnalytics = duration => {
    let n = 0,
        obj = {};
    let date = new Date();
    let startDate = '';
    switch (duration) {
        case 'Yesterday':
            n = 1;
            console.log(duration);
            obj.startDate = new Date(new Date(new Date().setDate(new Date().getDate() - n)).setHours(0, 0, 0, 0));
            obj.endDate = new Date(new Date(new Date().setDate(new Date().getDate() - n)).setHours(23, 59, 0, 0));
            break;
        case 'Last 7 Days':
            n = 7;
            console.log(duration);
            obj.startDate = new Date(new Date(new Date().setDate(new Date().getDate() - n)).setHours(0, 0, 0, 0));
            obj.endDate = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 0, 0));
            break;
        case 'Last 30 Days':
            n = 30;
            obj.startDate = new Date(new Date(new Date().setDate(new Date().getDate() - n)).setHours(0, 0, 0, 0));
            obj.endDate = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 0, 0));
            break;
        default:
            console.log(duration);
            obj.startDate = new Date(new Date(new Date().setDate(new Date().getDate() - 0)).setHours(0, 0, 0, 0));
            obj.endDate = new Date(new Date(new Date().setDate(new Date().getDate() - 0)).setHours(23, 59, 0, 0));
    }
    return obj;
};

export const sanitizeOptionValues = values => {
    return Array.isArray(values) ? values.map(String) : [];
};

export const sanitizeOrderDiscount = data => data;

export const isEmpty = (name, phone) => {
    return !name || !phone;
};
export const isUserExist = (customers, phone) => {
    return customers.find(customer => phone === customer.phone);
};

export const isUserExistForUpdation = (customers, phone, customer_id) => {
    return customers.find(customer => phone === customer.phone && customer_id !== customer.id);
};

export const uuid = () => uuidv4();

export const getUserPin = () => Math.floor(1000 + Math.random() * 9000);

export const formatNum = num => {
    return num
        ? Number(num)
              .toFixed(2)
              .replace(/[.,]00$/, '')
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatKNum = val => {
    const num = typeof val === 'string' ? Number(val)?.toFixed(0) : val;
    return num / 1000 + 'k';
};

export const capitalizeFirstLetter = string => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export function formate_time_convert_24_to_12hr(time) {
    try {
        // Parse the input time string with format "HH:mm:ss" to account for seconds
        let parsedTime = moment(time, 'HH:mm:ss');

        if (!parsedTime.isValid()) {
            // Parse the input time string with format "HH:mm" if seconds are not present
            parsedTime = moment(time, 'HH:mm');
        }

        // Convert the parsed time to 12-hour format
        return parsedTime.format('hh:mm A');
    } catch (error) {
        // Handle invalid time format
        return 'Invalid time format';
    }
}

export const FONT_FAMILY = {
    outfit_extrabold: 'Outfit-ExtraBold',
    outfit_bold: 'Outfit-Bold',
    outfit_light: 'Outfit-Light',
    outfit_medium: 'Outfit-Medium',
    outfit_regular: 'Outfit-Regular',
    outfit_semibold: 'Outfit-SemiBold',
    outfit_thin: 'Outfit-Thin',
};

export const FONT_SIZE = {
    xsmall: 10,
    small: 12,
    regular: 14,
    medium: 16,
    large: 18,
    xlarge: 22,
    xxlarge: 26,
    xxxlarge: 34,
    payment: 50,
};

export const COLORS = {
    red: '#e51a32',
    skyBlue: '#27aae1',
    mustardYellow: '#f2bc1d',
    extraLightGray: '#f5f5f7',
    extraLightGray2: '#FAFAFB',
    extraLightGray3: '#D9D9D9',
    lightGray: '#cecece',
    mediumGray: '#565656',
    darkGray: '#414143',
    darkGreen: '#048900',
    darkOrange: '#ef4136',
    extraBrightGreen: '#F2FCD2',
    brightGreen: '#4ac600',
    brightGreen1: '#2DB817',
    brightOrange: '#f96a32',
    brightOrange1: '#DA4848',
    mainColor: '#3b0c36',
    white: '#fff',
    purple: '#710065',
    lightGreen: '#f2fcd2',
    green: '#8BC53F',
    lightOrange: '#ffe7dc',
    brightPurple: '#E5D7E5',
    brightYellow: 'rgba(242, 188, 29, 0.1)',
    lightYellow: '#FFEFCE',
    extralightYellow: '#FFF9E9',
    extraBrightPurple: '#EEEAEE',
    extraBrightRed: '#F9ECED',
    peach: '#FFE6C1',
    lightBlue: '#F7FBFA',
    lightBlue1: '#D5F3F7',
    lightBlue2: 'rgba(247, 251, 250, 1)',
    mediumBlue: '#E9F6FC',
    black: '#414042',
    darkBlack: '#000000',
    mediumGreen: '#36AA71',
    darkGray2: '#7d7d7d',
    violet: '#7386EC',
    seaGreen: '#4DB6AC',
    lightSeaGreen: '#E8F6DF',
    extraLightSeaGreen: '#DFF6E8',
    extraLightSeaGreen2: '#F1FCD2',
    brown: '#B97A69',
    seaBlue: '#3CC9DC',
    lightGray2: '#A4A4A4',
    barclaysBlue: '#00aeef',
    lightOrange2: '#F88439',
    darkVoilet: '#805CF4',
    extraBrightBrown: 'rgba(238, 231, 221, 0.5)',
    blueLight: '#1778F2',
    pinkDark: '#D120BE',
    extraLightGray4: '#FDFDFD',
    extraLightGray5: '#F9ECED',
    darkOrange2: '#E51A32',
    blueDark: '#1778F24D',
    darkPurple: '#3D0137',
    parotGreen: '#1BD7CC',
    seaDarkGreen: '#50C370',
    lightVoilet: '#E5D7E5',
    extraLightOrange: '#FFF4EF',
    skyBlue1: '#079ED7',
    extraLightBlue: '#EFFCFF',
    opacityColor: 'rgba(0, 0, 0, 0.8)',
    lightPink: '#F6E5D0',
    lightGreen: '#D8E4D9',
    extraLightPurple: '#EAF0FF',
    extraLightGreen: '#F6FFF2',
    lightBlue3: '#F6FFF2',
};

export const UNITS = [
    'pcs',
    'kg',
    'gm',
    'ltr',
    'ml',
    'bag',
    'tola',
    'feet',
    'box',
    'packet',
    'carton',
    'dozen',
    'ream',
    'bottle',
    'bundle',
    'pound',
    'roll',
    'gallon',
    'quarter',
    'can',
    'thaan',
    'meters',
];

export const HELPLINE_NUMBER = '021-38341166';

export const formateDate_dd_month_yy = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + ' ' + months[current_datetime.getMonth()] + ' ' + current_datetime.getFullYear();
    return formatted_date;
};

export const formate_month_yy = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = months[current_datetime.getMonth()] + ' ' + current_datetime.getFullYear();
    return formatted_date;
};

export const formateDate_month_yy = date => {
    date = new Date(date);
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    let current_datetime = new Date(date);
    let formatted_date = months[current_datetime.getMonth()] + ' ' + current_datetime.getFullYear();
    return formatted_date;
};

export const formateDate_dd_month_yy_lower = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + ' ' + months[current_datetime.getMonth()] + ' ' + current_datetime.getFullYear();
    return formatted_date;
};

export const formateDate_dd_month_yy_lower_coma = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + ' ' + months[current_datetime.getMonth()] + ', ' + current_datetime.getFullYear();
    return formatted_date;
};

export const formateMonth = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = months[current_datetime.getMonth()];
    return formatted_date;
};

export const formateDate_mm_dd = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = months[current_datetime.getMonth()] + ' ' + current_datetime.getDate();
    return formatted_date;
};

export const formateDate_mm_dd_yyyy = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let current_datetime = new Date(date);
    let formatted_date = months[current_datetime.getMonth()] + ' ' + current_datetime.getDate() + ', ' + current_datetime.getFullYear();
    return formatted_date;
};

export const formateDate_day_mm_dd_yyyy = date => {
    date = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let current_datetime = new Date(date);
    let formatted_date =
        weekdayNames[current_datetime.getDay()] + ', ' + months[current_datetime.getMonth()] + ' ' + current_datetime.getDate() + ', ' + current_datetime.getFullYear();
    return formatted_date;
};

export var formatAMPM = date => {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};
