import {useState} from 'react';
import {Col} from 'react-bootstrap';
import {getCheckUseremail} from '../../../../api';
import {useLanguageContext, useThemeContext} from '../../../../context';
import {Button, CheckBox, TextField} from '../../../common';
import {hashSync} from 'bcryptjs';
import {HASH_SALT, toSnakeCase, validateConfirmPassPin} from '../../../../constants';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../../assets/icons';

const Step2 = ({toggleStep, handleSave}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    let navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [pin, setPin] = useState();
    const [errors, setErrors] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleCheckBox = () => {
        setIsChecked(x => !x);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            const label = 'terms_accepted';
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleBack = () => toggleStep('1');

    const onChange = (label, value) => {
        if (label === I18n.create_password) {
            setPassword(value);
        } else if (label === I18n.confirm_password) {
            setConfirmPassword(value);
        } else if (label == I18n.create_pin_code) {
            setPin(value);
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (label === I18n.create_password) {
                label = 'password';
            }

            if (label === I18n.confirm_password) {
                label = 'confirm_password';
            }

            if (label === I18n.create_pin_code) {
                label = 'pin';
            }
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleNext = () => {
        const params = {
            password,
            confirmPassword,
            pin,
            isChecked,
        };

        const formErrors = validateConfirmPassPin(params);
        console.log('formErrors', formErrors);
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) {
            return;
        }

        setIsLoading(true);
        let hash = hashSync(pin, HASH_SALT);

        let details = {
            password,
            pin: hash,
            isChecked,
        };

        handleSave(details);
        setIsLoading(false);
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleNext();
        }
    };

    return (
        <>
            <Col md={5} onKeyDown={handleEnterPress}>
                <div className="loginFormBox">
                    <div className="signupTextBox">
                        <h1 className="fontSize24 fontWeight700 marBot10" style={{color: theme.text}}>
                            {I18n.lets_create_account}
                        </h1>
                        <p className="fontSize14 fontWeight400 marBot20 darkGrayTwo">{I18n.signing_oscar_is_fast}</p>
                    </div>

                    <TextField
                        onChange={onChange}
                        label={I18n.create_password}
                        placeholder={I18n.create_password}
                        value={password}
                        type={viewPassword ? 'text' : 'password'}
                        OnTextChangeType={'password'}
                        error={errors.password}
                        suffix={viewPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                        suffix_fill={theme.white}
                        suffix_width={'18'}
                        suffix_height={'18'}
                        suffix_viewBox={'0 0 18 18'}
                        suffixClick={() => setViewPassword(prev => !prev)}
                        required={true}
                    />

                    <TextField
                        onChange={onChange}
                        label={I18n.confirm_password}
                        placeholder={I18n.confirm_password}
                        value={confirmPassword}
                        type={viewConfirmPassword ? 'text' : 'password'}
                        OnTextChangeType={'confirm_password'}
                        error={errors.confirm_password}
                        suffix={viewConfirmPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                        suffix_fill={theme.white}
                        suffix_width={'18'}
                        suffix_height={'18'}
                        suffix_viewBox={'0 0 18 18'}
                        suffixClick={() => setViewConfirmPassword(prev => !prev)}
                        required={true}
                    />

                    <TextField
                        onChange={onChange}
                        label={I18n.create_pin_code}
                        placeholder={I18n.create_pin_code}
                        value={pin}
                        // type={viewPassword ? "text" : "password"}
                        type="numeric"
                        error={errors.pin}
                        maxLength={4}
                        required={true}
                    />

                    <div className="signupBottomText">
                        <CheckBox
                            checkboxLabel={
                                <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                    I agree Oscar’s
                                    <a
                                        style={{
                                            textDecoration: 'underline',
                                            color: theme.text,
                                            textUnderlineOffset: '3px',
                                        }}
                                        href="">
                                        {' Terms, '}
                                    </a>
                                    <a
                                        style={{
                                            textDecoration: 'underline',
                                            color: theme.text,
                                            textUnderlineOffset: '3px',
                                        }}
                                        href="">
                                        {'privacy'}
                                    </a>
                                    {' and '}
                                    <a
                                        style={{
                                            textDecoration: 'underline',
                                            color: theme.text,
                                            textUnderlineOffset: '3px',
                                        }}
                                        href="">
                                        policy
                                    </a>
                                </p>
                            }
                            onChange={toggleCheckBox}
                            checked={isChecked}
                        />
                        {<p className="inputError marTop0 marBot0">{errors?.terms_accepted || ''}</p>}
                    </div>

                    <div className="signinLoginButton">
                        <Button className={`width100 fontWeight600 fontSize16 cursorPointer`} title={I18n.continue} handleClick={handleNext} />
                    </div>
                    <div>
                        <p className="fontSize14 marTop0">
                            <span className="white">{I18n.already_have_an_account}</span>

                            <span className="cursorPointer  marLeft5 barclaysBlueColor" onClick={() => navigate('/login')}>
                                {I18n.login}
                            </span>
                            <span className="marLeft5">
                                <Icon name="arrowRight" viewBox="0 0 18 18 " />
                            </span>
                        </p>
                    </div>
                </div>
            </Col>
        </>
    );
};

export default Step2;
