import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';
import {observeActiveRiders, observeArchivedRiders, observeDraftRiders} from '../../../../../pos-core/database/helpers/rider';

const RiderList = ({riders, archivedList, draftList}) => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);

    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_riders);
    const [selected, setSelected] = useState({});
    const [loading, setloading] = useState(false);
    const [riderCount, setRiderCount] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('riders');

    useEffect(() => {
        let count;
        if (filter === I18n.archived) {
            count = archivedList.length;
        } else if (filter === I18n.drafts_saved) {
            count = draftList.length;
        } else {
            count = riders.length;
        }
        setRiderCount(count);
    }, [filter, riders, archivedList, draftList]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'rider_created') {
            setShowMessage({
                visible: true,
                message: I18n.rider_created,
            });
        } else if (renderToast === 'rider_updated') {
            setShowMessage({
                visible: true,
                message: I18n.rider_updated,
            });
        } else if (renderToast === 'rider_published_successfully') {
            setShowMessage({
                visible: true,
                message: I18n.rider_published_successfully,
            });
        } else if (renderToast === 'rider_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.rider_saved_into_draft,
            });
        }
    }, [renderToast]);

    const filterOption = [
        {
            title: I18n.all_riders,
            action: () => handleFilter(I18n.all_riders),
            checked: filter == I18n.all_riders || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };

    const handleCreateRider = () => {
        navigate('/riders/create');
    };

    function handleSearch(val) {
        const filterList = filter === I18n.archived ? archivedList : riders;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values) || x?.phone_number?.includes(values));
    }

    const handleSelectAll = () => {
        let list = filter == I18n.archived ? archivedList : riders;
        if (Object.keys(selected).length != list.length) {
            list?.map(val => {
                setSelected(values => {
                    let obj = {...values};
                    obj[val.id] = val;
                    return obj;
                });
            });
        } else {
            setSelected({});
        }
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelected(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const checkedClick = () =>
        Object.keys(selected)?.length > 0 && Object.keys(selected)?.length === (filter == I18n.archived ? archivedList.length : riders.length) ? true : false;

    const onChange = (label, value) => setText(value);

    const renderList = text ? searchList : filter === I18n.archived ? archivedList : filter === I18n.drafts_saved ? draftList : riders;

    return loading ? (
        <Loading />
    ) : riders.length > 0 ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.rider} (${riderCount})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    rightCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_rider,
                                  action: handleCreateRider,
                              }
                            : null
                    }
                    className={'borderRadiusTopleftTopRigt'}
                />

                <div className="itemListContainer borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 160}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`fiveColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol">
                                        <CheckBox
                                            checkboxLabel={
                                                <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                                    {I18n.name}
                                                </p>
                                            }
                                            onChange={handleSelectAll}
                                            checked={checkedClick()}
                                        />
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.phone}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.email}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.city}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.actions}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={
                                                text ? I18n.search : filter == I18n.archived ? I18n.archive_rider : filter == I18n.drafts_saved ? I18n.draft_rider : I18n.rider
                                            }
                                            subHeading={
                                                text
                                                    ? I18n.we_couldnt_find_any_results_for_your_search
                                                    : filter == I18n.archived
                                                    ? I18n.no_archive_riders_found
                                                    : filter == I18n.drafts_saved
                                                    ? I18n.no_draft_riders_found
                                                    : I18n.no_rider_found
                                            }
                                            name={text ? 'seacrhNotFoundIcon' : filter == I18n.archived ? 'archiveIcon' : 'draftIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => (
                                            <List
                                                handleChecked={handleChecked}
                                                selected={selected}
                                                setSelected={setSelected}
                                                key={index}
                                                rider={val}
                                                filter={filter}
                                                setShowMessage={setShowMessage}
                                                userPermission={userPermission}
                                            />
                                        ))
                                    )}
                                </div>
                                <ToastBox
                                    className="addSuccessfullyToast"
                                    ToastVisiable={showMessage.visible}
                                    bodyPara={showMessage.message}
                                    setShowMessage={setShowMessage}
                                    showIcon={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.rider}
                subHeading={I18n.create_and_manage_your_team}
                name="riderIcon"
                fill={theme.white}
                viewBox={'0 0 100 100'}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                              title: I18n.create_rider,
                              action: handleCreateRider,
                          }
                        : null
                }
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    riders: observeActiveRiders(),
    archivedList: observeArchivedRiders(),
    draftList: observeDraftRiders(),
}));

export default enhance(RiderList);
