import React from 'react';
import OrderPayment from './index';
import {useSearchParams} from 'react-router-dom';

const EnhancedOrderPayment = () => {
    const [searchParams] = useSearchParams();
    const orderNumber = searchParams?.get('id');

    return <OrderPayment orderNumber={orderNumber} />;
};

export default EnhancedOrderPayment;
