import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import ViewDataModal from '../../../../../common/V2/ViewDataModal';
import {
    DashboardHeader,
    DashboardBanner,
    ExpandYourOnline,
    Graphcard,
    KeyMetrics,
    LowSellingItems,
    LowStockItems,
    OutofStockItems,
    TopSellingItems,
    UnmatchedSales,
    UpgradedOscarPlan,
    Loading,
} from '../../../../../common';
import SaleOnline from './SalesOnline';
import SaleReport from './SaleReport';
import LocationWiseSales from '../../../../../common/LocationWiseSales';
import { getLocationWiseSellingView, getLowAndOutStockProducts, getTopAndLowSellingProducts } from '../../../../../../api';
import { dateFilterMapping, formateIdForApi, ITEMS_PER_PAGE } from '../../../../../../constants';
import usePermission from '../../../../../../hooks/usePermission';
import { useAuthContext } from '../../../../../../context';
import TransactionList from '../../../Payments/Transactions/TransactionList';

const SalesOverview = ({ dashboardData, selectedDateRange, setSelectedDateRange, selectedLocation, initialTab = 0, setSelectedLocation, loading, setLoading, visible, setVisible }) => {
    const [viewDataModal, setViewDataModal] = useState(false);
    const [primaryTabValue, setPrimaryTabValue] = useState(initialTab);
    const [reportDropDownOption, setReportDropDownOption] = useState({ name: 'Sales Summary', value: 'sale_summary' });
    const [tabValue, setTabVal] = useState(0);
    const [allListData, setAllListData] = useState({ name: '', data: [] });
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalpages, setTotalPages] = useState(0);
    const [title, setTitle] = useState('');
    const isPosPermission = usePermission('pos');
    const { business } = useAuthContext();

    /**
     * Extracts scalar values (numbers and strings) from an object.
     */
    function extractScalarValues(obj) {
        const result = {
            return_matrix: obj?.return_matrix,
            sales_matrix: obj?.sales_matrix,
            transaction_matrix: obj?.transaction_matrix,
        };

        return result;
    }

    /**
     * Toggles the visibility of the view data modal.
     *
     * @return {void}
     */
    const toggleViewDataModal = () => {
        setViewDataModal(prev => !prev);
    };

    /**
     * Transforms payment data into a format suitable for display.
     *
     * @param {Object} salesByPaymentMethod - An object containing sales data by payment method.
     * @return {Object} An object containing labels, values, additional text, and styles data for display.
     */
    function transformPaymentData(salesByPaymentMethod) {
        const labels = [];
        const salesValues = [];

        for (const [key, method] of Object.entries(salesByPaymentMethod)) {
            // labels.push(`${key.charAt(0).toUpperCase() + key.slice(1)} (${method.total_orders} orders)`);
            labels.push(`${key.charAt(0).toUpperCase() + key.slice(1)}`);
            const sales = method.sales;
            salesValues.push(sales);
        }

        return {
            showLegend: false,
            labels,
            values: salesValues,
            additionalText: [''],
            stylesData: {
                barColor: '#FFE6C1',
                textColor: 'white',
                secondaryTextColor: '#CECECE',
                radius: 14,
            },
        };
    }
    /**
     * Transforms sales data by channel into a format suitable for display.
     *
     * @param {Object} salesData - Sales data object containing 'in_store' and 'online' properties.
     * @return {Object} An object containing labels, values, and styles data for display.
     */
    function transformChannelData(salesData) {
        const labels = [];
        const salesValues = [];

        for (const [key, method] of Object.entries(salesData)) {
            labels.push(`${key.charAt(0).toUpperCase() + key.slice(1)}`);
            salesValues.push(method.sales);
        }

        return {
            showLegend: false,
            labels,
            values: salesValues,
            additionalText: [],
            stylesData: {
                barColor: '#A5B0EE',
                barBgColor: '#313547',
                textColor: 'white',
                secondaryTextColor: '#CECECE',
                radius: 14,
                barPercentage: 0.3
            },
        };
    }

    /**
     * Add daily sales data structure with labels, values,
     * additional text, and styling for dashboard bar chart visualization.
     */
    const dailyData = {
        showLegend: false,
        labels: Object?.keys(dashboardData?.sales_by_day),
        values: Object?.values(dashboardData?.sales_by_day),
        additionalText: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        stylesData: {
            barColor: '#A5B0EE',
            textColor: 'white',
            secondaryTextColor: '#CECECE',
            radius: 14,
        },
    };

    /**
     * Transforms sales data by hour into a format suitable for display.
     *
     * @param {object} salesData - An object containing sales data for each hour.
     * @param {number} tabValue - A value indicating which tab is currently active (0 for AM, 1 for PM).
     * @return {object} An object containing labels, values, and styles for display.
     */
    function transformHourData(salesData, tabValue) {
        const labels = [];
        const values = [];

        for (let hour = 1; hour <= 12; hour++) {
            if (tabValue === 0) {
                labels.push(hour.toString());
                values.push(salesData[hour] || 0);
            } else if (tabValue === 1) {
                const pmHour = hour + 12;
                labels.push(pmHour.toString());
                values.push(salesData[pmHour] || 0);
            }
        }

        return {
            showLegend: false,
            labels: labels,
            values: values,
            additionalText: [''],
            stylesData: {
                barColor: '#FFE6C1',
                textColor: 'white',
                secondaryTextColor: '#CECECE',
                radius: 14,
            },
            tabs: true,
        };
    }

    const keyMetrics = extractScalarValues(dashboardData);
    const channelData = transformChannelData(dashboardData?.sales_by_channel);
    const paymentData = transformPaymentData(dashboardData?.sales_by_payment_method);
    const hourlyData = transformHourData(dashboardData?.sales_by_hour, tabValue);
    const locationString = formateIdForApi(selectedLocation, business?.id);

    /**
     * @param {*} val
     * Implement data retrieval for various dashboard views
     *  including top/low selling items, low/out of stock items, and location-wise sales with
     *  pagination and loading state management.
     */
    const handleGetAllData = async val => {
        setLoading(true);
        setViewDataModal(true);
        setTitle(val);

        try {
            let params = {
                start_date: selectedDateRange?.start_date,
                end_date: selectedDateRange?.end_date,
                date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
                busibusiness_idsness_ids: locationString,
                page: currentPage || 1,
                limit: ITEMS_PER_PAGE || 20,
            };

            let response;

            if (['top_selling_items', 'low_selling_items'].includes(val)) {
                params = {
                    ...params,
                    topSellingItems: val === 'top_selling_items',
                };
                response = await getTopAndLowSellingProducts(params);
            } else if (['low_stock_items', 'out_of_stock_items'].includes(val)) {
                params = {
                    business_id: locationString,
                    is_lowstock: val === 'low_stock_items',
                    page: currentPage || 1,
                    limit: ITEMS_PER_PAGE || 20,
                };
                response = await getLowAndOutStockProducts(params);
            } else if (val === 'location_wise_sales') {
                response = await getLocationWiseSellingView(params);
            }
            if (response?.success) {
                setTotalPages(response?.total_pages || 0);
                setAllListData(prevState => ({
                    name: val,
                    data: [...(prevState?.data || []), ...(response?.items || response?.locations)],
                }));
                setHasMore(currentPage < response?.total_pages);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentPage) {
            if (currentPage <= totalpages && title) {
                handleGetAllData(title);
            }
        }
    }, [currentPage]);

    return (
        <>
            <Row>
                <Col md={12}>
                    <DashboardHeader
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        setSelectedDateRange={setSelectedDateRange}
                        selectedDateRange={selectedDateRange}
                        reportDropDownOption={reportDropDownOption}
                        setReportDropDownOption={setReportDropDownOption}
                        setPrimaryTabValue={setPrimaryTabValue}
                        primaryTabValue={primaryTabValue}
                        visible={visible}
                        setVisible={setVisible}
                    />
                </Col>
            </Row>
            {loading ? (
                <Loading />
            ) : (
                <Row>
                    {primaryTabValue === 0 ? (
                        <>
                            <Col md={12}>
                                <DashboardBanner />
                            </Col>
                            <Col md={12}>
                                <div className="saleKeyMetrics">
                                    <KeyMetrics type={'sale_dashborard'} isPosPermission={isPosPermission} metricsData={keyMetrics} title="key_metrics" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Graphcard type="bar" data={channelData} title={'sales_by_channel'} />
                            </Col>
                            <Col md={6}>
                                <Graphcard type="bar" data={paymentData} title={'sales_by_payment_method'} />
                            </Col>
                            {/* <Col md={6}>
                                <div className="UpgradedOscarPlan">
                                    <UpgradedOscarPlan />
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div className="unmacthedSalesWrapper">
                                    <UnmatchedSales />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Graphcard type="donut" data={dashboardData?.sales_by_category} title="sales_by_category" />
                            </Col>
                            <Col md={6}>
                                <Graphcard type="bar" data={dailyData} title="sales_by_day" />
                            </Col>
                            {/* <Col md={6} className="onlinePresenceMainWrapper">
                                <ExpandYourOnline />
                            </Col> */}

                            <Col md={6}>
                                <div className="salesHourWrapper">
                                    <Graphcard type="bar" tabValue={tabValue} setTabVal={setTabVal} data={hourlyData} title="sales_by_hour" />
                                </div>
                            </Col>
                            <Col md={12}>
                                <TopSellingItems data={dashboardData?.top_selling_items} handleNavigate={() => handleGetAllData('top_selling_items')} />
                            </Col>
                            <Col md={12}>
                                <LowSellingItems data={dashboardData?.low_selling_items} handleNavigate={() => handleGetAllData('low_selling_items')} />
                            </Col>
                            {Object.keys(selectedLocation || {})?.length == 1 && (
                                <Col md={12}>
                                    <LowStockItems data={dashboardData?.low_stock_items} handleNavigate={() => handleGetAllData('low_stock_items')} />
                                </Col>
                            )}

                            {Object.keys(selectedLocation || {})?.length == 1 && (
                                <Col md={12}>
                                    <OutofStockItems data={dashboardData?.out_of_stock_items} handleNavigate={() => handleGetAllData('out_of_stock_items')} />
                                </Col>
                            )}

                            {Object.keys(selectedLocation || {})?.length > 1 && (
                                <Col md={12}>
                                    <LocationWiseSales data={dashboardData?.location_wise_sales} handleNavigate={() => handleGetAllData('location_wise_sales')} />
                                </Col>
                            )}
                        </>
                    ) : primaryTabValue === 1 ? (
                        <TransactionList selectedLocation={selectedLocation} selectedDateRange={selectedDateRange} />
                    ) : primaryTabValue === 2 ? (
                        <SaleOnline />
                    ) : (
                        <SaleReport
                            isPosPermission={isPosPermission}
                            selectedDateRange={selectedDateRange}
                            selectedLocation={selectedLocation}
                            reportDropDownOption={reportDropDownOption}
                        />
                    )}
                </Row>
            )}

            <ViewDataModal
                setAllListData={setAllListData}
                setHasMore={setHasMore}
                setCurrentPage={setCurrentPage}
                hasMore={hasMore}
                allListData={allListData}
                totalpages={totalpages}
                setToggle={setViewDataModal}
                toggle={viewDataModal}
                loading={loading}
                title={title}
                selectedDateRange={selectedDateRange}
                selectedLocation={selectedLocation}
            />
        </>
    );
};

export default SalesOverview;
