import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import NewAppModal from '../../common/NewAppModal';
import RadioButton from '../RadioButton';
import {SPLIT_BILL, SPLIT_BILL_BY_AMOUNT, SPLIT_BILL_BY_DISH, SPLIT_INTO_EQUAL_PAYMENTS, toSnakeCase} from '../../../constants';
import SplitBillByAmount from './SplitBillByAmount';
import SplitBillIntoEqualPayments from './SplitBillIntoEqualPayments';
import {bulkCreatePayments} from '../../../pos-core/database/helpers';
import Icon from '../../../assets/icons';
import {useNavigate} from 'react-router-dom';

const SplitBillModal = ({toggle, setToggle, totalBill, order, business}) => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [splitAmount, setSplitAmount] = useState('');
    const [billType, setBillType] = useState();
    const [selectedBillType, setSelectedBillType] = useState();
    const [payments, setPayments] = useState([]);
    const [numberOfPayment, setNumberOfPayment] = useState(0);
    const [billTypes, setBillTypes] = useState(SPLIT_BILL);
    const [errors, setErrors] = useState({});

    const addlabelsToBillTypes = () => {
        const modifiedTypes = billTypes?.map(item => ({
            ...item,
            label: (
                <div className="splitBillWrapper">
                    <p className="marBot0 marTop0 fontSize14 fontWeight400 white">{item.title}</p>
                    <p className="marBot0 marTop0 fontSize12 fontWeight400 darkGray">{item.subTitle}</p>
                </div>
            ),
            value: item.title,
        }));

        setBillTypes(modifiedTypes);
    };

    useEffect(() => {
        addlabelsToBillTypes();
    }, []);

    function handleClose(event) {
        setToggle(false);
        setBillType('');
        setSelectedBillType('');
    }

    const handleChange = async e => {
        const value = e?.target?.value || e;
        setBillType(value);
    };

    const hanldeContinue = async () => {
        if (selectedBillType) {
            if (selectedBillType === SPLIT_BILL_BY_AMOUNT) {
                const splitPaymentAmount = payments.reduce((acc, curr) => Number(curr.amount) + acc, 0);
                if (totalBill === splitPaymentAmount) {
                    const paymentPayload = payments.map(payment => ({
                        amount: Number(payment.amount),
                        status: 'pending',
                        expiry_date: new Date().getTime(),
                        started_at: new Date().getTime(),
                        created_at: new Date().getTime(),
                    }));

                    await bulkCreatePayments(paymentPayload, business, order);

                    setToggle(false);
                } else {
                    setErrors({
                        amount: splitAmount ? 'Splitted amount is not equal to the total bill amount' : 'Please enter the amount',
                    });
                }
            } else if (selectedBillType === SPLIT_INTO_EQUAL_PAYMENTS) {
                if (numberOfPayment > 0) {
                    const equalAmount = (totalBill / numberOfPayment).toFixed(2);
                    const paymentPayload = Array.from({length: numberOfPayment}, (_, index) => ({
                        amount: Number(equalAmount),
                        status: 'pending',
                        expiry_date: new Date().getTime(),
                        started_at: new Date().getTime(),
                        created_at: new Date().getTime(),
                    }));
                    await bulkCreatePayments(paymentPayload, business, order);

                    setToggle(false);
                } else {
                    setErrors({
                        number_of_split: 'Number of splits will be greater than 0',
                    });
                }
            } else {
                return;
            }
        } else {
            if (billType === SPLIT_BILL_BY_DISH) {
                navigate(`/split-dish?id=${order?.number}`);
            } else {
                setSelectedBillType(billType);
            }
        }
    };

    return (
        <>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={toggle}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleClose()}
                primaryCta={{
                    title: I18n.confirm,
                    action: () => hanldeContinue(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => handleClose(),
                }}
                title={selectedBillType ? I18n[toSnakeCase(selectedBillType)] : I18n.split_bill}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        {selectedBillType === SPLIT_BILL_BY_AMOUNT ? (
                            <SplitBillByAmount
                                totalBill={totalBill}
                                payments={payments}
                                splitAmount={splitAmount}
                                setSplitAmount={setSplitAmount}
                                setPayments={setPayments}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        ) : selectedBillType === SPLIT_INTO_EQUAL_PAYMENTS ? (
                            <SplitBillIntoEqualPayments totalBill={totalBill} setNumberOfPayment={setNumberOfPayment} errors={errors} setErrors={setErrors} />
                        ) : (
                            <>
                                <p className="fontSize20 marBot20" style={{color: theme.white}}>
                                    {I18n.choose_how_to_split_the_bill}
                                </p>

                                <RadioButton options={billTypes} className="billTypesWrapper" selectedSize={billType} handleChange={handleChange} showCustomBtn />
                                {billType === SPLIT_BILL_BY_DISH && (
                                    <div className="flex">
                                        <Icon name="infocircleIcon" fill={theme.barclaysBlue} width="18" height="18" viewBox={'0 0 18 18'} />

                                        <p className="marBot0 fontSize12 marLeft5 fontWeight400" style={{color: theme.white}}>
                                            {I18n.all_discounts_and_customer_must_be_reapplied}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </NewAppModal>
        </>
    );
};

export default SplitBillModal;
