import withObservables from '@nozbe/with-observables';
import {firstLetterCaptilize, formatNum} from '../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import {useEffect, useRef, useState} from 'react';
import {switchMap} from 'rxjs';
import newImagePlaceholder from '../../../../../assets/images/newImagePlaceholder.svg';
import {InputField, QtyIncreaseDecrase} from '../../../../common/V2';
import Icon from '../../../../../assets/icons';

const OrderItem = ({
    order,
    item,
    key,
    product_template,
    product_modifier,
    handleProduct,
    handleItemDiscount,
    handleSelectedItem,
    setModifierModal,
    setSelectedModifier,
    modifierModal,
    setItemNotes,
    setVoidReason,
    existingOrderLines,
    restaurantPos = false,
    paymentScreen = false,
}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [itemName, setItemName] = useState(item?.name);
    const [amount, setAmount] = useState(item?.selling_price || '');
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);
    const [qtyCounterToggle, setQtyCounterToggle] = useState(false);
    const [activeItem, setActiveItem] = useState(false);
    const [productModifiers, setProductModifiers] = useState(null);
    const [orderLineModifiers, setOrderLineModifiers] = useState(null);
    const [isNewlyAdded, setIsNewlyAdded] = useState(false);

    const amountRef = useRef(null);
    const nameRef = useRef(null);
    const qtyref = useRef(null);

    const getModifiers = async () => {
        if (!product_modifier) return;
        const mappedModifiers = await Promise.all(
            product_modifier?.map(async x => {
                const modifiers = await x?.modifier?.fetch();
                if (modifiers?.archive) {
                    return null;
                }

                const modifiersOption = await modifiers?.modifier_option.fetch();
                return {
                    modifiers,
                    modifiersOption,
                };
            }),
        );

        const filteredModifiers = mappedModifiers.filter(mod => mod !== null);
        setProductModifiers(filteredModifiers);
    };

    const setLineModifiers = () => {
        const mappedModifiers = [];
        Object.values(item?.order_line_modifiers || {})?.forEach(obj => {
            Object.values(obj).forEach(itemObj => {
                mappedModifiers.push(itemObj);
            });
        });
        setOrderLineModifiers(mappedModifiers);
    };

    const checkNewlyAddedItem = () => {
        const newItem = !existingOrderLines?.some(elem => elem.id === item.id);
        setIsNewlyAdded(newItem);
    };

    useEffect(() => {
        document.body.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.body.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (item.is_open) {
            amountRef?.current?.focus();
        }
    }, [showDeleteBtn]);

    useEffect(() => {
        if (restaurantPos) {
            getModifiers();
            setLineModifiers();
            checkNewlyAddedItem();
        }
    }, [modifierModal, existingOrderLines, item]);

    const onChange = (label, value) => {
        if (label === I18n.open_item_name) {
            setItemName(value);
        } else if (label === I18n.amount) {
            setAmount(value);
        }
    };

    function handleClickOutside(event) {
        if (event.target.closest('.cartItemQtyBox') === null) {
            setQtyCounterToggle(false);
        }
    }

    const handledelete = async () => {
        if (restaurantPos && paymentScreen) return;
        await item?.deleteOrderLine();
    };
    const toggleDeleteBtn = () => {
        if (item.is_open) setShowDeleteBtn(prev => !prev);
    };

    const handleOpenItemPrice = async () => {
        await item?.updatePrice(Number(amount));
        toggleDeleteBtn();
    };

    const handleOpenItemName = async () => {
        await item.updateName(itemName);
    };

    const handleAmount = async event => {
        if (event.keyCode === 13) {
            await handleOpenItemPrice();
        }
    };

    const handleName = async event => {
        if (event.keyCode === 13) {
            await handleOpenItemName();
        }
    };

    const handleQuantityToggle = () => {
        if (item.void) return;
        setQtyCounterToggle(prev => !prev);
    };

    const handleUpdateItemQty = (item, qty, type) => {
        if (item.void || (restaurantPos && paymentScreen)) return;
        handleProduct(item, qty, type);
    };

    const handleDiscountAmount = () => {
        let discount = item.discount_type === 'percentage' ? (item.discount * item._raw.base_price) / 100 : item.discount;
        return formatNum(discount * item.quantity);
    };

    const handleSelected = () => {
        if (item.void) return;

        handleSelectedItem(item);
        setActiveItem(prev => !prev);
    };
    const handleItemLevelDiscount = () => {
        handleItemDiscount(item);
    };

    const handleItemLevelNotes = () => {
        if (restaurantPos && paymentScreen) return;
        setItemNotes(item);
    };

    const handleVoidReason = () => {
        setVoidReason(item);
    };

    const calculateOrderLinePrice = () => {
        if (Object.values(item?.order_line_modifiers || {}).length > 0 && restaurantPos) {
            const modifierPrice = orderLineModifiers?.reduce((acc, curr) => {
                return acc + Number(curr?.price);
            }, 0);
            const totalModifierPrice = modifierPrice + item?.selling_price;
            return formatNum(totalModifierPrice);
        } else {
            return formatNum(item?.selling_price);
        }
    };

    const handleModifier = () => {
        if (item.void || paymentScreen) return;
        setSelectedModifier(item?.order_line_modifiers);
        setModifierModal({
            visible: true,
            selectedItem: item,
            modifiers: productModifiers,
        });
    };

    const handleChangeFocus = event => {
        if (event.key === 'Tab' || event.keyCode === 9 || event.code === 'Tab') {
            event.preventDefault();

            const {activeElement} = document;

            if (activeElement === amountRef.current) {
                amountRef.current?.blur();
                nameRef.current?.focus();
            } else if (activeElement === nameRef.current) {
                setQtyCounterToggle(true);
                setTimeout(() => {
                    nameRef.current?.blur();
                    qtyref.current?.focus();
                }, 500);
            } else if (activeElement === qtyref.current) {
                qtyref.current?.blur();
                amountRef.current?.focus();
            }
        }
    };

    const handleCombinedAmountFocus = async event => {
        await handleAmount(event);
        handleChangeFocus(event);
    };

    const handleCombinedNameFocus = async event => {
        await handleName(event);
        handleChangeFocus(event);
    };

    return (
        <>
            <div
                key={key}
                className={`${item.void ? 'disableCartItem' : 'cartItemListInner'}`}
                onClick={handleSelected}
                style={{
                    borderColor: theme.seperatorThree,
                }}>
                <div className="cartItemNameImage">
                    <span className="itemListImage marRight15">
                        <img src={product_template?.image || newImagePlaceholder} style={{height: 40, width: 40, resizeMode: 'cover', borderRadius: '5px'}} />
                    </span>
                    {item?.is_open ? (
                        <span className="cartItemName">
                            <InputField
                                onChange={onChange}
                                onBlur={handleOpenItemName}
                                label={I18n.open_item_name}
                                placeholder="Enter item name"
                                value={itemName}
                                inputRef={nameRef}
                                onKeyDown={handleCombinedNameFocus}
                            />
                            {item?.note && (
                                <p
                                    style={{
                                        color: item.void ? theme.disableGray : theme.textgray,
                                    }}
                                    className="marBot0 marTop5 fontSize14 fontWeight400 OneLineTruncate">
                                    {item.note}
                                </p>
                            )}
                        </span>
                    ) : (
                        <span>
                            <p style={{color: item.void ? theme.disableGray : theme.white}} className="marBot0 fontSize18 fontWeight500 OneLineTruncate">
                                {firstLetterCaptilize(item?.name)}
                            </p>
                            {item?.note && (
                                <p
                                    style={{
                                        color: item.void ? theme.disableGray : theme.lightGray,
                                    }}
                                    className="marBot0 fontSize14 fontWeight400 OneLineTruncate">
                                    {item.note}
                                </p>
                            )}
                        </span>
                    )}
                </div>

                <div
                    className="cartItemQtyBox"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <QtyIncreaseDecrase
                        item={item}
                        toggle={qtyCounterToggle}
                        inputRef={qtyref}
                        handleUpdateItemQty={handleUpdateItemQty}
                        handleToggle={handleQuantityToggle}
                        trigger="onChange"
                    />
                </div>

                <div className="amountIconContainer">
                    {item?.is_open && showDeleteBtn === false ? (
                        <span className="cartItemInputFeild">
                            <InputField
                                onChange={onChange}
                                onBlur={handleOpenItemPrice}
                                label={I18n.amount}
                                placeholder="0"
                                type="numeric"
                                value={amount}
                                inputRef={amountRef}
                                maxLength={8}
                                onKeyDown={handleCombinedAmountFocus}
                                prefix={{
                                    name: 'rsIcon2',
                                    fill: theme.seperatorTwo,
                                    height: 18,
                                    width: 18,
                                    viewBox: '0 0 34 34',
                                }}
                            />
                        </span>
                    ) : (
                        <span className="cartItemAmountandIconBox">
                            <div className="amountBox">
                                <p className="marBot0 fontSize16 fontWeight400" style={{color: item.void ? theme.disableGray : theme.white}}>
                                    {`Rs. ${calculateOrderLinePrice()}`}
                                </p>

                                {item.void ? (
                                    <p
                                        className="marBot0 fontSize12 OneLineTruncate"
                                        style={{
                                            color: theme.brightOrange,
                                        }}>
                                        {`Voided. ${item.void_reason}`}
                                    </p>
                                ) : item?.discount > 0 ? (
                                    <p
                                        className="marBot0 fontSize14 fontWeight400"
                                        style={{
                                            color: item.void ? theme.disableGray : theme.textgray,
                                        }}>
                                        {`-Rs. ${handleDiscountAmount()}`}
                                    </p>
                                ) : null}
                            </div>
                            {activeItem && (
                                <div className="iconConatiner cursorPointer marBot0">
                                    {item?.is_open && <Icon name="editIcon2" fill={theme.barclaysBlue} width="18" height="18" viewBox={'0 0 18 18'} onClick={toggleDeleteBtn} />}

                                    <Icon name="discountShapeIcon" fill={theme.barclaysBlue} width="18" height="18" viewBox={'0 0 18 18'} onClick={handleItemLevelDiscount} />

                                    {restaurantPos && (
                                        <Icon name="documentIcon" fill={theme.barclaysBlue} width="16" height="18" viewBox={'0 0 16 18'} onClick={handleItemLevelNotes} />
                                    )}

                                    {!restaurantPos ? (
                                        <Icon name="delete2Icon" fill={theme.red} width="18" height="18" viewBox={'0 0 18 18'} onClick={() => handledelete('delete')} />
                                    ) : order?.status === 'active' || isNewlyAdded ? (
                                        <Icon name="delete2Icon" fill={theme.red} width="18" height="18" viewBox={'0 0 18 18'} onClick={() => handledelete('delete')} />
                                    ) : (
                                        <Icon name="closeIcon" fill={theme.brightOrange} width={'13'} height={'13'} viewBox={'0 0 15 15'} onClick={() => handleVoidReason()} />
                                    )}
                                </div>
                            )}
                        </span>
                    )}
                </div>
            </div>
            {restaurantPos && productModifiers?.length > 0 && (
                <div className={`${item.void ? 'disabledModifierFieldWrapper' : 'modifierFieldWrapper'}`} onClick={() => handleModifier()}>
                    {Object.values(item?.order_line_modifiers || {}).length > 0 ? (
                        <div>
                            {orderLineModifiers?.map((item, ind) => (
                                <span style={{color: theme.darkGrayTwo, fontSize: '12px'}} key={ind}>{`${item?.name}${ind === orderLineModifiers.length - 1 ? '.' : ', '}`}</span>
                            ))}
                        </div>
                    ) : (
                        <p className="marBot0 fontSize14" style={{color: theme.barclaysBlue}}>
                            {`+ ${I18n.modifier?.toLowerCase()}`}
                        </p>
                    )}

                    {Object.values(item?.order_line_modifiers || {}).length > 0 && !item?.void && !paymentScreen && (
                        <Icon name="pencilIcon" fill={theme.barclaysBlue} viewBox={'0 0 13 13'} />
                    )}
                </div>
            )}
        </>
    );
};

const enhance = withObservables(['item', 'isOpenItem'], ({item, isOpenItem}) => {
    const observables = {
        item: item.observe(),
    };
    if (!isOpenItem) {
        observables.product_template = item?.product.observe().pipe(switchMap(product => product.product_template.observe()));
        observables.product_modifier = item?.product.observe().pipe(switchMap(product => product.product_modifier.observe()));
    }
    return observables;
});

export default enhance(OrderItem);
