import './App.css';
import {AuthProvider, LanguageProvider, MenuProvider, SessionProvider, ThemeProvider} from './context';
import AppRoutes from './routes';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import {database} from './pos-core/database';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getToastIcon} from './constants';
const App = () => {
    return (
        <DatabaseProvider database={database}>
            <AuthProvider>
                <ThemeProvider>
                    <LanguageProvider>
                        <SessionProvider>
                            <MenuProvider>
                                <AppRoutes />
                                <ToastContainer position="bottom-left" icon={({type}) => getToastIcon(type)} />
                            </MenuProvider>
                        </SessionProvider>
                    </LanguageProvider>
                </ThemeProvider>
            </AuthProvider>
        </DatabaseProvider>
    );
};

export default App;
