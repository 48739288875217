import React, {useEffect} from 'react';
import {DEVICE_HEIGHT, formatNum} from '../../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import {Button, CheckBox, ToastBox} from '../../../../../common';
import Icon from '../../../../../../assets/icons';
import {useOrderCalculation} from '../../../../../../hooks';

const BillCard = ({item, selectedItems, handleChecked, length, handleCreate, handleDelete, setBillTotals, showMessage, setShowMessage}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {order, orderLines} = item;
    const {subTotal, total, tax, giveItemLevelDiscount} = useOrderCalculation({orderLines, isRestaurantPos: true});
    const isSplittedCard = !order.number;
    const isLastElement = orderLines.length === 1;

    const calculateOrderLinePrice = line => {
        if (Object.values(line?.order_line_modifiers || {}).length > 0) {
            const modifierPrice = Object.values(line?.order_line_modifiers || {}).reduce((totalPrice, modifierGroup) => {
                const groupPrice = Object.values(modifierGroup).reduce((groupTotal, modifier) => {
                    return groupTotal + (Number(modifier?.price) || 0);
                }, 0);

                return totalPrice + groupPrice;
            }, 0);

            const totalModifierPrice = modifierPrice + line?.selling_price;
            return formatNum(totalModifierPrice);
        } else {
            return formatNum(line?.selling_price);
        }
    };

    const handleDiscountAmount = item => {
        let discount = item.discount_type === 'percentage' ? (item.discount * item.base_price) / 100 : item.discount;
        return formatNum(discount * item.quantity);
    };

    useEffect(() => {
        const id = Number(order?.number ? order?.number : order.bill_number);
        setBillTotals(prev => ({...prev, [id]: {total, isOrder: order?.number ? true : false}}));
    }, [total]);

    return (
        <div className="splitDishCard" style={{display: 'flex', flexDirection: 'column', height: DEVICE_HEIGHT - 150}}>
            <div className="splitDishCardHead">
                <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                    {order.number ? `Order #${order.number}` : `Bill ${order.bill_number}`}
                </p>

                {isSplittedCard && (
                    <span onClick={() => handleDelete(order.bill_number, null, 'card')} className="cursorPointer" style={{position: 'relative', bottom: '4px'}}>
                        <Icon name="closeIcon" fill={theme.darkGrayTwo} width={'12'} height={'12'} viewBox={'0 0 15 15'} />
                    </span>
                )}
            </div>
            <div className="splitDishCardList" style={{flex: 1, overflowY: 'auto'}}>
                {!isSplittedCard && (
                    <p className="fontSize14 fontWeight500" style={{color: theme.white}}>
                        {`Select dishes for bill ${length + 1}`}
                    </p>
                )}

                {orderLines?.map((line, ind) => {
                    return (
                        <div className="marTop10" key={ind}>
                            <div className="spaceBetweenCenter">
                                {isSplittedCard ? (
                                    <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.text}}>
                                        {line.name}
                                    </p>
                                ) : (
                                    <CheckBox
                                        className="itemlistCheckBox"
                                        checkboxLabel={
                                            <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.text}}>
                                                {line.name}
                                            </p>
                                        }
                                        onChange={() => handleChecked(line)}
                                        checked={selectedItems[line.uniqueKey]}
                                    />
                                )}

                                <div style={{display: 'flex', alignItems: 'end', minWidth: 'max-content'}}>
                                    <p className={`marBot0 fontSize14 ${isSplittedCard ? 'marRight10' : ''}`} style={{color: theme.text}}>
                                        {`Rs. ${calculateOrderLinePrice(line)}`}
                                    </p>
                                    {isSplittedCard && (
                                        <span
                                            onClick={() => handleDelete(order.bill_number, line.uniqueKey, isLastElement ? 'card' : 'line')}
                                            className="cursorPointer"
                                            style={{position: 'relative', bottom: '2px'}}>
                                            <Icon name="closeIcon" fill={theme.darkGrayTwo} width={'12'} height={'12'} viewBox={'0 0 15 15'} />
                                        </span>
                                    )}
                                </div>
                            </div>
                            {(line.note || line.discount) && (
                                <div className="spaceBetweenCenter">
                                    <p className={`marBot0 fontSize12 ${isSplittedCard ? 'marLeft0' : 'marLeft35'}`} style={{color: theme.textgray}}>
                                        {line.note || ''}
                                    </p>

                                    <div>
                                        <p className={`marBot0 fontSize12 ${isSplittedCard ? 'marRight20' : ''}`} style={{color: theme.textgray}}>
                                            {`-Rs. ${handleDiscountAmount(line)}`}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {Object.values(line?.order_line_modifiers || {}).length > 0 && (
                                <div className={`modifierList marTop5 OneLineTruncate ${isSplittedCard ? 'marLeft0 marRight20' : 'marLeft35'}`}>
                                    {Object.values(line?.order_line_modifiers || {})?.map(obj =>
                                        Object.values(obj).map((itemObj, ind) => (
                                            <span className="OneLineTruncate" style={{color: theme.darkGrayTwo, fontSize: '12px'}} key={ind}>{`${itemObj?.name}${
                                                ind === Object.values(obj || {}).length - 1 ? '.' : ', '
                                            }`}</span>
                                        )),
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            <div>
                <div className="splitDishCardAmountBox">
                    <div className="spaceBetweenCenter" style={{padding: '0px 15px'}}>
                        <p className="marBot0 fontSize14" style={{color: theme.white}}>
                            {I18n.bill_amount}
                        </p>

                        <p className="marBot0 fontSize14 fontWeigh600" style={{color: theme.white}}>
                            {`Rs. ${formatNum(total)}`}
                        </p>
                    </div>

                    <div className="thinDivider width100 marTop10 marBot10 " />

                    <div className="spaceBetweenCenter" style={{padding: '2px 15px'}}>
                        <p className="marBot0 fontSize12" style={{color: theme.textgray}}>
                            {I18n.sub_total}
                        </p>

                        <p className="marBot0 fontSize12" style={{color: theme.white}}>
                            {`Rs. ${formatNum(subTotal)}`}
                        </p>
                    </div>
                    <div className="spaceBetweenCenter" style={{padding: '2px 15px'}}>
                        <p className="marBot0 fontSize12" style={{color: theme.textgray}}>
                            {I18n.total_tax}
                        </p>

                        <p className="marBot0 fontSize12" style={{color: theme.white}}>
                            {`Rs. ${formatNum(tax)}`}
                        </p>
                    </div>
                    {giveItemLevelDiscount > 0 && (
                        <div className="spaceBetweenCenter" style={{padding: '2px 15px'}}>
                            <p className="marBot0 fontSize12" style={{color: theme.textgray}}>
                                {I18n.discount}
                            </p>

                            <p className="marBot0 fontSize12" style={{color: theme.white}}>
                                {`-Rs. ${formatNum(giveItemLevelDiscount)}`}
                            </p>
                        </div>
                    )}
                </div>
                {!isSplittedCard && (
                    <div style={{margin: '15px 15px 0px 15px'}} className="closeButtonBox">
                        <Button
                            disabled={Object.values(selectedItems || {}).length === 0}
                            className={`headerbuttonStyle marTop0 cursorPointer width100`}
                            title="Create new bill"
                            handleClick={() => handleCreate(isLastElement, orderLines.length)}
                        />
                    </div>
                )}
            </div>
            <ToastBox
                className="addSuccessfullyToast errorToastMessage"
                ToastVisiable={showMessage.visible}
                bodyPara={showMessage.message}
                setShowMessage={setShowMessage}
                showIcon={true}
                errorToast={true}
            />
        </div>
    );
};

export default BillCard;
