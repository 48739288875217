import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import {useAuthContext, useLanguageContext, useMenuContext, useSessionContext, useThemeContext} from '../../../context';
import {useState} from 'react';
import {DEVICE_HEIGHT} from '../../../constants';

import {useNavigate} from 'react-router-dom';
import MessageModal from '../MessageModal';
import HamburgerItem from '../HamburgerMenu/HamburgerItem';
import HamburgerSubItems from '../HamburgerMenu/HamburgerSubItems';
import Cookies from 'js-cookie';

const SecondaryHamburgerMenu = () => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {isVisible, setIsVisible} = useMenuContext();
    const [logoutModal, setLogoutModal] = useState(false);

    const toggleMenu = () => setIsVisible(false);

    const handleSelect = route => {
        if (route == 'logout') {
            setLogoutModal(true);
        } else {
            navigate(`/${route}`);
        }
        setIsVisible(false);
    };

    const handleLogout = async () => {
        setLogoutModal(false);
        Cookies.remove('secret_key');
        Cookies.remove('device_id');
        navigate('/kds-login');
    };

    return (
        <>
            <Offcanvas
                className="navigationSiderbar"
                backdrop={false}
                show={isVisible}
                onHide={toggleMenu}
                style={{
                    backgroundColor: theme.background,
                    height: DEVICE_HEIGHT,
                }}>
                <Offcanvas.Body>
                    <Nav className="sidePaneNav" variant="pills" activeKey="1" onSelect={handleSelect}>
                        <HamburgerItem hamburgerIcon="devices" hamburgerTitle={I18n.kitchen_display} eventKey="kitchendisplay" />

                        <HamburgerItem hamburgerIcon="settingIcon" hamburgerTitle="Display setting" eventKey="displaysetting" />
                        <HamburgerItem hamburgerIcon="settingIcon" hamburgerTitle="Timers & alerts" eventKey="timersetting" />

                        <HamburgerItem hamburgerIcon="logoutIcon" hamburgerTitle={I18n.nav_logout} eventKey="logout" />
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

            <MessageModal
                setToggle={setLogoutModal}
                toggle={logoutModal}
                title={I18n.logout}
                description={I18n.do_you_really_want_to_logout}
                subDescription={I18n.your_session_is_active}
                secondaryCta={{
                    title: I18n.cancel,
                    action: () => setLogoutModal(false),
                }}
                primaryCta={{
                    title: I18n.logout,
                    action: handleLogout,
                    isDelete: true,
                }}
            />
        </>
    );
};

export default SecondaryHamburgerMenu;
