import React, { useEffect, useState } from 'react';
import { useAuthContext, useLanguageContext, useThemeContext } from '../../../context';
import TextField from '../TextField';
import RangePickerCalendar from '../RangePickerCalendar';
import NewDropDown from '../NewDropDown';
import { formatDateWithTime, PaymentDropDown, salesDropDown, salesReportDropDown } from '../../../constants';
import TabBox from '../TabBox';

const DashboardHeader = ({
    setSelectedDateRange,
    selectedLocation,
    setSelectedLocation,
    reportDropDownOption,
    setReportDropDownOption,
    type = 'sales_dashboard',
    primaryTabValue,
    setPrimaryTabValue,
    visible,
    setVisible,
}) => {
    const { business, userBusiness, role, account } = useAuthContext();
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const [locationOption, setLocationOption] = useState([]);

    const onFilterDate = (label = 'Today', startDateStr = '', endDateStr = '', diffInDays = 0) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        if (!isNaN(startDate) && !isNaN(endDate)) {
            setSelectedDateRange({
                start_date: formatDateWithTime(startDate),
                end_date: formatDateWithTime(endDate),
                label: label,
            });
        }
    };

    const fetchAllBusiness = async () => {
        const fetchedLocations = [];
        fetchedLocations.push(
            ...userBusiness?.map(item => ({
                business_name: item.location_name,
                id: item.id,
            })),
        );
        fetchedLocations.unshift({
            business_name: I18n.all_location,
            id: 'all',
        });

        setLocationOption(fetchedLocations);
    };

    useEffect(() => {
        fetchAllBusiness();
    }, []);

    const onChange = (label, value) => {
        if (label === I18n.reports) {
            setReportDropDownOption({ name: value?.name?.toLowerCase(), value: value.value });
        }
    };

    const handleChange = (event, newValue) => {
        setPrimaryTabValue(newValue);

        if (type === 'sales_dashboard') {
            if (newValue === 3) {
                setVisible({
                    reports: true,
                    location: true,
                    dateSelector: true,
                });
            } else {
                setVisible({
                    reports: false,
                    location: true,
                    dateSelector: true,
                });
            }
        } else if (type === 'payment_dashboard') {
            if (newValue === 1) {
                setVisible({
                    reports: false,
                    location: false,
                    dateSelector: false,
                });
            } else if (newValue === 2) {
                setVisible({
                    reports: false,
                    location: false,
                    dateSelector: true,
                });
            } else {
                setVisible({
                    reports: false,
                    location: true,
                    dateSelector: true,
                });
            }
        }
    };

    return (
        <div className="dashboardFilterBox">
            <div className="dashboardFilterBoxLeft">
                <div className="dahboardViewBox">
                    <TabBox tabValue={primaryTabValue} tabs={type == 'payment_dashboard' ? PaymentDropDown : salesDropDown} handleChange={handleChange} />
                </div>
            </div>

            <div className="dashboardFilterBoxRight">
                {visible?.reports && (
                    <div className="reportsDropwdown">
                        <TextField
                            label={I18n.reports}
                            placeholder={I18n.reports}
                            data={salesReportDropDown}
                            value={reportDropDownOption?.name}
                            onChange={onChange}
                            inputType="select"
                            type="text"
                            suffix="downIcon2"
                            suffix_fill={theme.white}
                            suffix_width={'24'}
                            suffix_height={'24'}
                            suffix_viewBox={'0 0 18 18'}
                            hideInputError={true}
                        />
                    </div>
                )}
                {visible?.location && (
                    <NewDropDown
                        iconName="locationIcon"
                        filterOption={locationOption}
                        setSelectedLocation={setSelectedLocation}
                        selectedLocation={selectedLocation}
                        title={I18n.location}
                    />
                )}

                {visible?.dateSelector && <RangePickerCalendar onFilterDate={onFilterDate} />}
            </div>
        </div>
    );
};

export default DashboardHeader;
