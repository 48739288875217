import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';

const MetricsCard = ({data, index}) => {
    const {I18n} = useLanguageContext();
    const {title, value} = data || {};

    const isNonCurrencyMetric = ['items_solds', 'transections', 'average_bucket_size'].includes(title);
    const formattedValue = isNonCurrencyMetric ? formatNum(value) : formatNum(value, 'Rs. ');

    return (
        <div className="metricsBoxInner" key={index}>
            <p className="fontSiz16 fontweight400 darkGrayTwo marBot10">{I18n[title]}</p>
            <p className="fontSiz16 fontWeight600 white marBot10">{formattedValue}</p>
        </div>
    );
};

export default MetricsCard;
