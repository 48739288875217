import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CheckBox, Header, MessageModal, NonIdealScreen, ToastBox} from '../../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import usePermission from '../../../../../../hooks/usePermission';
import withObservables from '@nozbe/with-observables';
import {observeActiveModifier, observeArchiveModifier} from '../../../../../../pos-core/database/helpers/modifier';
import {Col, Container, Row} from 'react-bootstrap';
import TableHeader from '../../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../../constants';
import {useSearch} from '../../../../../../hooks';
import List from './List';

const ModifierList = ({modifier, archiveModifier}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {renderToast, setRenderToast} = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState(I18n.all_modifiers);
    const {text, setText, searchList} = useSearch(handleSearch);
    const [selecteditem, setSelecteditem] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const userPermission = usePermission('modifiers');
    const renderList = text ? searchList : filter === I18n.archive ? archiveModifier : modifier;
    const messagesMap = {
        [I18n.modifier_created_successfully]: I18n.modifier_created_successfully,
        [I18n.modifier_updated_successfully]: I18n.modifier_updated_successfully,
        [I18n.modifier_archived_successfully]: I18n.modifier_archived_successfully,
        [I18n.modifier_unarchived_successfully]: I18n.modifier_unarchived_successfully,
    };

    const handleFilter = val => {
        setFilter(val);
    };

    const actionList = [
        {
            title: filter === I18n.archive ? I18n.unarchive : I18n.archive,
            action: () => toggleArchiveModal(),
        },
    ];

    const filterOption = [
        {
            title: I18n.all_modifiers,
            action: () => handleFilter(I18n.all_modifiers),
            checked: filter == I18n.all_modifiers || filter === '' ? true : false,
        },
        {
            title: I18n.archive,
            action: () => handleFilter(I18n.archive),
            checked: filter == I18n.archive ? true : false,
        },
    ];

    function handleSearch(val) {
        const filterList = modifier;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values));
    }

    const navigateToCreate = () => {
        navigate('/modifiers/create');
    };

    const onChange = (label, value) => {
        setText(value);
    };

    const handleCreateModifier = () => {
        navigate('/modifiers/create');
    };

    const checkedClick = () => (Object.keys(selecteditem)?.length > 0 && Object.keys(selecteditem)?.length === renderList?.length ? true : false);

    const handleSelectAll = async () => {
        if (Object.keys(selecteditem)?.length !== renderList.length) {
            const newSelectedItems = renderList.reduce((acc, val) => {
                acc[val?.id] = val;
                return acc;
            }, {});

            setSelecteditem(newSelectedItems);
        } else {
            setSelecteditem({});
        }
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelecteditem(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const handleArchivedAction = async selected => {
        setloading(true);

        let status = filter == I18n.all_modifiers ? true : false;

        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async mod => {
                const productModifier = await mod.product_modifier.fetch();
                await mod.archiveModifier(status);
                await Promise.all(productModifier.map(elem => elem.updateProductModifierStatus(status)));
                selectedIds.push(mod.id);
            }),
        );

        setSelecteditem(selected => {
            let obj = {...selected};
            selectedIds?.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });

        setloading(false);
        handleFilter(filter);
        setArchiveModal(false);
    };

    const toggleArchiveModal = () => {
        if (Object.values(selecteditem)?.length) {
            setArchiveModal(x => !x);
        } else {
            setShowMessage({
                visible: true,
                message: 'Please select item first',
            });
        }
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (messagesMap?.[renderToast]) {
            setShowMessage({
                visible: true,
                message: messagesMap[renderToast],
            });
        }
    }, [renderToast]);

    return (
        <div className="modifiersScreen">
            {modifier?.length || archiveModifier?.length ? (
                <>
                    <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                        <Header
                            type="search"
                            title={`${I18n.modifiers} (${renderList?.length})`}
                            search={{
                                type: 'pos',
                                placeholder: I18n.search_by_name,
                                prefix: {
                                    name: 'search2Icon',
                                    fill: theme.white,
                                },
                                onChange: onChange,
                            }}
                            className={'borderRadiusTopleftTopRigt'}
                            leftCta={{
                                title: filter,
                                name: 'downIcon2',
                                fill: theme.white,
                                width: '20',
                                height: '20',
                                viewBox: '0 0 18 18',
                                option: filterOption,
                            }}
                            rightCta={
                                userPermission?.canCrud
                                    ? {
                                          title: I18n.create_modifier,
                                          action: handleCreateModifier,
                                      }
                                    : null
                            }
                            actionCta={
                                userPermission?.canCrud
                                    ? {
                                          title: I18n.actions,
                                          name: 'downIcon2',
                                          fill: theme.white,
                                          width: '20',
                                          height: '20',
                                          viewBox: '0 0 18 18',
                                          option: actionList,
                                      }
                                    : null
                            }
                        />

                        <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <TableHeader className={`fourColumn`} style={{borderColor: theme.inputBorder}}>
                                            <div
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}
                                                className="BoxWidth justifyStart itemListBox">
                                                <CheckBox
                                                    className={'itemlistCheckBox'}
                                                    checkboxLabel={
                                                        <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                                            {I18n.name}
                                                        </p>
                                                    }
                                                    onChange={handleSelectAll}
                                                    checked={checkedClick()}
                                                />
                                            </div>

                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.items}
                                                </p>
                                            </div>

                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.details}
                                                </p>
                                            </div>

                                            {userPermission?.canCrud && (
                                                <div className="BoxWidth justifyCenter">
                                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                        {I18n.action}
                                                    </p>
                                                </div>
                                            )}
                                        </TableHeader>
                                        <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                            {renderList?.length === 0 ? (
                                                <NonIdealScreen
                                                    heading={filter === I18n.archive ? I18n.archive_modifier : I18n.search}
                                                    subHeading={filter === I18n.archive ? I18n.no_archive_modifier_found : I18n.we_couldnt_find_any_results_for_your_search}
                                                    name={filter === I18n.archive ? 'draftIcon' : 'seacrhNotFoundIcon'}
                                                    fill={theme.white}
                                                    isMultiStep={false}
                                                    tablenonIdeal={true}
                                                />
                                            ) : (
                                                renderList?.map((val, index) => (
                                                    <List
                                                        key={index}
                                                        modifier={val}
                                                        userPermission={userPermission}
                                                        filter={filter}
                                                        setRenderToast={setRenderToast}
                                                        handleChecked={handleChecked}
                                                        selecteditem={selecteditem}
                                                        setSelecteditem={setSelecteditem}
                                                    />
                                                ))
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
                    <MessageModal
                        className="messageModal"
                        setToggle={setArchiveModal}
                        toggle={archiveModal}
                        description={
                            filter === I18n.archive ? I18n.are_you_sure_you_want_to_unarchive_the_selected_modifier : I18n.are_you_sure_you_want_to_archive_the_selected_modifier
                        }
                        secondaryCta={{
                            title: I18n.no,
                            action: toggleArchiveModal,
                        }}
                        primaryCta={{
                            title: I18n.yes,
                            action: () => handleArchivedAction(selecteditem),
                            isDelete: true,
                            loading: loading,
                        }}
                    />
                </>
            ) : (
                <>
                    <NonIdealScreen
                        heading={I18n.setup_modifiers}
                        paragraph={I18n.setup_modifiers_and_customize}
                        name="modifierIcon"
                        viewBox="0 0 100 100"
                        fill={theme.white}
                        secondaryCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.create_modifiers,
                                      action: () => navigateToCreate(),
                                  }
                                : null
                        }
                        isMultiStep={false}
                    />
                </>
            )}
        </div>
    );
};

const enhance = withObservables([], () => ({
    modifier: observeActiveModifier(),
    archiveModifier: observeArchiveModifier(),
}));

export default enhance(ModifierList);
