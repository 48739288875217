import withObservables from '@nozbe/with-observables';
import {useEffect, useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {of, switchMap} from 'rxjs';
import {Sync} from '../../../../../api';
import {DEVICE_HEIGHT, handleUpdateProductQty} from '../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useOrderCalculation, useSessionUser} from '../../../../../hooks';
import useShortcut from '../../../../../hooks/useShortcut';
import {
    bulkUpdateOrderLinesCal,
    bulkUpdateOrderProductQuantity,
    getActiveSplittedOrders,
    getActiveSplittedOrdersCount,
    observeActiveOrder,
    observeGetOrderById,
} from '../../../../../pos-core/database/helpers';
import {observeActivePaymentMethod} from '../../../../../pos-core/database/helpers/payment_method';
import {BillAmountBox, LeftPane, NonIdealScreen, PosDiscountModal, RightPane, ToastBox, TransactionSuccessfulModal} from '../../../../common';
import ItemNotesModal from '../../../../common/ItemNotesModal';
import SplitBillModal from '../../../../common/SplitBillModal';
import {ItemDiscountsModal, NewPosHeader, SelectedCustomerModal, VerificationModal, WalletPaymentModal} from '../../../../common/V2';
import OrderItem from '../../POS/CreateOrder/OrderLine';
import CardMethod from './CardMethod';
import CashMethod from './CashMethod';
import PaymentMethod from './PaymentMethod';
import WalletMethod from './WalletMethod';
import Icon from '../../../../../assets/icons';
import LoyaltyPointsModal from '../../../../common/V2/LoyaltyPointsModal';

const OrderPayment = ({orders, orderLines, customer, discount, sales_tax, payment_method, pending_payments, completed_payments, all_payments}) => {
    let order = orders[0];

    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business, user, persistData, setPersistData} = useAuthContext();
    const {localUser} = useSessionUser();
    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    const isRestaurantPos = orderNumber ? true : false;
    const pendingPayment = pending_payments?.length > 0 ? pending_payments[0] : null;
    const isLastPayment = completed_payments?.length + 1 === all_payments?.length;

    const [selectedAction, setSelectedAction] = useState([]);
    const [posDiscountModal, setPosDiscountModal] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [posDiscount, setPosDiscount] = useState(discount || undefined);
    const [orderDiscountModal, setOrderDiscountModal] = useState(false);
    const [orderselectedItem, setOrderselectedItem] = useState({});
    const [selectedWallet, setSelectedWallet] = useState('');
    const [toggleWalletModal, setToggleWalletModal] = useState(false);
    const [toggleVerifyModal, settoggleVerifyModal] = useState(false);
    const [splitBillModal, setSplitBillModal] = useState(false);
    const [orderNotesModal, setOrderNotesModal] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({});
    const [splitOrdersLength, setSplitOrdersLength] = useState(0);
    const [redeemPoints, setRedeemPoints] = useState(null);
    const [selectedpaymentMethodTax, setSelectedpaymentMethodTax] = useState([]);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    useEffect(() => {
        getSplitOrderDetails();
        setAmount(persistData?.orderAmount || 0);
    }, [persistData, successModal]);

    useEffect(() => {
        const handleFetchPaymentMatedTax = async () => {
            let data = await selectedAction?.sales_tax?.fetch();
            setSelectedpaymentMethodTax(data);
        };
        handleFetchPaymentMatedTax();
    }, [selectedAction]);

    const {subTotal, amount, setAmount, change, total, tax, givenPosDiscount, giveItemLevelDiscount, inclusive, exclusive, redeemAmount, initialTotal} = useOrderCalculation({
        order,
        orderLines,
        discount,
        sales_tax,
        selectedpaymentMethodTax,
        isRestaurantPos,
        pendingPayment,
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (payment_method && payment_method?.length > 0) {
            const cashMethod = payment_method.find(method => method.name === 'cash');
            setSelectedAction(cashMethod || null);
        }
    }, [payment_method]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    const handlePayment = type => {
        setSelectedAction(type);
    };

    const handleProduct = (val, qty, type) => {
        handleUpdateProductQty(val, qty, type, orderLines, order, business.type);
    };

    const handlePosDiscount = async val => {
        setPosDiscount(val);
        await order.updatePosDiscount(val);
        setPosDiscountModal(false);
    };

    const handleDeletePosDiscount = async () => {
        setPosDiscountModal(false);
        setPosDiscount();
        await order.updatePosDiscount(null);
    };

    const togglePosDiscount = () => {
        setPosDiscountModal(prev => !prev);
    };

    const toggleSelectCustomer = async () => {
        if (amount) {
            setPersistData({orderAmount: amount});
        }
        setSelectCustomer(true);
    };

    const handleCreateCustomer = () => {
        navigate(`/customers/create?id=${order.number}`);
    };

    const handleSelectCustomer = async val => {
        const customerId = order?.customer?.id;
        let message = '';
        if (val == 'removeCustomer') {
            await order?.updateCustomer(null);
            message = I18n.remove_Customer;
        } else {
            message = customerId ? I18n.update_Customer : I18n.customer_added;
            await order?.updateCustomer(val);
        }

        setSelectCustomer(false);
        resetRedeemPoints();
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    const handleDone = async (method, isSplittedBill) => {
        console.log('HANDLE DONE CALL');
        if (successModal || (method !== 'card' && amount < total)) return;

        await bulkUpdateOrderLinesCal(orderLines);
        const filteredOrderLines = orderLines.filter(ord => !ord.is_open);
        await bulkUpdateOrderProductQuantity(filteredOrderLines, 'sale', business?.id);

        let paramsForOrder = {
            user: localUser,
            payment_method: isSplittedBill ? '' : method,
            total: isSplittedBill ? initialTotal : total,
            amount: isSplittedBill ? 0 : method === 'card' ? Number(total) : Number(amount),
            change: isSplittedBill ? 0 : method === 'card' ? Number(0) : Number(change),
            sub_total: subTotal,
            total_tax: tax,
            total_item_level_discount: giveItemLevelDiscount,
            order_level_discount: givenPosDiscount,
        };

        let finalOrder = await order.updateOrderCompletion(paramsForOrder);

        if (!isSplittedBill) {
            // create payment for retail and restaurant orders if bill is not splitted.

            let payParams = {
                order: finalOrder,
                total: finalOrder?.total,
                payment_method: method,
                card_number: '',
                received_amount: method === 'card' ? Number(total) : Number(amount),
                change: method === 'card' ? Number(0) : Number(change),
                status: 'complete',
                completed_at: new Date().getTime(),
                // expiry_date: new Date().getTime(),
                created_at: new Date().getTime(),
                started_at: new Date().getTime(),
            };

            await business.createPayment(payParams);
        }

        // let assume 10 rs is equal to 1 point, will fetch this value from database

        if (customer) {
            const pointsRate = 10;
            const redeemedPoints = Number(order.redeemed_points || 0);
            const pointsEarned = Math.ceil(total / pointsRate);

            await order.updateEarnedPoints(Number(pointsEarned));
            await customer.updateCustomerPoints(pointsEarned, 'add');
            await customer.updateCustomerPoints(redeemedPoints, 'subtract');

            if (method === 'credit') {
                await customer.updateCredit(Number(total), 'add');
            }
        }

        setSuccessModal({
            total,
            change,
            method,
        });

        if (posDiscount) {
            await posDiscount?.updateTotalGiven(givenPosDiscount);
        }
        await Sync(user.email);
        unbindShortcuts();
    };

    const {unbindShortcuts} = useShortcut({
        keys: [{key: 'enter', action: handleDone}],
    });

    const handleItemDiscount = () => {
        setOrderDiscountModal(true);
    };

    const handleOrderDiscount = async (type, value) => {
        let discountAvailable = orderselectedItem?.discount;
        let discountObj = {
            value: Number(value),
            type,
        };

        await orderselectedItem?.updateItemDistcount(discountObj);
        setOrderDiscountModal(false);
        if (!discountAvailable) {
            setShowMessage({
                visible: true,
                message: I18n.item_level_discount_added,
            });
        }
    };

    const handleSelectedItem = item => {
        setOrderselectedItem(item);
    };

    const handleToggleAccountModal = () => {
        setToggleWalletModal(prev => !prev);
    };
    const handleSelectedWallet = val => {
        setSelectedWallet(val);
        handleToggleAccountModal();
    };

    const handleSaveAccountNo = val => {
        handleToggleAccountModal(); // api fetch account details
        settoggleVerifyModal(true);

        setTimeout(() => {
            handleToggleAccountModal();
            settoggleVerifyModal(false);
        }, '2000');
    };

    const hanldeSplitBill = () => {
        if (pendingPayment) return;
        setSplitBillModal(true);
    };

    const hanldeOrderNoteModal = async () => {
        setOrderNotesModal(true);
    };

    const updateOrderNotes = async orderNote => {
        await order.updateOrderNotes(orderNote);
    };

    const handleBack = () => {
        setPersistData(null);
        navigate(-1);
    };

    const handleRedeemPoints = async val => {
        await order.updateRedeemedPoints(Number(val));
    };

    const handleRedeem = () => {
        setRedeemPoints(true);
    };

    const resetRedeemPoints = async () => {
        setRedeemPoints(null);
        await handleRedeemPoints(0);
    };

    const getSplitOrderDetails = async () => {
        const orderNumber = order?.parent_number || order?.number;
        const activeSplittedOrders = await getActiveSplittedOrdersCount(orderNumber);
        setSplitOrdersLength(activeSplittedOrders);
    };

    return (
        <section className="posBillingMain" style={{height: DEVICE_HEIGHT - 45}}>
            <Container fluid className="noPadding">
                <Row className="posSectionRow noMargin">
                    <LeftPane>
                        <NewPosHeader
                            title={{
                                name: I18n.order_details,
                            }}
                            customer={customer}
                            order={order}
                            handleRedeem={handleRedeem}
                            leftAction={{
                                title: customer?.name || I18n.add_customer,
                                icon_name: customer ? 'profileTicketIcon' : 'add2Icon',
                                textColor: theme.barclaysBlue,
                                action: toggleSelectCustomer,
                            }}
                            centerAction={
                                isRestaurantPos
                                    ? splitOrdersLength === 0 && !order.parent_number
                                        ? {
                                              title: 'Split bill',
                                              textColor: pendingPayment ? theme.seperatorTwo : theme.barclaysBlue,
                                              action: hanldeSplitBill,
                                          }
                                        : null
                                    : null
                            }
                            rightAction={
                                isRestaurantPos
                                    ? {
                                          title: discount?.name || I18n.order_discount,
                                          textColor: theme.barclaysBlue,
                                          action: togglePosDiscount,
                                      }
                                    : {
                                          title: order?.note ? I18n.note_added : I18n.order_note,
                                          textColor: theme.barclaysBlue,
                                          action: hanldeOrderNoteModal,
                                      }
                            }
                            backBtnCta={true}
                            backAction={handleBack}
                        />

                        {orderLines?.length ? (
                            <div
                                className="posrightSectionInner"
                                style={{
                                    height: DEVICE_HEIGHT - 105,
                                    backgroundColor: theme.newposRightBg,
                                }}>
                                <div className="cartItemList">
                                    {orderLines?.map((item, index) => (
                                        <OrderItem
                                            item={item}
                                            key={index}
                                            isOpenItem={item.is_open}
                                            handleSelectedItem={handleSelectedItem}
                                            handleProduct={handleProduct}
                                            handleItemDiscount={handleItemDiscount}
                                            restaurantPos={isRestaurantPos}
                                            paymentScreen={true}
                                        />
                                    ))}
                                </div>

                                <div
                                    className="pospaymentScreenAmountBox"
                                    style={{
                                        height: 'auto',
                                    }}>
                                    {order?.note && (
                                        <div className="orderNoteWrapper">
                                            <Icon name="pencilIcon" fill={theme.barclaysBlue} viewBox={'0 0 13 13'} onClick={() => hanldeOrderNoteModal()} />
                                            <p className="fontSize14 marBot0 marLeft5 OneLineTruncate" style={{color: theme.lightGrayTwo}}>
                                                {order?.note}
                                            </p>
                                        </div>
                                    )}
                                    <div>
                                        <BillAmountBox
                                            className={`width100`}
                                            subTotal={subTotal}
                                            total={total}
                                            tax={tax}
                                            giveItemLevelDiscount={giveItemLevelDiscount}
                                            givenPosDiscount={givenPosDiscount}
                                            redeemAmount={redeemAmount}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="width100"
                                style={{
                                    backgroundColor: 'red',
                                    height: DEVICE_HEIGHT - 105,
                                }}>
                                <NonIdealScreen
                                    heading={I18n.session}
                                    backgroundColor={theme.topBarBG}
                                    subHeading={I18n.please_go_to_the_POS_and_add_items_to_the_list}
                                    name="goToPOSIcon"
                                    fill={theme.white}
                                    secondaryCta={{
                                        title: I18n.go_to_pos,
                                        action: () => navigate(-1),
                                    }}
                                    isMultiStep={false}
                                />
                            </div>
                        )}
                    </LeftPane>
                    <RightPane>
                        <NewPosHeader
                            title={{
                                name: `${I18n.select_payment_method} ${
                                    pendingPayment ? `(Amount Rs. ${pendingPayment?.amount}, payment ${completed_payments?.length + 1} of ${all_payments?.length})` : ''
                                }`,
                            }}
                        />
                        <div
                            style={{
                                height: DEVICE_HEIGHT - 105,
                                backgroundColor: theme.topBarBG,
                            }}>
                            <div className="paymentRightHead">
                                <PaymentMethod selected={selectedAction} payment_method={payment_method} handlePayment={handlePayment} />
                            </div>

                            <div className="paymentRightBody width100">
                                {selectedAction?.name === 'cash' || selectedAction?.name === 'credit' ? (
                                    <CashMethod
                                        paymentType={selectedAction}
                                        handleDone={handleDone}
                                        amount={amount}
                                        setAmount={setAmount}
                                        givenPosDiscount={givenPosDiscount}
                                        total={total}
                                        change={change}
                                        customer={customer}
                                        setShowMessage={setShowMessage}
                                        orderLines={orderLines}
                                        order={order}
                                        pendingPayment={pendingPayment}
                                        completed_payments={completed_payments}
                                        isLastPayment={isLastPayment}
                                        setSuccessModal={setSuccessModal}
                                    />
                                ) : selectedAction?.name === 'wallet' ? (
                                    <WalletMethod orderLines={orderLines} handleSelected={handleSelectedWallet} />
                                ) : (
                                    <CardMethod
                                        paymentType={selectedAction}
                                        order={order}
                                        orderLines={orderLines}
                                        givenPosDiscount={givenPosDiscount}
                                        total={total}
                                        change={change}
                                        handleDone={handleDone}
                                        customer={customer}
                                        pendingPayment={pendingPayment}
                                        completed_payments={completed_payments}
                                        isLastPayment={isLastPayment}
                                        setSuccessModal={setSuccessModal}
                                        setShowMessage={setShowMessage}
                                    />
                                )}
                            </div>
                        </div>
                    </RightPane>
                    <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
                </Row>
            </Container>

            <ItemDiscountsModal
                setToggle={setOrderDiscountModal}
                selectedItem={orderselectedItem}
                handleSelectedItem={handleSelectedItem}
                toggle={orderDiscountModal}
                handleSave={handleOrderDiscount}
                showDiscountType
                title={I18n.item_level_discount}
            />
            <PosDiscountModal
                setToggle={setPosDiscountModal}
                toggle={posDiscountModal}
                handleSelect={handlePosDiscount}
                order={order}
                handleDeletePosDiscount={handleDeletePosDiscount}
            />

            <SelectedCustomerModal
                setToggle={setSelectCustomer}
                toggle={selectCustomer}
                handleSelect={handleSelectCustomer}
                order={order}
                handleCreateCustomer={handleCreateCustomer}
            />

            <WalletPaymentModal
                setToggle={setToggleWalletModal}
                toggle={toggleWalletModal}
                selectedWallet={selectedWallet}
                handleSave={handleSaveAccountNo}
                customerInfo={customerInfo}
            />

            <VerificationModal toggle={toggleVerifyModal} setToggle={settoggleVerifyModal} />

            <SplitBillModal toggle={splitBillModal} setToggle={setSplitBillModal} totalBill={total} order={order} business={business} />

            <TransactionSuccessfulModal
                setToggle={setSuccessModal}
                toggle={successModal}
                order={order}
                orderLines={orderLines}
                givenPosDiscount={givenPosDiscount}
                tax={exclusive}
                isRestaurantPos={isRestaurantPos}
                activeOrders={orders}
                pendingPayment={pendingPayment}
                completed_payments={completed_payments}
                customer={customer}
                splitOrdersLength={splitOrdersLength}
            />

            <ItemNotesModal order={order} visible={orderNotesModal} setVisible={setOrderNotesModal} saveNotes={updateOrderNotes} />

            <LoyaltyPointsModal
                visible={redeemPoints}
                setVisible={setRedeemPoints}
                handleRedeemPoints={handleRedeemPoints}
                setShowMessage={setShowMessage}
                customer={customer}
                order={order}
            />
        </section>
    );
};

const enhance = withObservables(['orderNumber'], ({orderNumber}) => {
    return {
        orders: orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder(),
        orderLines: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order =>
                order?.length ? order[0].order_line.observeWithColumns(['quantity', 'archive', 'unit', 'discount', 'selling_price', 'order_line_modifiers']) : of(null),
            ),
        ),
        payment_method: observeActivePaymentMethod(),
        customer: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => (order?.length ? order[0].customer.observe() : of(null)))),
        discount: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => (order?.length ? order[0].discount.observe() : of(null)))),
        sales_tax: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => (order?.length ? order[0].sales_tax.observe() : of(null)))),
        pending_payments: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => (order?.length ? order[0].getPendingPayments().observe() : of(null))),
        ),
        completed_payments: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => (order?.length ? order[0].getCompletedPayments().observe() : of(null))),
        ),
        all_payments: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => (order?.length ? order[0].getAllPayments().observe() : of(null))),
        ),
    };
});

export default enhance(OrderPayment);
