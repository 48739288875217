import React, {useEffect, useState} from 'react';
import {Header} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import BillCard from './BillCard';
import withObservables from '@nozbe/with-observables';
import {bulkCreateOrderLines, bulkDeleteOrderLines, bulkUpdateOrderLinesQty, observeGetOrderById} from '../../../../../pos-core/database/helpers';
import {of, switchMap} from 'rxjs';
import {filterOptions, mergeQuantitiesWithRef, separateOrdersForBilling, uuid, DEVICE_HEIGHT} from '../../../../../constants';

const SplitByDish = ({order, orderLines}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, counter} = useAuthContext();
    let navigate = useNavigate();

    const [selectedItems, setSelectedItems] = useState({});
    const [splitOrders, setSplitOrders] = useState([]);
    const [billTotals, setBillTotals] = useState({});
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const handleBack = () => {
        navigate(-1);
    };

    const createOrdersAndLines = async (splitOrderArray, currentOrder) => {
        try {
            const floor_plan = await currentOrder?.order?.floor_plan.fetch();
            const dine_in_table = await currentOrder?.order?.dine_in_table.fetch();
            const waiter = await currentOrder?.order?.waiter.fetch();

            const createOrderPromises = splitOrderArray.map(async splitOrder => {
                const {order, orderLines, total, number} = splitOrder;

                let createdOrder = await business.createOrder({
                    number,
                    user,
                });

                const payload = {
                    floor_plan,
                    dine_in_table,
                    waiter,
                    started_at: currentOrder?.order?.started_at,
                    status: currentOrder?.order?.status,
                    note: currentOrder?.order?.note,
                    type: currentOrder?.order?.type,
                    no_of_guests: currentOrder?.order?.no_of_guests,
                    parent_number: currentOrder?.order?.number,
                    total,
                };

                const newlyCreatedOrder = await createdOrder.setOrderFieldsAndRelation(payload);
                await bulkCreateOrderLines(newlyCreatedOrder, orderLines);

                return createdOrder;
            });

            const createdOrders = await Promise.all(createOrderPromises);
            return createdOrders;
        } catch (error) {
            console.log('Error creating orders and lines:', error);
        }
    };

    const handleSplit = async () => {
        try {
            const {currentOrder, splitOrdersArray, lastOrderNumber} = separateOrdersForBilling(splitOrders, billTotals, counter);

            orderLines = orderLines?.filter(line => !line.void);

            // get deletion and updation ordelines of current order
            const {updationArray, deletionArray} = filterOptions(orderLines, currentOrder?.orderLines, 'split_bill');
            const finalUpdationArray = mergeQuantitiesWithRef(updationArray);

            // set discount, customer and redeempoints to null of current order
            await currentOrder?.order?.updateCustomer(null);
            await currentOrder?.order?.updatePosDiscount(null);
            await currentOrder?.order?.updateOrderTotal(currentOrder?.total);
            await currentOrder?.order?.updateRedeemedPoints(0);

            // delete and update orderlines for current order
            await bulkUpdateOrderLinesQty(finalUpdationArray);
            await bulkDeleteOrderLines(deletionArray);

            // Create new orders based on the splitOrders array (In loop of splitOrdersArray)
            await createOrdersAndLines(splitOrdersArray, currentOrder);
            await counter.updateOrderCount(lastOrderNumber);

            navigate(`/pos/billing?id=${currentOrder?.order?.number}`);
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleChecked = orderLine => {
        const orderLineId = orderLine.uniqueKey;
        setSelectedItems(prev => {
            let obj = {...prev};
            let item = obj[orderLineId];
            if (item) {
                delete obj[orderLineId];
            } else {
                obj[orderLineId] = {...orderLine};
            }
            return obj;
        });
    };

    const augmentArray = () => {
        orderLines = orderLines?.filter(line => !line.void);

        const splitOrderLines = [];
        orderLines.forEach(async orderLine => {
            const qty = orderLine.quantity;
            if (qty > 1) {
                for (let i = 0; i < qty - 1; i++) {
                    splitOrderLines.push({
                        ...orderLine._raw,
                        modifiedQuantity: 1,
                        quantity: 1,
                        product: orderLine.product,
                        order_line_modifiers: {...orderLine.order_line_modifiers},
                        uniqueKey: uuid(),
                    });
                }
            }

            splitOrderLines.push({
                ...orderLine._raw,
                modifiedQuantity: 1,
                quantity: 1,
                order_line_modifiers: {...orderLine.order_line_modifiers},
                product: orderLine.product,
                uniqueKey: uuid(),
            });
        });
        const temp = {
            order: order[0],
            orderLines: splitOrderLines,
        };
        setSplitOrders(prev => [...prev, temp]);
    };

    const handleCreate = (isLastElement, orderLineLength) => {
        const selectedItemsArray = Object.values(selectedItems || {});
        const allSeclected = selectedItemsArray.length === orderLineLength;

        if (selectedItemsArray.length === 0 || isLastElement || allSeclected) {
            setShowMessage({
                visible: true,
                message: 'Bill must have at least more than one item',
            });
            return;
        }

        const selectedKeys = selectedItemsArray.map(item => item.uniqueKey);

        const newOrder = {
            order: {
                bill_number: splitOrders.length + 1,
            },
            orderLines: Object.values(selectedItems),
        };

        const updatedSplitOrders = splitOrders.map(splitOrder => {
            if (splitOrder.order.number) {
                const updatedOrderLines = splitOrder.orderLines.filter(orderLine => !selectedKeys.includes(orderLine.uniqueKey));
                return {
                    ...splitOrder,
                    orderLines: updatedOrderLines,
                };
            }
            return splitOrder;
        });

        setSplitOrders(prev => [...updatedSplitOrders, newOrder]);
        setSelectedItems({});
    };

    const handleDelete = (cardId, lineId, deleteType) => {
        if (deleteType === 'card') {
            const elem = splitOrders.find(spOrd => spOrd.order?.bill_number === cardId);
            const updatedSplitOrders = splitOrders
                .map(splitOrder => {
                    if (splitOrder.order.number) {
                        return {
                            ...splitOrder,
                            orderLines: [...splitOrder.orderLines, ...elem.orderLines],
                        };
                    }
                    return splitOrder;
                })
                .filter(x => x.order?.bill_number !== cardId);

            const reIndexedSplitOrders = updatedSplitOrders.map((splitOrder, index) => {
                if (splitOrder.order?.number) {
                    return splitOrder;
                }
                return {
                    ...splitOrder,
                    order: {
                        ...splitOrder.order,
                        bill_number: index + 1,
                    },
                };
            });

            setSplitOrders(reIndexedSplitOrders);
        } else {
            const elem = splitOrders.find(spOrd => spOrd.order?.bill_number === cardId);
            const orderLine = elem.orderLines.find(line => line.uniqueKey === lineId);

            const updatedSplitOrders = splitOrders.map(splitOrder => {
                if (splitOrder.order.number) {
                    return {
                        ...splitOrder,
                        orderLines: [...splitOrder.orderLines, orderLine],
                    };
                }
                if (splitOrder.order.bill_number) {
                    return {
                        ...splitOrder,
                        orderLines: splitOrder.orderLines.filter(ord => ord.uniqueKey !== lineId),
                    };
                }
                return splitOrder;
            });

            setSplitOrders(updatedSplitOrders);
        }
    };

    useEffect(() => {
        augmentArray();
    }, []);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    return (
        <div>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleBack}
                saveCta={{
                    title: I18n.confirm,
                    action: () => handleSplit(),
                }}
            />
            <p className="marBot10 fontSize24 fontWeight600 textCenter" style={{color: theme.white, marginTop: '-50px'}}>
                {I18n.split_bill_by_dish}
            </p>
            <Container fluid style={{height: DEVICE_HEIGHT - 110, overflow: 'auto'}}>
                <Row className="marTop20">
                    {splitOrders?.map((ord, ind) => (
                        <Col sm={3} md={3} lg={3} xl={3} xxl={2} className="marBot20" key={ind}>
                            <BillCard
                                item={ord}
                                selectedItems={selectedItems}
                                handleChecked={handleChecked}
                                length={splitOrders.length}
                                handleCreate={handleCreate}
                                handleDelete={handleDelete}
                                setBillTotals={setBillTotals}
                                setShowMessage={setShowMessage}
                                showMessage={showMessage}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

const enhance = withObservables([''], ({}) => {
    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    return {
        order: observeGetOrderById(orderNumber),
        orderLines: observeGetOrderById(orderNumber).pipe(
            switchMap(order =>
                order?.length ? order[0].order_line.observeWithColumns(['quantity', 'archive', 'unit', 'discount', 'selling_price', 'order_line_modifiers']) : of(null),
            ),
        ),
    };
});

export default enhance(SplitByDish);
