import React from 'react'
import { useLanguageContext, useThemeContext } from '../../../context';
import { unMatchedBanner } from '../../../assets/images/image';
import DashboardDataBox from '../DashboardDataBox';
import Button from '../Button';
import { toast } from 'react-toastify';

const UnmatchedSales = () => {

  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();

  const handleRoute = () => {
    toast.info(I18n.coming_soon);
  }
  return (
    <DashboardDataBox title='achieve_unmatched_sales_growth_online'>
      <div className='unmacthedSalesBoxInner'>
        <img src={unMatchedBanner} alt={I18n.achieve_unmatched_sales_growth_online} title={I18n.achieve_unmatched_sales_growth_online} />
        <p className='fontSize14 fontWeight400 white marTop20 marBot20'>
          {I18n.achieve_unmatched_sales_growth_descp} <br />
          {I18n.achieve_unmatched_sales_growth_descp_two}
        </p>
        <Button
          type="secondary"
          className={`fontSize18 cursorPointer`}
          backgroundColor={theme.brightGreen}
          txtColor={theme.white}
          title={I18n.preview_site}
          handleClick={handleRoute}
        />
      </div>
    </DashboardDataBox>
  )
}

export default UnmatchedSales
