import React from 'react'
import { PaymentImage } from '../../../assets/images/image';
import Button from '../Button';
import { useLanguageContext, useThemeContext } from '../../../context';
import { toast } from 'react-toastify';

const DashboardBanner = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleRoute = () => {
    toast.info(I18n.coming_soon);
  }
  return (
    <div className='dashboardBannerMain'>
      <div className='dashBannerLeft'>
        <h1 className='fontSize24 fontWeight600 white'>
          {I18n.effortless_payments_solutions}
        </h1>
        <p className='fontSize14 fontWeight400 white'>
          {I18n.effortless_payments_descp_one}<br />
          {I18n.effortless_payments_descp_two}
        </p>
        <div className='dashboardBannerListData'>
          <ul>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.accept_payments_via_card_machines}</span>
            </li>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.lowest_rates_in_pakistan}</span>
            </li>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.Automated_reconciliation}</span>
            </li>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.user_friendly_payments_dashboard}</span>
            </li>
          </ul>
        </div>
        <Button
          type="secondary"
          className={`fontSize18 cursorPointer`}
          backgroundColor={theme.brightGreen}
          txtColor={theme.white}
          title={I18n.explore_payment_plans}
          handleClick={handleRoute}
        />
      </div>
      <div className='dashBannerRight'>
        <img src={PaymentImage} alt="" />
      </div>
    </div>

  )
}

export default DashboardBanner;
