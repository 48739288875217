import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Sync} from '../../../api';
import {UNITS, formatNum, getFirstErrorMessage} from '../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {bulkCreateCategory, bulkCreateCustomer, bulkCreateDiscount, bulkCreateSalestax, bulkCreateTemplateAndItem, observeAllProducts} from '../../../pos-core/database/helpers';
import CsvUploadComponent from '../CsvUploadComponent';
import CustomerCSVTable from '../CustomerCSVTable';
import DiscountCSVTable from '../DiscountCSVTable';
import ItemCSVTable from '../ItemCSVTable';
import TaxCSVTable from '../TaxCSVTable';
import {PosAppModal} from '../V2';
import withObservables from '@nozbe/with-observables';

const CsvUploadModal = ({toggle, setToggle, type, products}) => {
    const {I18n} = useLanguageContext();
    const {business, user, account} = useAuthContext();
    const {theme} = useThemeContext();

    const [data, setData] = useState([]);
    const [viewCsvTable, setViewCsvTable] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleClose = event => {
        setToggle(false);
    };

    const {formateRow} = data;

    const handleSave = async formateRow => {
        if (formateRow?.length) {
            try {
                setBtnLoading(true);
                if (type === 'customer') {
                    await bulkCreateCustomer(formateRow, account);
                } else if (type === 'discount') {
                    await bulkCreateDiscount(formateRow, account, business);
                } else if (type === 'tax') {
                    await bulkCreateSalestax(formateRow, account);
                } else if (type === 'item') {
                    const productCategories = await account?.category?.fetch();
                    const locations = [business];
                    const uniqueCategories = new Set(formateRow?.map(item => item?.category?.toString()?.toLowerCase()));
                    const uniqueCategoriesArray = [...uniqueCategories];
                    const filteredCategories = uniqueCategoriesArray?.filter(name => !productCategories.some(category => category.name?.toLowerCase() === name.toLowerCase()));
                    await bulkCreateCategory(filteredCategories, account);
                    const allCategories = await account?.category?.fetch();
                    await Promise.all(
                        formateRow?.map(async item => {
                            const productCategory = allCategories?.find(category => category?.name?.toLowerCase() === item?.category?.toString()?.toLowerCase());

                            const {barcode, image, category, units, cost_per_item, description, price, name, qty, low_stock_alert, id} = item;

                            let selling = Number(price) || 0;
                            let costing = Number(cost_per_item) || 0;
                            let totalProfit = selling - costing;
                            let totalMargin = (totalProfit / costing) * 100;
                            let Profit = totalProfit <= 0 ? '0' : totalProfit;
                            let margin = totalMargin <= 0 || totalMargin == Infinity ? '0' : formatNum(totalMargin);
                            let unit = UNITS?.find(itemUnit => itemUnit?.name?.toLowerCase() === units?.toLowerCase())?.name;

                            let productObj = {
                                cost_price: Number(cost_per_item),
                                description: description || '',
                                image: image || '',
                                is_taxable: false,
                                is_trackable: false,
                                name: name,
                                rate: 0,
                                base_price: Number(price || 0),
                                secondary_unit: '',
                                unit: unit || '',
                                barcode: barcode?.toString() || '',
                                low_stock: Number(low_stock_alert),
                                margin: Number(margin),
                                profit: Number(Profit),
                                quantity: Number(qty) || 0,
                                selling_price: Number(price),
                                tax: '',
                            };

                            await bulkCreateTemplateAndItem(productObj, account, productCategory, locations);
                        }),
                    );
                }
                setData([]);
                setToggle(false);
                setViewCsvTable(false);
                setErrors({});
                await Sync(user.email);
            } catch (error) {
                console.log('Bulk creation error (CSV Upload)--------->', error);
            } finally {
                setBtnLoading(false);
            }
        }
    };

    const handleDiscard = () => {
        setErrors({});
        setViewCsvTable(false);
        setData([]);
    };

    const errorMessage = Object?.values(errors).length > 0 && getFirstErrorMessage(errors);

    return (
        <>
            {viewCsvTable ? (
                <PosAppModal
                    className="selectCustomerModal"
                    toggle={toggle}
                    handleClose={handleClose}
                    title={I18n.csv_uploaded}
                    bottomSaveCta={{
                        title: I18n.save,
                        action: () => handleSave(formateRow),
                        disabled: !formateRow?.length || Object.keys(errors)?.length,
                        saveLoad: btnLoading,
                    }}
                    backCta={{
                        action: () => handleDiscard(),
                        name: 'backArrowIcon',
                        fill: theme.barclaysBlue,
                        width: '18',
                        height: '18',
                        viewBox: '0 0 18 18',
                    }}
                    bottomCancelCta={{
                        title: I18n.discard,
                        action: () => handleDiscard(),
                    }}
                    toastBox={
                        Object?.values(errors).length > 0
                            ? {
                                  errorToast: true,
                                  message: errorMessage.message,
                              }
                            : null
                    }>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {type === 'customer' ? (
                                    <CustomerCSVTable errors={errors} setErrors={setErrors} data={data} />
                                ) : type === 'discount' ? (
                                    <DiscountCSVTable errors={errors} setErrors={setErrors} data={data} />
                                ) : type === 'tax' ? (
                                    <TaxCSVTable errors={errors} setErrors={setErrors} data={data} />
                                ) : type === 'item' ? (
                                    <ItemCSVTable errors={errors} setData={setData} setErrors={setErrors} data={data} products={products} />
                                ) : null}
                            </Col>
                        </Row>
                    </Container>
                </PosAppModal>
            ) : (
                <PosAppModal
                    className="selectCustomerModal"
                    toggle={toggle}
                    handleClose={handleClose}
                    title={I18n.import_library}
                    backCta={{
                        name: 'backArrowIcon',
                        fill: theme.barclaysBlue,
                        width: '18',
                        height: '18',
                        viewBox: '0 0 18 18',
                    }}>
                    <CsvUploadComponent setData={setData} data={data} setViewCsvTable={setViewCsvTable} type={type} />
                </PosAppModal>
            )}
        </>
    );
};

const enhance = withObservables([], () => ({
    products: observeAllProducts().observe(),
}));

export default enhance(CsvUploadModal);
