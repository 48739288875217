import {Col, Row} from 'react-bootstrap';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {CustomContainer, Header, TextField, ImageUploader, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {
    CNIC_REGEX,
    HASH_SALT,
    ROLE_PERMISSION,
    SALARY_TYPE,
    bulkUploadImages,
    getAccountBusiness,
    getFirstErrorMessage,
    getHashMap,
    getLocationNames,
    toSnakeCase,
    uuid,
    validateCreateTeamForm,
} from '../../../../../constants';
import {useEffect, useState} from 'react';
import CnicUploader from '../../../../common/CnicUploader';
import RolePermission from '../../../../common/RolePermission';
import {Sync, postAccountRegister, updatePassword} from '../../../../../api';
import CreateRoleModal from '../../../../common/CreateRoleModal';
import {observeRoles} from '../../../../../pos-core/database/helpers';
import withObservables from '@nozbe/with-observables';
import {hashSync} from 'bcryptjs';
import LocationModal from '../../../../common/LocationModal';
import ImageUploadModal from '../../../../common/ImageUploadModal';

const CreateTeam = ({member, roles}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast, userBusiness, account, persistData, setPersistData} = useAuthContext();

    let navigate = useNavigate();
    const {state} = useLocation();
    const {
        image_data,
        results_data,
        cnicNumber_data,
        cnicFront_data,
        cnicBack_data,
        firstname_data,
        lastname_data,
        email_data,
        phone_data,
        password_data,
        salaryType_data,
        salaryAmount_data,
        userRole_data,
        posPin_data,
        selectedLocations_data,
    } = persistData?.team || {};

    const [errors, setErrors] = useState({});
    const [imageModal, setImageModal] = useState(false);
    const [image, setImage] = useState(image_data || member?.memberDetails?.image || '');
    const [results, setResults] = useState(results_data || {});
    const [cnicNumber, setCnicNumber] = useState(cnicNumber_data || member?.memberDetails?.cnic || '');
    const [cnicFront, setCnicFront] = useState(cnicFront_data || member?.memberDetails?.cnic_front || '');
    const [cnicBack, setCnicBack] = useState(cnicBack_data || member?.memberDetails?.cnic_back || '');
    const [firstname, setFirstname] = useState(firstname_data || member?.memberDetails?.first_name || '');
    const [lastname, setLastname] = useState(lastname_data || member?.memberDetails?.last_name || '');
    const [email, setEmail] = useState(email_data || member?.memberDetails?.email || '');
    const [phone, setPhone] = useState(phone_data || member?.memberDetails?.phone_number || '');
    const [password, setPassword] = useState(password_data || null);
    const [salaryType, setSalaryType] = useState(salaryType_data || member?.memberDetails?.salary_type);
    const [salaryAmount, setSalaryAmount] = useState(salaryAmount_data || member?.memberDetails?.salary_amount);
    const [userRole, setUserRole] = useState(userRole_data || '');
    const [posPin, setPosPin] = useState(posPin_data || '');
    const [createRoleModal, setCreateRoleModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState(selectedLocations_data || {});
    const [location, setLocation] = useState(null);
    const [locationsObj, setLocationsObj] = useState();
    const [searchParams] = useSearchParams();

    const location_id = searchParams?.get('location_id');
    let team_data = {
        image_data: image,
        results_data: results,
        cnicNumber_data: cnicNumber,
        cnicFront_data: cnicFront,
        cnicBack_data: cnicBack,
        firstname_data: firstname,
        lastname_data: lastname,
        email_data: email,
        phone_data: phone,
        password_data: password,
        salaryType_data: salaryType,
        salaryAmount_data: salaryAmount,
        userRole_data: userRole,
        posPin_data: posPin,
        selectedLocations_data: selectedLocations,
    };

    useEffect(() => {
        getBusinessOfAccount();
        if (member) {
            setUserRole(member?.role);
            setResults(member?.module);
        }
    }, []);

    useEffect(() => {
        if (location_id && location) {
            setSelectedLocations({[location_id]: location_id});
        }
    }, [location_id, location]);

    useEffect(() => {
        if (Object.values(selectedLocations || {}).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = {...prevErrors};
                let label = 'Location';
                if (updatedErrors[toSnakeCase(label)]) {
                    delete updatedErrors[toSnakeCase(label)];
                }

                return updatedErrors;
            });
        }
    }, [selectedLocations]);

    const onChange = async (label, value) => {
        if (label === I18n.first_name) setFirstname(value);
        else if (label === I18n.last_name) setLastname(value);
        else if (label === I18n.phone) setPhone(value);
        else if (label === I18n.email) setEmail(value);
        else if (label === I18n.password) setPassword(value);
        else if (label === I18n.cnic) setCnicNumber(value);
        else if (label == I18n.location) setLocationModal(true);
        else if (label === I18n.salary_type) setSalaryType(value);
        else if (label === I18n.salary_amount) setSalaryAmount(value);
        else if (label === I18n.select_role) {
            if (value.name == I18n.create_role_title) {
                setCreateRoleModal(prev => !prev);
            } else {
                setUserRole(value);
                const modules = await value?.module?.fetch();
                const resultObject = {};
                modules?.forEach(obj => {
                    resultObject[obj.name] = {name: obj.name, access: obj.create ? 'full_access' : 'view_only'};
                });

                setResults(resultObject);
            }
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (label === 'CNIC #' && CNIC_REGEX.test(value)) {
                label = 'cnic Number';
            }
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleCreateRole = async value => {
        const modules = await value?.module?.fetch();
        const resultObject = {};
        modules?.forEach(obj => {
            resultObject[obj.name] = {name: obj.name, access: obj.create ? 'full_access' : 'view_only'};
        });
        setResults(resultObject);
        setUserRole(value);
        setCreateRoleModal(false);
    };

    const handleEmploy = async () => {
        try {
            setBtnLoading(true);

            const locationsArray = Object.values(selectedLocations || {}).map(item => locationsObj[item].id);
            const locationName = location.find(loc => loc._raw.id === Object.values(selectedLocations || {})?.[0])?.name;

            let createUserPayload = null;
            const memberDetails = member?.memberDetails;
            let employeDataObj = {
                cnicNumber,
                email,
                image,
                firstname,
                lastname,
                phone,
                password,
                posPin,
                userRole,
                locationsArray,
            };

            const {formErrors, isPosActive} = await validateCreateTeamForm(employeDataObj, memberDetails);
            console.log('form Errors', formErrors);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) {
                setBtnLoading(false);
                return;
            }

            const imagesToUpload = [
                {image: image, id: uuid(), table: 'user', state: 'image'},
                {image: cnicFront, id: uuid(), table: 'user', state: 'cnic_front'},
                {image: cnicBack, id: uuid(), table: 'user', state: 'cnic_back'},
            ].filter(item => item?.image?.includes('data'));

            const getImagesUrl = await bulkUploadImages(imagesToUpload);
            const account = await business?.account?.fetch();

            if (member) {
                createUserPayload = {
                    first_name: firstname,
                    last_name: lastname || '',
                    phone_number: phone,
                    cnic: cnicNumber,
                    email: email,
                    salary_type: salaryType?.name,
                    salary_amount: salaryAmount,
                    cnic_front: getImagesUrl?.['cnic_front'] || cnicFront,
                    cnic_back: getImagesUrl?.['cnic_back'] || cnicBack,
                    image: getImagesUrl?.['image'] || image,
                    role: userRole,
                    user_business: await member?.memberDetails?.user_business.fetch(),
                    pos_allow: isPosActive,
                };
            } else {
                createUserPayload = {
                    account_id: account.id,
                    first_name: firstname,
                    last_name: lastname || '',
                    phone_number: phone,
                    cnic: cnicNumber,
                    email: email,
                    salary_type: salaryType?.name,
                    salary_amount: salaryAmount,
                    cnic_front: getImagesUrl?.['cnic_front'] || cnicFront,
                    cnic_back: getImagesUrl?.['cnic_back'] || cnicBack,
                    image: getImagesUrl['image'] || image,
                    is_active: true,
                    password: password,
                    archive: false,
                    role_id: userRole.id,
                    role: userRole.name,
                    pin: posPin ? hashSync(posPin, HASH_SALT) : null,
                    created_at: new Date().getTime(),
                    last_visited: new Date().getTime(),
                    business_ids: locationsArray,
                    pos_allow: isPosActive,
                };
            }

            if (member) {
                await member?.memberDetails.updateUser(createUserPayload);
                if (password) {
                    const payload = {
                        email,
                        new_password: password,
                    };
                    await updatePassword(payload);
                }
            } else {
                const response = await postAccountRegister(createUserPayload);
                console.log('response is', response);
            }

            let toastMessage = member ? 'team_member_update' : 'team_member_created';
            setRenderToast(toastMessage);

            await Sync(user.email);

            const userPayload = {
                ...employeDataObj,
                userRole: userRole.name,
                location: locationName,
                posPin: employeDataObj?.posPin,
                posAllow: isPosActive,
            };

            if (memberDetails) {
                navigate('/team');
            } else {
                localStorage.setItem('created_user', JSON.stringify(userPayload));
                navigate('/team/send-email', {
                    state: {screenName: state?.screenName == 'is_onboarding' ? 'is_onboarding' : 'create_team'},
                });
            }

            setBtnLoading(false);
            setPersistData(null);
        } catch (error) {
            const errorMessage = getFirstErrorMessage(error?.response?.data?.error);
            setErrors({response: errorMessage});
            setBtnLoading(false);
        }
    };

    const handleRemoveImage = () => setImage();

    const handleImage = e => {
        try {
            let val = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(val);
            reader.onload = () => setImage(reader.result);
        } catch (e) {
            setImage('');
            console.log(e.message);
        }
    };

    const handleCnicUpload = e => {
        try {
            let val = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(val);
            reader.onload = () => {
                let label = '';
                if (e.target.id == 'file-ip-2') {
                    setCnicFront(reader.result);
                    label = 'cnic Front';
                } else {
                    setCnicBack(reader.result);
                    label = 'cnic Back';
                }

                setErrors(prevErrors => {
                    const updatedErrors = {...prevErrors};
                    if (updatedErrors[toSnakeCase(label)]) {
                        delete updatedErrors[toSnakeCase(label)];
                    }

                    return updatedErrors;
                });
            };
        } catch (e) {
            if (e.target.id == 'file-ip-2') setCnicFront('');
            else setCnicBack('');

            console.log(e.message);
        }
    };

    const toggleImageModal = () => {
        setImageModal(prev => !prev);
    };

    const HandleToggle = (check, item, access) => {
        const {id} = item || {};
        setResults(prev => {
            let all = {...prev};
            if (check) all[id] = {name: id, access};
            else delete all[id];
            return all;
        });
    };

    async function getBusinessOfAccount() {
        const singleSelection = true;
        const businessLocations = await getAccountBusiness(account, business, userBusiness, singleSelection);
        const businessObj = await getHashMap(account);

        if (member) {
            const memberBusiness = await member.memberDetails.user_business.fetch();
            const businessId = memberBusiness[0]?.business.id;
            setSelectedLocations({[businessId]: businessId});
        }
        setLocation(businessLocations);
        setLocationsObj(businessObj);
    }

    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            handleEmploy();
        }
    };

    const handleToggleLocationModal = () => {
        if (!member) {
            setLocationModal(true);
            setPersistData({team: team_data});
        }
    };

    const handleBack = () => {
        if (state?.screenName === 'is_onboarding') {
            navigate('/onboarding', {state: {screenName: 'teams'}});
        } else {
            navigate('/team');
        }
    };
    return (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                saveCta={{
                    title: I18n.continue,
                    action: () => handleEmploy(),
                    saveLoad: btnLoading,
                }}
                backAction={handleBack}
            />
            <CustomContainer className="addScreenMainBox createCutomerContainer">
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot40" style={{color: theme.text}}>
                                {member ? I18n.edit_team_member : I18n.create_team_member}
                            </h3>
                            <h3 className="fontSize20 fontWeight400 marBot20" style={{color: theme.text}}>
                                {I18n.member_credential_details}
                            </h3>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    onChange={onChange}
                                    error={errors?.['first_name']}
                                    label={I18n.first_name}
                                    placeholder={I18n.first_name}
                                    value={firstname}
                                    required={true}
                                    autoFocus={true}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField onChange={onChange} error={errors?.['last_name']} label={I18n.last_name} placeholder={I18n.last_name} value={lastname} />
                            </div>
                        </div>
                        <TextField onChange={onChange} label={I18n.email} placeholder={I18n.email} value={email} required={true} error={errors?.['email']} disabled={member} />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%'}}>
                            <div className="priceSectionInputLeft">
                                <TextField
                                    type="numeric"
                                    onChange={onChange}
                                    error={errors?.['phone']}
                                    label={I18n.phone}
                                    placeholder={I18n.phone}
                                    value={phone}
                                    maxLength={11}
                                    required={true}
                                />
                            </div>

                            <div className="priceSectionInputRight">
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={onChange}
                                    error={errors?.['password']}
                                    label={I18n.password}
                                    value={password}
                                    placeholder={I18n.password}
                                    suffix={showPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                                    suffix_fill={theme.white}
                                    suffix_width={'18'}
                                    suffix_height={'18'}
                                    suffix_viewBox={'0 0 18 18'}
                                    suffixClick={() => setShowPassword(prev => !prev)}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="divider width100 marTop0 marBot30" />
                        <h3 className="fontSize20 fontWeight400 marBot20" style={{color: theme.text}}>
                            {I18n.location}
                        </h3>

                        <div onClick={handleToggleLocationModal}>
                            <TextField
                                label={I18n.location}
                                placeholder={I18n.location}
                                onChange={onChange}
                                error={errors?.['location']}
                                value={getLocationNames(locationsObj, selectedLocations)}
                                inputType="text"
                                type="text"
                                suffix="downIcon2"
                                suffix_fill={theme.white}
                                required={true}
                                suffix_width={'24'}
                                suffix_height={'24'}
                                suffix_viewBox={'0 0 18 18'}
                                disabled={member}
                            />
                        </div>

                        <div className="divider width100 marTop0 marBot30" />
                        <h3 className="fontSize20 fontWeight400 marBot20" style={{color: theme.text}}>
                            {I18n.cnic_details}
                        </h3>

                        <div>
                            <div className="intventoryFormGridTwo">
                                <div className="intventoryFormGridLeft">
                                    <TextField
                                        maxLength={13}
                                        type="numeric"
                                        onChange={onChange}
                                        error={errors?.['cnic_number']}
                                        label={I18n.cnic}
                                        placeholder={I18n.cnic}
                                        value={cnicNumber}
                                    />

                                    <div className="cnicUploadBox">
                                        <CnicUploader handleCnicUpload={handleCnicUpload} cnicImage={cnicFront} cnictype={'front'} error={errors?.['cnic_front']} />
                                        <CnicUploader handleCnicUpload={handleCnicUpload} cnicImage={cnicBack} cnictype={'back'} error={errors?.['cnic_back']} />
                                    </div>
                                </div>
                                <div className="intventoryFormGridRight">
                                    <ImageUploader handleImage={handleImage} image={image} toggleImageModal={toggleImageModal} error={errors?.['image']} />
                                </div>
                            </div>

                            <div className="divider width100 marTop40 marBot30" />
                            <h3 className="fontSize20 fontWeight400 marBot20" style={{color: theme.text}}>
                                Job details
                            </h3>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField
                                        label={I18n.select_role}
                                        placeholder={I18n.select_role}
                                        error={errors?.['select_role']}
                                        onChange={onChange}
                                        value={userRole}
                                        data={[
                                            ...(business.is_hq
                                                ? [
                                                      {
                                                          name: I18n.create_role_title,
                                                          color: theme.barclaysBlue,
                                                      },
                                                  ]
                                                : []),

                                            ...roles,
                                        ]}
                                        inputType="select"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                        disabled={false}
                                        required={true}
                                        highlighter={true}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField
                                        label={I18n.salary_type}
                                        placeholder={I18n.salary_type}
                                        error={errors?.['salary_type']}
                                        onChange={onChange}
                                        value={salaryType}
                                        data={SALARY_TYPE}
                                        inputType="select"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div>
                                <TextField
                                    type="numeric"
                                    onChange={onChange}
                                    error={errors?.['salary_amount']}
                                    label={I18n.salary_amount}
                                    placeholder={I18n.salary_amount}
                                    value={salaryAmount}
                                    maxLength={11}
                                />
                            </div>

                            {results && <div className="divider width100 marTop0 marBot30" />}
                            <h3 className="fontSize20 fontWeight400 marBot20" style={{color: theme.text}}>
                                Team member permissions
                            </h3>

                            {ROLE_PERMISSION.filter(role => role.allowedBusinessTypes?.includes(business.type))?.map((item, index) => {
                                return (
                                    <RolePermission
                                        item={item}
                                        HandleToggle={HandleToggle}
                                        selected={results[item.id]}
                                        moduleAccess={results[item.id]?.access}
                                        setPosPin={setPosPin}
                                        posPin={posPin}
                                        createTeamComponent={true}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                );
                            })}
                        </div>
                    </Col>
                </Row>

                <ImageUploadModal setToggle={setImageModal} toggle={imageModal} setImage={setImage} />
                <CreateRoleModal setToggle={setCreateRoleModal} toggle={createRoleModal} handleCreateRole={handleCreateRole} roles={roles} />

                <LocationModal
                    toggle={locationModal}
                    setToggle={setLocationModal}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                    locations={location}
                    singleSelection={true}
                    screenName="team"
                />

                <ToastBox className="toastMainBox" ToastVisiable={errors?.['response']} type={'withoutheHeader'} bodyPara={errors?.['response']} />
            </CustomContainer>
        </div>
    );
};

const enhance = withObservables([], () => ({
    roles: observeRoles(),
}));

export default enhance(CreateTeam);
