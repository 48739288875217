import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import withObservables from '@nozbe/with-observables';
import {capitalizeFirstAlphabet} from '../../../constants';
import {getSplittedOrders} from '../../../pos-core/database/helpers';
import {useNavigate} from 'react-router-dom';

const ResturantSummary = ({order, floor_plan, table, waiter, rider, splitted_orders}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    let navigate = useNavigate();

    const handleNavigate = number => {
        navigate(`/orders/${number}`);
    };
    return (
        <>
            {/* <div className="detailBotCol "></div> */}
            <div className="rowConatainer garyBorderBottom">
                <div className="detailBotCol ">
                    <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                        {I18n.floor_plan}
                    </p>
                    <p className="fontSize14 marBot0" style={{color: theme.white}}>
                        {floor_plan?.name}
                    </p>
                </div>

                <div className="detailBotCol ">
                    <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                        {I18n.type}
                    </p>
                    <p className="fontSize14 marBot0" style={{color: theme.white}}>
                        {capitalizeFirstAlphabet(order.type)}
                    </p>
                </div>

                {order?.type === 'dinein' && (
                    <div className="detailBotCol">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {I18n.table}
                        </p>
                        <p className="fontSize14 marBot0" style={{color: theme.white}}>
                            {table?.name}
                        </p>
                    </div>
                )}

                {order.type === 'dinein' && (
                    <div className="detailBotCol ">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {I18n.guests}
                        </p>
                        <p className="fontSize14 marBot0" style={{color: theme.white}}>
                            {order?.no_of_guests}
                        </p>
                    </div>
                )}

                {(order?.type === 'dinein' || order.type === 'delivery') && (
                    <div className="detailBotCol ">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {order?.type === 'dinein' ? I18n.waiter : I18n.rider}
                        </p>
                        <p className="fontSize14 marBot0" style={{color: theme.white}}>
                            {order?.type === 'dinein' ? waiter?.name || '--' : rider?.name || '--'}
                        </p>
                    </div>
                )}
            </div>

            {(splitted_orders?.length > 0 || order?.parent_number) && (
                <div className="rowConatainer">
                    <div className="detailBotCol ">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {splitted_orders?.length > 0 ? I18n.child_order_id : I18n.parent_order_id}
                        </p>
                        {splitted_orders?.length > 0 ? (
                            <div className="flex flexwrap marTop5">
                                {splitted_orders?.map(ord => (
                                    <p className="fontSize14 marBot0 marRight10 cursorPointer" style={{color: theme.barclaysBlue}} onClick={() => handleNavigate(ord.number)}>
                                        {`${I18n.order_hash}${ord.number}`}
                                    </p>
                                ))}
                            </div>
                        ) : (
                            <p className="fontSize14 marBot0 cursorPointer marTop5" style={{color: theme.barclaysBlue}} onClick={() => handleNavigate(order?.parent_number)}>
                                {`${I18n.order_hash}${order?.parent_number}`}
                            </p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    floor_plan: order.floor_plan.observe(),
    table: order.dine_in_table.observe(),
    waiter: order.waiter.observe(),
    rider: order.rider.observe(),
    splitted_orders: getSplittedOrders(order.number).observe(),
}));

export default enhance(ResturantSummary);
