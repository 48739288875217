export const SETTING_CONFIG_OPTIONS = [
    {name: 'enable_stock_button', sub_title: 'Enable stock list'},
    {name: 'open_item_keyboard', sub_title: 'Show open item keypad'},
    {name: 'price_edit', sub_title: 'Allow price edit'},
    {name: 'customer_details', sub_title: 'Mandate customer details'},
    {name: 'discount_button', sub_title: 'Show discount button'},
    {name: 'counter_management', sub_title: 'Enable counter management to link your payment device'},
    {name: 'Margin_and_reports', sub_title: 'Margin and reports'},
    {name: 'item_service_library', sub_title: 'Select item or service library'},
];
